<template>
  <div class="select2-wrapper text-left">
    <select
      id="description"
      name="descrizione"
      aria-label="es: paraurti"
      v-model="selectedDescription"
      class="form-control form-control-lg"
      style="min-width: 300px"
      disabled="disabled"
    >
      <option
        v-for="(description, index) in descriptions"
        :value="description.value"
        v-bind:key="description.text"
        :key="index"
      >
        {{ description.text }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "description-select",
  props: {
    slug: String,
    slugGruppo: String,
    searchCode: String,
    preselectedDescription: String,
  },
  data() {
    return {
      descriptions: [],
      selectedDescription: null,
    };
  },
  mounted() {
    console.log("slug", this.slug);

    this.selectedDescription = this.preselectedDescription;
    axios
      .get("/api/v1/filter/description", {
        params: {
          slug: this.slug,
          slug_gruppo: this.slugGruppo,
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.descriptions = response.data.data;
          $("#description").removeAttr("disabled");
        }
      })
      .finally(() => {});

    const self = this;

    $("#description").select2({
      minimumInputLength: "3",
      language: "it",
      // selectOnBlur:true
      // ajax: {
      //     url: "/api/v1/filter/description",
      //     data: function (term) {
      //         return {
      //             "q": term,
      //             "slug": self.slug,
      //             "slug_gruppo": self.slugGruppo,
      //         }
      //     },
      //     dataType: "json",
      //     processResults: function (data) {
      //         return {
      //             results: Object.values(data.data).map(el => {
      //                 return {
      //                     id: el,
      //                     text: el
      //                 };
      //             })
      //         };
      //     }
      // },
    });

    $("#description").on("change", function () {
      self.selectedDescription = this.value;
    });

    $(document).on("keyup keydown", "input.select2-search__field", (e) => {
      const data = $(e.target).val();
      this.searchDescription(data);
    });
  },
  methods: {
    searchDescription(data) {
      this.selectedDescription = data;
      let descr = { text: data, value: data };
      this.descriptions.push(descr);
      var index = this.descriptions.indexOf(descr);
      this.descriptions.splice(index - 1, 1);
      $("#select2-description-container").text(data);
    },
  },
};
</script>

<style scoped>
</style>
