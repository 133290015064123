<template>
  <!--    <form action="{{ path_for('order-component-rma', {key: key}) }}" method="POST" class="custom-validation" novalidate>-->
  <!--        <p>Compila il form sottostante e segui le istruzioni a pagina 2 del PDF che verrà scaricato</p>-->
  <!--        <input type="hidden" id="rowIdReso" name="row_id" value="{{ row.id }}" />-->
  <!--        <div class="form-group">-->
  <!--            <label for="motivoReso">Motivo del reso<sup>*</sup></label>-->
  <!--            <select id="motivoReso" class="form-control" required name="motivo">-->
  <!--                <option value="">Seleziona un motivo</option>-->
  <!--                <option value="Difettoso">Componente difettoso</option>-->
  <!--                <option value="Pezzo sbagliato">Componente errato</option>-->
  <!--                <option value="Altro">Altro</option>-->
  <!--            </select>-->
  <!--            <div class="invalid-feedback">-->
  <!--                Seleziona il motivo del reso-->
  <!--            </div>-->
  <!--        </div>-->
  <!--        <div class="form-group">-->
  <!--            <label for="commentiReso">Commenti</label>-->
  <!--            <textarea id="commentiReso" class="form-control" name="commenti"></textarea>-->
  <!--        </div>-->
  <!--        <div class="form-group">-->
  <!--            <button type="submit" class="btn btn-primary">Richiedi reso</button>-->
  <!--        </div>-->
  <!--    </form>-->
  <form
    @submit.prevent="submit"
    id="product-return-form"
    novalidate
    role="form"
  >
    <div class="form-group">
      <label for="motivoReso"
        >{{ $trans("filter-model.motivo-reso") }} <sup>*</sup></label
      >
      <select
        id="motivoReso"
        class="form-control"
        name="motivo"
        v-model="$v.motivo.$model"
      >
        <option value="">
          {{ $trans("filter-component.seleziona-motivo") }}
        </option>
        <option value="Difettoso">
          {{ $trans("filter-component.difettoso") }}
        </option>
        <option value="Pezzo sbagliato">
          {{ $trans("filter-component.errato") }}
        </option>
        <option value="Altro">{{ $trans("filter-component.altro") }}</option>
      </select>
    </div>
  </form>
</template>

<script>
// import { ValidationMixin } from 'vuelidate';
// import { required } from 'vuelidate/lib/validators';

export default {
  name: "productReturn",
};
</script>
