<template>
  <div class="store-locator-container">
    <div class="store-list">
      <div v-for="(marker, index) in markers" :key="index" class="store-item" >
        <p style="margin: 0"><strong>{{ marker.title }}</strong></p>
        <p style="color: gray; font-size: 12px;">{{ marker.address }}</p>
        <p @click="showHours(index)" class="today">
          <strong>{{ $trans("store-finder.oggi") }}</strong>
          {{ isStoreOpen(marker.hours) }}
          <span v-if="canIShow(hours[index]) == false || pageInteracted == false">▼</span>
          <span v-if="canIShow(hours[index]) == true && pageInteracted == true">▶</span>
        </p>
        <table v-if="canIShow(hours[index]) == true && pageInteracted == true">
          <tr v-for="opening in marker.hours">
            <td class="opening-day">{{ opening.day }}</td>
            <td>{{ opening.open }}</td>
          </tr>
        </table>
        <p class="lower-btn" @click="showInfoWindow(index)">
          <span>▶</span> {{ $trans("store-finder.seleziona-negozio") }}
        </p>
        <p class="lower-btn" @click="roadTo(marker)">
          <span>▶</span> {{ $trans("store-finder.naviga") }}
        </p>
        <p class="lower-btn" @click="goToPage(marker)">
          <span>▶</span> {{ $trans("store-finder.pagina-negozio") }}
        </p>
      </div>
    </div>
    <GmapMap
        :center="center"
        :zoom="zoom"
        @click="setMarker"
    >
      <GmapMarker
          v-for="(marker, index) in markers"
          :key="index"
          :position="marker.position"
          :clickable="true"
          @click="showInfoWindow(index)"
      />
      <GmapInfoWindow
          :position="infoWindow.position"
          :opened="infoWindow.opened"
          @closeclick="infoWindow.opened = false"
      >
        <div>
          <p>
            <strong>{{ infoWindow.title }}</strong>
            <div>{{ infoWindow.address }}</div>
            <div v-if="infoWindow.hours && infoWindow.hours.open">
              {{ infoWindow.hours.open }}
            </div>
          </p>
        </div>
      </GmapInfoWindow>
    </GmapMap>
  </div>
</template>
<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBZ9HsWEjL28Nqpvq5KfvDXrozWDQ_KdGw',
    libraries: 'places',
  }
})

export default {
  name: 'sediPuntiVendita',
  props: {
    infoSedi: Object,
  },
  data() {
    return {
      center: { lat: 45.516235, lng: 9.202751 },
      zoom: 8,
      markers: [],
      infoWindow: {
        position: null,
        title: '',
        description: '',
        showHours: '',
        address: '',
        opened: false,
      },
      storeOpen: '',
      hours: [0,1,2,3,4,5,6,7,8,9,10,11],
      selectedIndex: '',
      pageInteracted: false,
    }
  },
  mounted() {
    this.markers = Object.values(this.infoSedi);
    // Object.keys(obj).forEach(key => {
    //   console.log(key, obj[key]);
    // });
    console.log(this.infoSedi, this.markers);
  },
  methods: {
    setMarker(event) {
      this.markers.push({
        position: { lat: event.latLng.lat(), lng: event.latLng.lng() },
        title: 'New Store',
        description: 'Description for New Store',
        address: '123 Main St',
        distance: null,
      })
      this.calculateDistances();
    },
    showInfoWindow(index) {
      console.log(index);
      this.infoWindow.position = this.markers[index].position
      this.infoWindow.title = this.markers[index].title
      this.infoWindow.description = this.markers[index].description
      this.infoWindow.address = this.markers[index].address
      const pos = new Date().getDay() === 0 ? 6 : new Date().getDay() - 1;
      this.infoWindow.hours = this.markers[index].hours[pos];
      this.infoWindow.opened = true
    },
    showHours(index) {
      console.log(index);
      if (this.pageInteracted == false) {
        this.pageInteracted = true;
      } else {
        if (this.selectedIndex == index) {
          this.pageInteracted = false;
        } else {
          this.pageInteracted = true;
        }
      }
      this.selectedIndex = index;
    },
    canIShow(item) {
      console.log(item, this.selectedIndex)
      if (item == this.selectedIndex) {
        return true;
      } else {
        return false
      }
    },
    goToPage(item) {
      window.location = item.page;
    },
    roadTo(item) {
      window.open(item.road, '_blank');
    },
    isStoreOpen(items) {
      console.log(items);
      const d = new Date().getDay()
      if ( d == 7) {
        return 'Chiuso';
      } else {
        return items[d-1].open;
      }
    },
    calculateDistances() {
      const service = new google.maps.DistanceMatrixService();
      const origin = new google.maps.LatLng(this.center.lat, this.center.lng);
      const destinations = this.markers.map((marker) => new google.maps.LatLng(marker.position.lat, marker.position.lng));
      const request = {
        origins: [origin],
        destinations,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      service.getDistanceMatrix(request, (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          const distances = response.rows[0].elements.map((element) => element.distance.value / 1000);
          this.markers = this.markers.map((marker, index) => {
            marker.distance = distances[index];
            return marker;
          });
        }
      });
    },
  }
};
</script>
<style scoped>
  .vue-map-container {
    height: 500px;
    width: 100%;
  }
  .store-locator-container {display: flex; max-height: 500px;}
  .store-list {
    min-width: 400px;
    overflow-y: scroll;
    margin-right: 20px;
  }
  .store-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  .store-item:hover {
    background-color: #eee;
  }
  table td{
    font-size: 14px;
  }
  table {
    margin-bottom: 16px;
  }
  .opening-day, .today {
    padding-right: 30px;
  }
  .today {
    cursor: pointer;
  }
  .lower-btn {
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 14px;
  }
  p span {
    color: #C71A20;
  }
  @media screen and (max-width: 992px) {
    .store-list {
      width: 100%;
      min-width: 200px;
      max-height: 400px;
      margin-right: 0px;
      margin-bottom: 20px;
    }
    p.today {
      padding-right: 0;
    }
    .store-locator-container {
      max-height: 1000px;
      flex-wrap: wrap;
    }
  }
</style>
