function showFullScreenLoader() {
    document.getElementById("spinner-front").classList.add("show");
    document.getElementById("spinner-back").classList.add("show");
}
function hideFullScreenLoader() {
    document.getElementById("spinner-front").classList.remove("show");
    document.getElementById("spinner-back").classList.remove("show");
}

export {
    showFullScreenLoader, hideFullScreenLoader
}
