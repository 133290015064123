<template>
  <form
    @submit="saveProfile"
    class="custom-validation"
    data-toggle="validator"
    id="edit-profile-form"
    method="POST"
    novalidate
    role="form"
  >
    <div class="card mb-4">
      <div class="card-body card-body--padding--2">
        <h3 class="card-title">{{ $trans("sign-up.privato-azienda") }}</h3>
        <div class="row">
          <div class="form-check col-md-12">
            <div class="filter-user-type">
              <ul class="filter-user-type__list">
                <li class="filter-user-type__item">
                  <label class="filter-user-type__item-label">
                    <span class="filter-list__input input-radio">
                      <span class="input-radio__body">
                        <input
                          :value="true"
                          checked="checked"
                          class="input-radio__input"
                          type="radio"
                          v-model="isBusiness"
                        />
                        <span class="input-radio__circle"></span>
                      </span>
                    </span>
                    <span>{{ $trans("sign-up.azienda") }}</span>
                  </label>
                </li>
                <li class="filter-user-type__item">
                  <label class="filter-user-type__item-label">
                    <span class="filter-list__input input-radio">
                      <span class="input-radio__body">
                        <input
                          :value="false"
                          class="input-radio__input"
                          type="radio"
                          v-model="isBusiness"
                        />
                        <span class="input-radio__circle"></span>
                      </span>
                    </span>
                    <span>{{ $trans("sign-up.privato") }}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-body card-body--padding--2">
        <h3 class="card-title" v-if="isBusiness">
          {{ $trans("sign-up.azienda") }}
        </h3>
        <h3 class="card-title" v-else>{{ $trans("sign-up.privato") }}</h3>
        <div class="form-row" v-if="isBusiness">
          <div class="form-group col-md-12">
            <label for="checkout-ragione-sociale"
              >{{ $trans("form-input.ragione-sociale") }} <sup>*</sup></label
            >
            <input
              :readonly="isBusiness"
              class="form-control"
              id="checkout-ragione-sociale"
              name="ragione_soc"
              required
              type="text"
              v-model="ragione_soc"
            />
          </div>
        </div>
        <div class="form-row" v-else>
          <div class="form-group col-md-12">
            <label for="checkout-first-name"
              >{{ $trans("form-input.nome-cognome") }} <sup>*</sup></label
            >
            <input
              class="form-control"
              id="checkout-first-name"
              name="ragione_soc"
              required
              type="text"
              v-model="ragione_soc"
            />
            <div class="invalid-feedback">
              {{ $trans("form-input.nome-obbligatorio") }}
            </div>
          </div>
        </div>
        <div class="form-row" v-if="isBusiness">
          <div class="form-group col-md-6">
            <label for="checkout-codicesdi"
              >{{ $trans("form-input.cod-sdi") }} <sup>*</sup></label
            >
            <input
              class="form-control"
              id="checkout-codicesdi"
              name="identificativo_fatt_elett"
              required
              type="text"
              v-model="identificativo_fatt_elett"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="checkout-codicesdi"
              >{{ $trans("form-input.pec") }} <sup>*</sup></label
            >
            <input
              class="form-control"
              id="checkout-pec"
              name="pec"
              required
              type="text"
              v-model="pec"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6" v-if="isBusiness">
            <label for="checkout-partitaiva"
              >{{ $trans("form-input.partita-iva") }} <sup>*</sup></label
            >
            <input
              :readonly="isBusiness"
              class="form-control"
              id="checkout-partitaiva"
              name="p_iva"
              required
              type="text"
              v-model="p_iva"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="checkout-codicefiscale"
              >{{ $trans("form-input.codice-fiscale") }} <small>({{$trans('form-input.facoltativo')}})</small></label
            >
            <input
              class="form-control"
              id="checkout-codicefiscale"
              name="cod_fiscale"
              type="text"
              v-bind:disabled="isBusiness"
              v-model="cod_fiscale"
            />
            <div class="invalid-feedback">
              {{ $trans("form-input.campo-obbligatorio") }}
            </div>
          </div>
        </div>
        <div class="form-row">

          <div class="form-group col-md-6">
            <label for="checkout-country-newadd"
              >{{ $trans("form-input.nazione") }} <sup>*</sup></label
            >
            <select
              class="form-control"
              id="checkout-country-newadd"
              name="nazione"
              v-model="nazione"
            >
              <option v-for="item in nations">
                {{ item }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ $trans("form-input.campo-obbligatorio") }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="checkout-street-address-newadd"
              >{{ $trans("form-input.indirizzo") }} <sup>*</sup></label
            >
            <input
              :readonly="isBusiness"
              class="form-control"
              id="checkout-street-address-newadd"
              name="indirizzo"
              required
              type="text"
              v-model="indirizzo"
            />
            <div class="invalid-feedback">
              {{ $trans("form-input.campo-obbligatorio") }}
            </div>
          </div>
        </div>
        <div class="form-row">

<!--          CAP ITALIA-->
          <div class="form-group col-md-2" :class="{'d-none': !isItalia }">
            <label for="checkout-postcode-newadd"
              >{{ $trans("form-input.cap") }} <sup>*</sup></label
            >
            <div class="select2-wrapper">
              <select
                aria-label="Seleziona CAP"
                class="form-control"
                id="checkout-postcode-newadd"
                name="cap"
                required
                v-bind:disabled="isBusiness && profile.nazione === 'ITALIA'"
                v-model="cap"
              ></select>
            </div>
            <div class="invalid-feedback">
              {{ $trans("form-input.campo-obbligatorio") }}
            </div>
          </div>
<!--          CAP ESTERO-->
          <div class="form-group col-md-2" :class="{'d-none': isItalia }">
            <label
            >{{ $trans("form-input.cap") }} <sup>*</sup></label
            >
            <div class="select2-wrapper">
              <input
                  aria-label="Seleziona CAP"
                  class="form-control"
                  name="cap"
                  required
                  v-bind:disabled="isBusiness && profile.nazione === 'ITALIA'"
                  v-model="international_cap"
              >
            </div>
            <div class="invalid-feedback">
              {{ $trans("form-input.campo-obbligatorio") }}
            </div>
          </div>
<!--          INPUT CITTA ITALIA-->
          <div class="form-group col-md-8" v-if="isItalia">
            <label for="checkout-city-newadd"
              >{{ $trans("form-input.city") }} <sup>*</sup></label
            >
            <div class="select2-wrapper">
              <select
                aria-label="Seleziona città"
                class="form-control"
                id="checkout-city-newadd"
                name="citta"
                required
                v-bind:disabled="isBusiness || !cap || loadingCities && profile.nazione === 'ITALIA'"
                v-model="citta"
              >
                <option
                  :value="city.citta"
                  v-bind:key="city.citta"
                  v-for="city in cities"
                >
                  {{ city.citta }}
                </option>
              </select>
            </div>
            <div class="invalid-feedback">
              {{ $trans("form-input.campo-obbligatorio") }}
            </div>
          </div>
<!--          INPUT CITTA ESTERO-->
          <div class="form-group col-md-8" v-if="!isItalia">
            <label
            >{{ $trans("form-input.city") }} <sup>*</sup></label
            >
            <div class="select2-wrapper">
              <input
                  aria-label="Seleziona città"
                  class="form-control"
                  required
                  v-bind:disabled="isBusiness && profile.nazione === 'ITALIA'"
                  v-model="international_city"
              >
            </div>
            <div class="invalid-feedback">
              {{ $trans("form-input.campo-obbligatorio") }}
            </div>
          </div>
          <div class="form-group col-md-2" v-if="isItalia">
            <label for="checkout-state-newadd"
              >{{ $trans("form-input.provincia") }} <sup>*</sup></label
            >
            <input
              :readonly="isBusiness"
              class="form-control"
              id="checkout-state-newadd"
              maxlength="2"
              name="provincia"
              required
              type="text"
              v-model="provincia"
            />
            <div class="invalid-feedback">
              {{ $trans("form-input.campo-obbligatorio") }}
            </div>
          </div>
        </div>
        <div class="form-row">
          <!--                    <div class="form-group col-md-6">-->
          <!--                        <label for="checkout-email-priv">Email</label>-->
          <!--                        <input-->
          <!--                            type="email"-->
          <!--                            class="form-control"-->
          <!--                            id="checkout-email-priv"-->
          <!--                            name="email"-->
          <!--                            v-model="email"-->
          <!--                        />-->
          <!--                    </div>-->
          <div class="form-group col-md-6">
            <label for="checkout-tel-newadd">{{
              $trans("form-input.telefono")
            }}</label>
            <input
              class="form-control no-spinners"
              id="checkout-tel-newadd"
              name="telefono"
              type="tel"
              v-model="telefono"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="checkout-cell-newadd">{{
              $trans("form-input.cellulare")
            }}</label>
            <input
              class="form-control no-spinners"
              id="checkout-cell-newadd"
              name="cellulare"
              type="tel"
              v-model="cellulare"
            />
          </div>
        </div>
        <button
          class="btn btn-primary"
          type="submit"
          v-bind:disabled="!isValid"
        >
          {{ $trans("form-input.salva") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import toastr from "toastr";

export default {
  name: "account-profile",
  props: {
    profile: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusiness: null,
      ragione_soc: null,
      p_iva: null,
      identificativo_fatt_elett: null,
      cod_fiscale: null,
      nazione: '',
      indirizzo: null,
      cap: null,
      citta: null,
      provincia: null,
      telefono: null,
      email: null,
      cellulare: null,
      cities: [],
      loadingCities: false,
      loadinProfile: false,
      pec: null,
      nations: [],
      isItalia: false,
      international_cap: null,
      international_city: null,
    };
  },
  mounted() {
    if (this.profile != null) {
      this.loadinProfile = true;
      this.isBusiness =
        this.profile.p_iva != null &&
        this.profile.p_iva !== "" &&
        this.profile.p_iva !== "-";
      this.parseProfile();
    }

    const self = this;


    $("#checkout-postcode-newadd").on("change", function () {
      self.cap = this.value;
    });

    $("#checkout-city-newadd").select2({
      language: "it",
      width: "100%",
    });

    $("#checkout-city-newadd").on("change", function () {
      self.citta = this.value;
    });
  },
  methods: {
    getCap(){
      $("#checkout-postcode-newadd").select2({
        minimumInputLength: 3,
        language: "it",
        width: "100%",
        ajax: {
          url: "/api/v1/cap",
          dataType: "json",
          data: function (params) {
            return {
              q: params.term,
              nation: document.querySelector('#checkout-country-newadd').value
            };
          },
          processResults: function (data) {
            return {
              results: data.data.map((el) => {
                return {
                  id: el.cap,
                  text: el.cap,
                };
              }),
            };
          },
        },
      });
    },
    loadCities: function () {
      this.loadingCities = true;
      axios
        .get("/api/v1/city?q=" + this.cap)
        .then((res) => {
          this.cities = res.data.data;

          if (!this.loadinProfile) {
            if (this.cities.length === 1) {
              this.citta = this.cities[0].citta;
            } else {
              this.citta = null;
              this.provincia = null;
            }
          } else {
            this.loadinProfile = false;
          }
        })
        .finally(() => {
          this.loadingCities = false;
        });
    },
    parseProfile: function () {
      const capData = {
        id: this.profile.cap,
        text: this.profile.cap,
      };

      axios.get('/api/v1/nations').then((res)=>{
        console.log(res.data.data[0]);
        this.nations = res.data.data[0];
      });

      const option = new Option(capData.text, capData.id, true, true);
      $("#checkout-postcode-newadd").append(option).trigger("change");

      if(this.profile.nazione !== 'ITALIA'){
        this.isItalia = false;
        this.international_cap = this.profile.cap;
        this.international_city = this.profile.citta;
      }else{
        this.isItalia = true;
        this.cap = this.profile.cap;
        this.citta = this.profile.citta.toUpperCase();
      }


      this.ragione_soc = this.profile.ragione_soc;
      this.p_iva = this.profile.p_iva;
      this.identificativo_fatt_elett = this.profile.identificativo_fatt_elett;
      this.cod_fiscale = this.profile.cod_fiscale;
      this.indirizzo = this.profile.indirizzo;
      // this.nazione = this.profile.nazione;

      this.nazione =
          this.profile.nazione && this.profile.nazione !== "-"
              ? this.profile.nazione
              : "ITALIA";

      this.provincia = this.profile.provincia;
      this.telefono = this.profile.telefono;
      this.email = this.profile.email;
      this.cellulare = this.profile.cellulare;
      this.pec = this.profile.pec;


      $("#checkout-postcode-newadd").trigger({
        type: "select2:select",
        params: {
          data: capData,
        },
      });
    },
    saveProfile: function (e) {
      if(!this.isItalia){
        this.cap = this.international_cap;
        this.citta = this.international_city;
      }
      e.preventDefault();
      axios
        .post("/api/v1/client/profile", {
          id: this.profile != null ? this.profile.id : null,
          ragione_soc: this.ragione_soc,
          p_iva: this.p_iva,
          identificativo_fatt_elett: this.identificativo_fatt_elett,
          cod_fiscale: this.cod_fiscale,
          indirizzo: this.indirizzo,
          nazione: this.nazione,
          cap: this.cap,
          citta: this.citta,
          provincia: this.provincia,
          telefono: this.telefono,
          email: this.email,
          cellulare: this.cellulare,
          pec: this.pec,
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => {
          alert(error.response.data.errors.join());
        });
    },
  },
  computed: {
    isValid: function () {
      if(this.isItalia){
        if (this.isBusiness) {
          return (
              this.ragione_soc &&
              this.identificativo_fatt_elett &&
              this.p_iva &&
              this.indirizzo &&
              this.cap &&
              this.citta &&
              this.provincia &&
              this.nazione
          );
        }
        return (
            this.ragione_soc &&
            this.indirizzo &&
            this.cap &&
            this.citta &&
            this.provincia &&
            this.nazione
        );
      } else{
        if (this.isBusiness) {
          return (
              this.ragione_soc &&
              this.identificativo_fatt_elett &&
              this.p_iva &&
              this.indirizzo &&
              this.international_cap &&
              this.international_city &&
              this.nazione
          );
        }

        return (
            this.ragione_soc &&
            this.indirizzo &&
            this.international_city &&
            this.international_cap &&
            this.nazione
        );
      }
    },
  },
  watch: {
    nazione: function(){
      if(this.nazione !== 'ITALIA'){
        this.isItalia = false;
      } else{
        this.isItalia = true;
        this.getCap();
      }
    },
    cap: function (newValue) {
      this.loadCities();
    },
    citta: function (newValue) {
      const filtered = this.cities.filter((el) => {
        return el.citta === this.citta;
      });
      if (filtered.length > 0) {
        this.provincia = filtered[0].provincia;
      }
    },
  },
};
</script>

<style scoped>
</style>
