<template>
  <form v-on:submit.prevent="submitForm">
    <div class="block-finder__form-control d-flex">
      <input
        type="text"
        class="form-control mr-1"
        :placeholder="placeholder"
        v-model="code"
      />
      <button type="submit" class="btn btn-primary" :disabled="!code">
        {{ $trans("common.cerca") }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "site-header-search",
  props: {
    path: String,
    placeholder: String,
  },

  data() {
    return {
      code: null,
    };
  },
  methods: {
    submitForm: function () {
      const link = this.path.replace("vue", this.code);
      window.location.href = link;
    },
  },
};
</script>

<style scoped>
</style>
