<template>
  <form
    id="new-address-form"
    role="form"
    method="POST"
    novalidate
    class="custom-validation"
    data-toggle="validator"
    @submit="addAddress"
    :style="[
        !noStyle ? '' : 'padding: 0 !important;'
    ]"
  >
    <div class="form-row align-items-center">


<!--      <div-->
<!--          class="d-flex ma-0 mb-2"-->
<!--          :class="[-->
<!--            !noStyle ? 'form-group col-md-2' : 'w-full'-->
<!--        ]"-->
<!--      >-->
<!--        <select-->
<!--            :id="'checkout-city-newadd-' + this.addressId"-->
<!--            name="citta"-->
<!--            aria-label="Seleziona città"-->
<!--            v-model="citta"-->
<!--            class="form-control"-->
<!--        ></select>-->
<!--      </div>-->

      <div
        class="d-flex ma-0"
        :class="[
            !noStyle ? 'form-group col-md-2' : 'w-full'
        ]"
      >
        <select
          :id="'checkout-postcode-newadd-' + this.addressId"
          name="cap"
          aria-label="Seleziona CAP"
          v-model="cap"
          class="form-control"
        ></select>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import toastr from "toastr";

export default {
  name: "new-address",
  props: {
    shipping_text: {
      type: Number,
    },
    addressId: {
      required: false,
      type: Number,
      default: 0,
    },
    address: {
      required: false,
      type: Object,
      default: null,
    },
    fromCheckout: {
      required: false,
      type: Boolean,
      default: false,
    },
    noStyle: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shipLoading: false,
      shippingText: 0,
      ragione_soc: null,
      nazione: "ITALIA",
      indirizzo: null,
      cap: null,
      citta: null,
      provincia: null,
      telefono: null,
      cellulare: null,
      cities: [],
      loadingCities: false,
      loadingAddress: false,
    };
  },
  mounted() {
    if (this.address != null) {
      this.loadingAddress = true;
      this.parseAddress();
    }
    const self = this;
    $("#checkout-postcode-newadd-" + this.addressId).select2({
      placeholder: "Cap",
      minimumInputLength: 3,
      language: "it",
      width: "100%",
      // templateResult: (state) => {
      //   if (!state.id) {
      //     return state.text;
      //   }
      //   return $(`<span>${state.text}<br><small style="color: #555">${state.citta}</smallstyle></span>`);
      // },
      ajax: {
        url: "/api/v1/cap",
        dataType: "json",
        data: function (params) {
          return {
            q: params.term,
            nation: document.querySelector('#country').value
          };
        },
        processResults: function (data) {
          return {
            results: data.data.map((el) => {
              return {
                id: el.cap,
                text: el.cap,
                citta: el.citta,
              };

            }),

          };

        },
      },


  });
    $("#checkout-postcode-newadd-" + this.addressId).on("change", function () {
      self.cap = this.value;
      this.cap = this.value;
    });
    // $("#checkout-city-newadd-" + this.addressId).select2({
    //   language: "it",
    //   width: "100%",
    // });
    // $("#checkout-city-newadd-" + this.addressId).select2({
    //   placeholder: "Città",
    //   minimumInputLength: 3,
    //   language: "it",
    //   width: "100%",
    //   ajax: {
    //     url: "/api/v1/city",
    //     dataType: "json",
    //     data: function (params) {
    //       return {
    //         q: params.term,
    //         nation: document.querySelector('#country').value
    //       };
    //     },
    //     processResults: function (data) {
    //       if(data.data.length === 1){
    //         console.log('dentro', data.data[0].cap, this.addressId);
    //         $("#checkout-postcode-newadd-" + this.addressId).append($('<option>', {
    //           value: data.data[0].cap,
    //           text: data.data[0].cap,
    //         }));
    //         // const option = new Option(data.data[0].cap, data.data[0].cap, true, true);
    //         // $("#checkout-postcode-newadd-" + this.addressId).append(option).trigger('change');
    //         // $("#checkout-postcode-newadd-" + this.addressId).trigger({
    //         //   type: 'select2:select',
    //         //   params: {
    //         //     data: data
    //         //   }
    //         // });
    //         $("#checkout-postcode-newadd-" + this.addressId).val(data.data[0].cap);
    //         $("#checkout-postcode-newadd-" + this.addressId).trigger('change');
    //       }
    //       return {
    //         results: data.data.map((el) => {
    //           return {
    //             id: el.cap,
    //             text: el.citta,
    //             cap: el.cap,
    //           };
    //
    //         }),
    //       };
    //
    //     },
    //   },
    //
    //
    // });
    $("#checkout-city-newadd-" + this.addressId).on("change", function () {
      self.citta = this.value;
      // this.citta = this.value;
    });
  },
  methods: {
    loadCities: function () {
      this.loadingCities = true;
      this.shipLoading = true;
      this.$emit("updateShipping");
      const cap = document.querySelector('#checkout-postcode-newadd-' + this.addressId).value;
      const nation = document.querySelector('#country').value;

      axios
        .get("/api/v1/city?q=" + this.cap + "&cap=" + cap + "&nation=" + nation)
        .then((res) => {
          this.cities = res.data.data;
          if (!this.loadingAddress) {
            if (this.cities.length >= 1) {
              this.citta = this.cities[0].citta;
              //Chiamata per prezzo in base a cap
              let city;
              let cap;
              let metodo_evasione;
              let ritiro;

              city = this.cities[0].citta;
              cap = this.cap;
              metodo_evasione = "NOWAIT";
              ritiro = "SP";

              console.log("cap", cap);
              console.log("citta", city);

              axios
                .get(
                  "/api/v1/cart/calc-shipping?city=" +
                    city +
                    "&cap=" +
                    cap +
                    "&metodo_evasione=" +
                    metodo_evasione +
                    "&tipo_spedizione=" +
                    ritiro +
                    "&order_id=" +
                    (this.order ? this.order.id : "") +
                    "&nazione=" + nation
                )
                .then((response) => {
                  this.shippingText = response.data.data.shipping + response.data.data.packaging;
                  console.log('spese', this.shippingText , 'data', response.data.data);
                  this.$emit("updateShipping");
                })
                .catch((error) => {
                  CustomSwal({
                    title: "Errore",
                    html: error.response.data.errors.join("<br><br>"),
                    confirmButtonText: "Ok",
                    icon: "error",
                  }).then(() => {});
                })
                .finally(() => {
                  this.shipLoading = false;
                  this.$emit("updateShipping");
                  this.shipLoading = true;

                });
            } else {
              this.citta = null;
              this.provincia = null;
            }
          } else {
            this.loadingAddress = false;
          }
        })
        .finally(() => {
          this.loadingCities = false;
        });
    },
    parseAddress: function () {
      const capData = {
        id: this.address.cap,
        text: this.address.cap,
      };

      var option = new Option(capData.text, capData.id, true, true);
      $("#checkout-postcode-newadd-" + this.addressId)
        .append(option)
        .trigger("change");

      this.ragione_soc = this.address.ragione_soc;
      this.nazione = this.address.nazione;
      this.indirizzo = this.address.indirizzo;
      this.cap = this.address.cap;
      this.citta = this.address.citta;
      this.provincia = this.address.provincia;
      this.telefono = this.address.telefono;
      this.cellulare = this.address.cellulare;

      $("#checkout-postcode-newadd-" + this.addressId).trigger({
        type: "select2:select",
        params: {
          data: capData,
        },
      });
    },
    addAddress: function (e) {
      e.preventDefault();
      axios
        .post("/api/v1/client/address/add", {
          address_id: this.address != null ? this.address.id : null,
          ragione_soc: this.ragione_soc,
          indirizzo: this.indirizzo,
          nazione: this.nazione,
          cap: this.cap,
          citta: this.citta,
          provincia: this.provincia,
          telefono: this.telefono,
          cellulare: this.cellulare,
        })
        .then((res) => {
          // $cookies.set("Indirizzo", res.data.data[0].id, "1h", "/checkout");
          // window.location.reload();
          this.$emit("newAddress", res);
        })
        .catch((error) => {
          alert(error.response.data.errors.join());
        });
    },
  },
  computed: {
    isValid: function () {
      return (
        this.ragione_soc &&
        this.indirizzo &&
        this.cap &&
        this.citta &&
        this.provincia &&
        this.nazione &&
        this.isAddressValid
      );
    },
    isAddressValid: function () {
      if (!this.indirizzo) {
        return true;
      }
      return /\d/.test(this.indirizzo);
    },
  },
  watch: {
    cap: function (newValue) {
      this.loadCities();
    },
    citta: function (newValue) {
      const filtered = this.cities.filter((el) => {
        return el.citta === this.citta;
      });
      if (filtered.length > 0) {
        this.provincia = filtered[0].provincia;
      }
    },
  },
};
</script>
