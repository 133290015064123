<template>
  <form
      @submit.prevent="submit"
      id="sign-up-form"
      method="POST"
      novalidate
      role="form"
  >
    <div class="card mb-4">
      <div class="card-body card-body--padding--2">
        <h3 class="card-title">{{ $trans("sign-up.privato-azienda") }}</h3>
        <div class="row">
<!--          <div>-->
<!--            [-->
<!--            {{citta}}-->
<!--            {{cap}}-->
<!--            {{isItalia?1:0}}-->
<!--            {{international_cap}}-->
<!--            ]-->
<!--          </div>-->
          <div class="form-check col-md-12">
            <div class="filter-user-type">
              <ul class="filter-user-type__list">
                <li class="filter-user-type__item">
                  <label class="filter-user-type__item-label">
                    <span class="filter-list__input input-radio">
                    <span class="input-radio__body">
                      <input
                          checked="checked"
                          class="input-radio__input"
                          type="radio"
                          v-model="clientType"
                          value="b2b"
                          @change="companyUser = true"
                      />
                      <span class="input-radio__circle"></span>
                    </span>
                    </span>
                    <span>{{ $trans("sign-up.azienda") }}</span>
                  </label>
                </li>
                <li class="filter-user-type__item">
                  <label class="filter-user-type__item-label">
                    <span class="filter-list__input input-radio">
                    <span class="input-radio__body">
                    <input
                        class="input-radio__input"
                        type="radio"
                        v-model="clientType"
                        value="b2c"
                        @change="companyUser = false"
                    />
                    <span class="input-radio__circle"></span>
                    </span>
                    </span>
                    <span>{{ $trans("sign-up.privato") }}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4" v-if="clientType">
      <div class="card-body card-body--padding--2">
        <h3 class="card-title">{{ $trans("sign-up.dati") }}</h3>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="checkout-email">Email<sup>*</sup></label>
            <input
                :class="{ 'is-invalid': $v.email.$error }"
                :readonly="userCheck"
                class="form-control"
                id="checkout-email"
                name="email"
                type="email"
                v-model.trim="$v.email.$model"
            />
            <div v-if="$v.email.$error">
              <div class="vue-field-error" v-if="!$v.email.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
              <div class="vue-field-error" v-if="!$v.email.email">
                {{ $trans("form-input.email-invalid") }}
              </div>
            </div>
          </div>
          <!--          INPUT NAZIONE DATI PRINCIPALI AZIENDA-->
          <div class="form-group col-md-6">
            <label for="checkout-country-newadd"
            >{{ $trans("form-input.nazione") }}<sup>*</sup></label
            >
            <select
                :class="{ 'is-invalid': $v.nazione.$error }"
                class="form-control"
                id="checkout-country-newadd"
                name="nazione"
                v-model="$v.nazione.$model"
                required
            >
              <option v-for="item in nations">{{ item.nazione }}</option>
            </select>
            <div v-if="nations.find(el => el.nazione === $v.nazione.$model).blacklistEcommerce">
              <div class="vue-field-error">
                {{ $trans("form-input.nazione-blacklist") }}
              </div>
            </div>
            <div v-if="$v.nazione.$error">
              <div class="vue-field-error" v-if="!$v.nazione.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
            </div>
          </div>

          <div
              class="form-group col-md-6"
              v-if="clientType === 'b2b' && isItalia"
          >
            <label for="checkout-partitaiva"
            >{{ $trans("form-input.partita-iva") }}<sup>*</sup></label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">IT</span>
              </div>
              <input
                  :class="{ 'is-invalid': $v.p_iva.$error }"
                  :readonly="userCheck"
                  class="form-control"
                  id="checkout-partitaiva"
                  name="p_iva"
                  required
                  type="text"
                  v-model.trim="$v.p_iva.$model"
              />
            </div>
            <div v-if="$v.p_iva.$error">
              <div class="vue-field-error" v-if="!$v.p_iva.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
              <div class="vue-field-error" v-if="!$v.p_iva.validVAT">
                {{ $trans("form-input.partita-iva-invalid") }}
              </div>
            </div>
          </div>

          <!--          FORM PARTITA IVA SE NAZIONE ESTERA-->
          <div
              class="form-group col-md-6"
              v-if="clientType === 'b2b' && !isItalia"
          >
            <label for="checkout-partitaiva"
            >{{ $trans("form-input.partita-iva") }}<sup>*</sup></label
            >
            <div class="input-group">
              <input
                  :class="{ 'is-invalid': $v.international_vat.$error }"
                  :readonly="userCheck"
                  class="form-control"
                  name="p_iva"
                  required
                  type="text"
                  v-model="international_vat"
              />
            </div>
            <div v-if="$v.international_vat.$error">
              <div
                  class="vue-field-error"
                  v-if="!$v.international_vat.required"
              >
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
            </div>
          </div>

          <!--          FORM CODICE FISCALE ITA-->
          <div class="form-group col-md-6" v-if="isItalia">
            <label for="checkout-codicefiscale">
              {{ $trans("form-input.codice-fiscale") }}
              <small v-if="clientType !== 'b2b'">
                ({{ $trans("form-input.facoltativo") }})
              </small>
              <sup v-else>*</sup>
              <!-- <button
              v-if="clientType === 'b2b'"
              type="button"
              class="btn btn-black btn-xs"
              :disabled="userCheck"
              @click="cod_fiscale = p_iva"
              >
              {{ $trans("form-input.copia-pIva") }}
              </button> -->
            </label>
            <input
                :class="{ 'is-invalid': $v.cod_fiscale.$error }"
                :readonly="userCheck"
                class="form-control"
                id="checkout-codicefiscale"
                name="cod_fiscale"
                type="text"
                :required="clientType === 'b2b' && isItalia"
                v-model.trim="$v.cod_fiscale.$model"
            />
            <div v-if="$v.cod_fiscale.$error">
              <div class="vue-field-error" v-if="!$v.cod_fiscale.validCF">
                {{ $trans("form-input.cod-fiscale-invalid") }}
              </div>
            </div>
          </div>

          <!--          FORM CODICE FISCALE ESTERO-->
          <div class="form-group col-md-6" v-if="!isItalia">
            <label for="checkout-codicefiscale">
              {{ $trans("form-input.codice-fiscale") }}
              <small v-if="clientType !== 'b2b'">
                ({{ $trans("form-input.facoltativo") }})
              </small>
              <sup v-else>*</sup>
            </label>
            <input
                :readonly="userCheck"
                class="form-control"
                name="cod_fiscale"
                type="text"
                :required="clientType === 'b2b'"
                v-model="international_cod_fiscale"
            />
            <!--            <div v-if="$v.cod_fiscale.$error">-->
            <!--              <div class="vue-field-error" v-if="!$v.cod_fiscale.validCF">-->
            <!--                {{ $trans("form-input.cod-fiscale-invalid") }}-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>

        <button
            :class="{ 'btn-loading': loadingCheckPIVA }"
            :disabled="
!p_iva ||
!email ||
!cod_fiscale ||
$v.p_iva.$error ||
$v.email.$error ||
$v.cod_fiscale.$error
"
            class="btn btn-primary"
            type="button"
            v-if="
(!userCheck && clientType === 'b2b' && isItalia) ||
(!userCheck && clientType === 'b2b' && !companyUser)
"
            v-on:click="checkPIVA()"
        >
          {{ $trans("form-input.continua") }}
        </button>
        <button
            :class="{ 'btn-loading': loadingCheckPIVA }"
            :disabled="!email || $v.email.$error || $v.cod_fiscale.$error"
            class="btn btn-primary"
            type="button"
            v-if="
(!userCheck && clientType === 'b2c' && isItalia) ||
(!userCheck && clientType === 'b2c' && !companyUser)
"
            v-on:click="checkCF()"
        >
          {{ $trans("form-input.continua") }}
        </button>
        <button
            :disabled="!email || $v.email.$error || international_vat.length <= 5"
            class="btn btn-primary"
            type="button"
            v-if="!userCheck && clientType === 'b2b' && !isItalia"
            v-on:click="checkCF()"
        >
          {{ $trans("form-input.continua") }}
        </button>
        <button
            class="btn btn-primary"
            type="button"
            v-if="userCheck"
            v-on:click="() => resetPrincipalData()"
        >
          {{ $trans("form-input.reset") }}
        </button>
      </div>
    </div>
    <div class="card mb-4" v-if="userCheck && !isGuest">
      <div class="card-body card-body--padding--2">
        <h3 class="card-title">{{ $trans("form-input.credenziali") }}</h3>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="checkout-password"
            >{{ $trans("form-input.password") }}<sup>*</sup></label
            >
            <input
                :class="{ 'is-invalid': $v.password.$error }"
                class="form-control"
                id="checkout-password"
                name="password"
                type="password"
                v-model="$v.password.$model"
            />
            <div v-if="$v.password.$error">
              <div class="vue-field-error" v-if="!$v.password.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
              <div class="vue-field-error" v-if="!$v.password.security">
                {{ $trans("form-input.password-invalid") }}
              </div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="checkout-confirm-password"
            >{{ $trans("form-input.confirm-password") }}<sup>*</sup></label
            >
            <input
                :class="{ 'is-invalid': $v.confirmPassword.$error }"
                class="form-control"
                id="checkout-confirm-password"
                name="password"
                type="password"
                v-model="$v.confirmPassword.$model"
            />
            <div v-if="$v.confirmPassword.$error">
              <div class="vue-field-error" v-if="!$v.confirmPassword.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
              <div
                  class="vue-field-error"
                  v-if="!$v.confirmPassword.sameAsPassword"
              >
                {{ $trans("form-input.password-match") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4" v-show="userCheck">
      <div class="card-body card-body--padding--2">
        <h3 class="card-title" v-if="clientType === 'b2b'">
          {{ $trans("sign-up.azienda") }}
        </h3>
        <h3 class="card-title" v-else>{{ $trans("sign-up.privato") }}</h3>
        <div class="alert alert-primary" v-if="clientId">
          {{
            clientType === "b2c"
                ? "Il tuo codice fiscale"
                : "La tua partita IVA"
          }}
          {{ $trans("form-input.presente-archivio") }}
        </div>
        <!--                <div v-show="!clientId">-->
        <h4 v-if="spedDifferent">
          {{ $trans("form-input.indirizzo-fatturazione") }}
        </h4>
        <div class="form-row" v-if="clientType === 'b2b'">
          <div class="form-group col-md-12">
            <label for="checkout-ragione-sociale"
            >{{ $trans("form-input.ragione-sociale") }} <sup>*</sup></label
            >
            <input
                :class="{ 'is-invalid': $v.ragione_soc.$error }"
                class="form-control"
                id="checkout-ragione-sociale"
                name="ragione_soc"
                required
                type="text"
                v-model.trim="$v.ragione_soc.$model"
                :readonly="fromClient.ragione_soc && !$v.ragione_soc.$error"
            />
            <div v-if="$v.ragione_soc.$error">
              <div class="vue-field-error" v-if="!$v.ragione_soc.required">
                {{ $trans("form-input.campo-obbligatiorio") }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" v-else>
          <div class="form-group col-md-12">
            <label for="checkout-first-name"
            >{{ $trans("form-input.nome-cognome") }} <sup>*</sup></label
            >
            <input
                :class="{ 'is-invalid': $v.ragione_soc.$error }"
                class="form-control"
                id="checkout-first-name"
                name="ragione_soc"
                required
                type="text"
                v-model.trim="$v.ragione_soc.$model"
            />
            <div v-if="$v.ragione_soc.$error">
              <div class="vue-field-error" v-if="!$v.ragione_soc.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" v-if="clientType === 'b2b'">
          <div class="form-group col-md-6">
            <label for="checkout-codicesdi">{{
                $trans("form-input.cod-sdi")
              }}</label>
            <input
                class="form-control"
                id="checkout-codicesdi"
                name="identificativo_fatt_elett"
                required
                type="text"
                v-model="identificativo_fatt_elett"
                :readonly="
!!fromClient.identificativo_fatt_elett &&
fromClient.identificativo_fatt_elett.length > 0
"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="checkout-codicesdi">{{
                $trans("form-input.pec")
              }}</label>
            <input
                :class="{ 'is-invalid': $v.pec.$error }"
                class="form-control"
                id="checkout-pec"
                name="pec"
                required
                type="text"
                v-model.trim="$v.pec.$model"
                :readonly="
!!fromClient.pec && fromClient.pec.length > 0 && !$v.pec.$error
"
            />
            <div v-if="$v.pec.$error">
              <div class="vue-field-error" v-if="!$v.pec.email">
                {{ $trans("form-input.email-invalid") }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="checkout-street-address-newadd"
            >{{ $trans("form-input.indirizzo") }}<sup>*</sup></label
            >
            <input
                :class="{ 'is-invalid': $v.indirizzo.$error }"
                class="form-control"
                id="checkout-street-address-newadd"
                name="indirizzo"
                required
                type="text"
                v-model.trim="$v.indirizzo.$model"
                :readonly="
fromClient.indirizzo &&
!$v.indirizzo.$error &&
clientType === 'b2b'
"
            />
            <div v-if="$v.indirizzo.$error">
              <div class="vue-field-error" v-if="!$v.indirizzo.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
            </div>
          </div>
        </div>


        <div class="form-row">
          <div class="form-group col-md-2">
            <label for="checkout-postcode-newadd"
            >{{ $trans("form-input.cap") }}<sup>*</sup></label
            >
            <div class="select2-wrapper">
              <select
                  aria-label="Seleziona CAP"
                  class="form-control"
                  id="checkout-postcode-newadd"
                  name="cap"
                  required
                  v-model="$v.cap.$model"
              ></select>
            </div>
            <div v-if="$v.cap.$error">
              <div class="vue-field-error" v-if="!$v.cap.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
            </div>
          </div>
          
          <div class="form-group col-md-8">
            <label for="checkout-city-newadd"
            >{{ $trans("form-input.city") }}<sup>*</sup></label
            >
            <div class="select2-wrapper">
              <select
                  aria-label="Seleziona città"
                  class="form-control"
                  id="checkout-city-newadd"
                  name="citta"
                  required
                  v-bind:disabled="
!cap ||
loadingCities ||
(fromClient.citta && !$v.citta.$error && clientType === 'b2b')
"
                  v-model="$v.citta.$model"
              >
                <option
                    :value="city.citta"
                    v-bind:key="city.citta"
                    v-for="city in cities"
                >
                  {{ city.citta }}
                </option>
              </select>
            </div>
            <div v-if="$v.citta.$error">
              <div class="vue-field-error" v-if="!$v.citta.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
            </div>
          </div>

          <!--          INPUT CITTA ESTERO-->
<!--          <div class="form-group col-md-8" v-if="!isItalia">-->
<!--            <label for="checkout-city-newadd"-->
<!--            >{{ $trans("form-input.city") }}<sup>*</sup></label-->
<!--            >-->
<!--            <input-->
<!--                v-bind:disabled="fromClient.citta && clientType === 'b2b'"-->
<!--                v-model="$v.international_city.$model"-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--            />-->
<!--            <div v-if="$v.international_city.$error">-->
<!--              <div-->
<!--                  class="vue-field-error"-->
<!--                  v-if="!$v.international_city.required"-->
<!--              >-->
<!--                {{ $trans("form-input.campo-obbligatorio") }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <div class="form-group col-md-2" v-if="isItalia">
            <label for="checkout-state-newadd"
            >{{ $trans("form-input.provincia") }}<sup>*</sup></label
            >
            <input
                class="form-control"
                id="checkout-state-newadd"
                maxlength="2"
                name="provincia"
                required
                type="text"
                v-model.trim="$v.provincia.$model"
                :readonly="
fromClient.provincia &&
!$v.provincia.$error &&
clientType === 'b2b'
"
            />
            <div v-if="$v.provincia.$error">
              <div class="vue-field-error" v-if="!$v.provincia.required">
                {{ $trans("form-input.campo-obbligatorio") }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="checkout-tel-newadd">{{
                $trans("form-input.telefono")
              }}</label>
            <input
                class="form-control"
                id="checkout-tel-newadd"
                name="telefono"
                type="tel"
                v-model.trim="$v.telefono.$model"
            />
            <div v-if="$v.telefono.$error">
              <div class="vue-field-error" v-if="!$v.telefono.numeric">
                {{ $trans("form-input.telefono-solo-numeri") }}
              </div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="checkout-cell-newadd">{{
                $trans("form-input.cellulare")
              }}</label>
            <input
                class="form-control"
                id="checkout-cell-newadd"
                name="cellulare"
                type="tel"
                v-model.trim="$v.cellulare.$model"
            />
            <div v-if="$v.cellulare.$error">
              <div class="vue-field-error" v-if="!$v.cellulare.numeric">
                {{ $trans("form-input.telefono-solo-numeri") }}
              </div>
            </div>
          </div>
        </div>

        <div v-show="spedDifferent">
          <h4>{{ $trans("form-input.indirizzo-spedizione") }}</h4>
          <div class="form-row" v-if="clientType === 'b2b'">
            <div class="form-group col-md-12">
              <label for="checkout-ragione-sociale_sped"
              >{{ $trans("form-input.ragione-sociale") }} <sup>*</sup></label
              >
              <input
                  :class="{ 'is-invalid': $v.ragione_soc_sped.$error }"
                  class="form-control"
                  id="checkout-ragione-sociale_sped"
                  name="ragione_soc_sped"
                  required
                  type="text"
                  v-model.trim="$v.ragione_soc_sped.$model"
              />
              <div v-if="$v.ragione_soc_sped.$error">
                <div
                    class="vue-field-error"
                    v-if="!$v.ragione_soc_sped.required"
                >
                  {{ $trans("form-input.campo-obbligatorio") }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-row" v-else>
            <div class="form-group col-md-12">
              <label for="checkout-first-name_sped"
              >{{ $trans("form-input.nome-cognome") }} <sup>*</sup></label
              >
              <input
                  :class="{ 'is-invalid': $v.ragione_soc_sped.$error }"
                  class="form-control"
                  id="checkout-first-name_sped"
                  name="ragione_soc_sped"
                  required
                  type="text"
                  v-model.trim="$v.ragione_soc_sped.$model"
              />
              <div v-if="$v.ragione_soc.$error">
                <div
                    class="vue-field-error"
                    v-if="!$v.ragione_soc_sped.required"
                >
                  {{ $trans("form-input.campo-obbligatorio") }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <!--            INDIRIZZO SPEDIZIONE-->
            <div class="form-group col-md-6">
              <label for="checkout-country-newadd_sped"
              >{{ $trans("form-input.nazione") }}<sup>*</sup></label
              >
              <select
                  :class="{ 'is-invalid': $v.nazione_sped.$error }"
                  class="form-control"
                  id="checkout-country-newadd_sped"
                  name="nazione"
                  v-model="$v.nazione_sped.$model"
              >
                <option v-for="item in nations">
                  {{ item.nazione }}
                </option>
              </select>
              <div v-if="$v.nazione_sped.$model && nations.find(el => el.nazione === $v.nazione_sped.$model).blacklistEcommerce">
                <div class="vue-field-error">
                  {{ $trans("form-input.nazione-blacklist") }}
                </div>
              </div>
              <div v-if="$v.nazione_sped.$error">
                <div class="vue-field-error" v-if="!$v.nazione_sped.required">
                  {{ $trans("form-input.campo-obbligatorio") }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="checkout-street-address-newadd_sped"
              >{{ $trans("form-input.indirizzo") }}<sup>*</sup></label
              >
              <input
                  :class="{ 'is-invalid': $v.indirizzo_sped.$error }"
                  class="form-control"
                  id="checkout-street-address-newadd_sped"
                  name="indirizzo_sped"
                  required
                  type="text"
                  v-model.trim="$v.indirizzo_sped.$model"
              />
              <div v-if="$v.indirizzo_sped.$error">
                <div class="vue-field-error" v-if="!$v.indirizzo_sped.required">
                  {{ $trans("form-input.campo-obbligatorio") }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-2">
              <label for="checkout-postcode-newadd_sped"
              >{{ $trans("form-input.cap") }}<sup>*</sup></label
              >
              <div class="select2-wrapper">
                <select
                    aria-label="Seleziona CAP"
                    class="form-control"
                    id="checkout-postcode-newadd_sped"
                    name="cap_sped"
                    required
                    v-model="$v.cap_sped.$model"
                ></select>
              </div>
              <div v-if="$v.cap_sped.$error">
                <div class="vue-field-error" v-if="!$v.cap_sped.required">
                  {{ $trans("form-input.campo-obbligatorio") }}
                </div>
              </div>
            </div>

<!--            <div-->
<!--                class="form-group col-md-2"-->
<!--                :class="{ 'd-none': isItalia_sped }"-->
<!--            >-->
<!--              <label>{{ $trans("form-input.cap") }}<sup>*</sup></label>-->
<!--              <div class="select2-wrapper">-->
<!--                <input-->
<!--                    class="form-control"-->
<!--                    :class="{ 'd-none': isItalia_sped }"-->
<!--                    type="text"-->
<!--                    v-model="$v.international_cap_sped.$model"-->
<!--                    required-->
<!--                />-->
<!--              </div>-->
<!--              <div v-if="$v.international_cap_sped.$error">-->
<!--                <div-->
<!--                    class="vue-field-error"-->
<!--                    v-if="!$v.international_cap_sped.required"-->
<!--                >-->
<!--                  {{ $trans("form-input.campo-obbligatorio") }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <div class="form-group col-md-8">
              <label for="checkout-city-newadd_sped"
              >{{ $trans("form-input.city") }}<sup>*</sup></label
              >
              <div class="select2-wrapper">
                <select
                    aria-label="Seleziona città"
                    class="form-control"
                    id="checkout-city-newadd_sped"
                    name="citta_sped"
                    required
                    v-bind:disabled="!$v.cap_sped.$model || loadingCities"
                    v-model="$v.citta_sped.$model"
                >
                  <option
                      :value="city.citta"
                      v-bind:key="city.citta"
                      v-for="city in cities_sped"
                  >
                    {{ city.citta }}
                  </option>
                </select>
              </div>
              <div v-if="$v.citta_sped.$error">
                <div class="vue-field-error" v-if="!$v.citta_sped.required">
                  {{ $trans("form-input.campo-obbligatorio") }}
                </div>
              </div>
            </div>

<!--            <div class="form-group col-md-8" v-if="!isItalia_sped">-->
<!--              <label-->
<!--                  v-model="$v.international_city_sped.$model"-->
<!--                  for="checkout-city-newadd"-->
<!--              >{{ $trans("form-input.city") }}<sup>*</sup></label-->
<!--              >-->
<!--              <input-->
<!--                  v-model="$v.international_city_sped.$model"-->
<!--                  type="text"-->
<!--                  class="form-control"-->
<!--              />-->
<!--            </div>-->

            <div class="form-group col-md-2" v-if="isItalia_sped">
              <label for="checkout-state-newadd_sped"
              >{{ $trans("form-input.provincia") }}<sup>*</sup></label
              >
              <input
                  class="form-control"
                  id="checkout-state-newadd_sped"
                  maxlength="2"
                  name="provincia_sped"
                  required
                  type="text"
                  v-model.trim="$v.provincia_sped.$model"
                  :readonly="
fromClient.provincia &&
!$v.provincia_sped.$error &&
clientType === 'b2b'
"
              />
              <div v-if="$v.provincia_sped.$error">
                <div class="vue-field-error" v-if="!$v.provincia_sped.required">
                  {{ $trans("form-input.campo-obbligatorio") }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="checkout-tel-newadd_sped">{{
                  $trans("form-input.telefono")
                }}</label>
              <input
                  class="form-control"
                  id="checkout-tel-newadd_sped"
                  name="telefono_sped"
                  type="tel"
                  v-model.trim="$v.telefono_sped.$model"
              />
              <div v-if="$v.telefono_sped.$error">
                <div class="vue-field-error" v-if="!$v.telefono_sped.numeric">
                  {{ $trans("form-input.telefono-solo-numeri") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="checkout__agree form-group mb-3">
          <div class="form-check">
<span class="input-check form-check-input">
<span class="input-check__body">
<input
    class="input-check__input"
    id="sped-diff"
    required
    type="checkbox"
    v-model="spedDifferent"
/>
<span class="input-check__box"></span>
<span class="input-check__icon"
><svg
    height="7px"
    width="9px"
    xmlns="http://www.w3.org/2000/svg"
>
<path
    d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"
/>
</svg>
</span>
</span>
</span>
            <label class="form-check-label" for="sped-diff">
              {{ $trans("form-input.indirizzo-diverso-spedizione") }}
            </label>
          </div>
        </div>

        <hr/>
        <!--                </div>-->

        <div class="checkout__agree form-group mb-3">
          <div class="form-check">
<span class="input-check form-check-input">
<span class="input-check__body">
<input
    class="input-check__input"
    id="privacy-policy"
    required
    type="checkbox"
    v-model="$v.privacy.$model"
/>
<span class="input-check__box"></span>
<span class="input-check__icon"
><svg
    height="7px"
    width="9px"
    xmlns="http://www.w3.org/2000/svg"
>
<path
    d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"
/>
</svg>
</span>
</span>
</span>
            <label class="form-check-label" for="privacy-policy">
              {{ $trans("form-input.dichiarazione") }}
              <a :href="policy_path" target="_blank"
              >{{ $trans("sign-up.privacy-policy") }}<sup>*</sup></a
              >
            </label>
          </div>
          <div v-if="$v.privacy.$error">
            <div class="vue-field-error" v-if="!$v.privacy.sameAs">
              {{ $trans("form-input.conferma-lettura") }}
            </div>
          </div>
        </div>

        <div class="alert alert-danger" v-if="signupErrors.length > 0">
          <p class="m-0" v-for="error in signupErrors">{{ error }}</p>
        </div>
        <button
            :class="{ 'btn-loading': loadingSignup }"
            class="btn btn-primary"
            type="submit"
        >
          {{
            isGuest
                ? $trans("sign-up.continua-come-ospite")
                : $trans("sign-up.registrati")
          }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import {CustomSwal, checkFiscalCode} from "../utils";
import {checkVATNumber} from "../vat-checker";
import {validationMixin} from "vuelidate";
import {
  required,
  helpers,
  email,
  sameAs,
  numeric,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  name: "sign-up",
  mixins: [validationMixin],
  props: {
    policy_path: {
      type: String,
      required: true,
    },
    referrer: {
      type: String,
      required: false,
      default: "",
    },
    isGuest: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      clientType: null,
      ragione_soc: "",
      ragione_soc_sped: "",
      p_iva: "",
      identificativo_fatt_elett: "",
      cod_fiscale: "",
      nazione: "",
      indirizzo: "",
      indirizzo_sped: "",
      cap: "",
      cap_sped: "",
      citta: "",
      citta_sped: "",
      provincia: "",
      provincia_sped: "",
      telefono: "",
      telefono_sped: "",
      email: "",
      cellulare: "",
      cities: [],
      cities_sped: [],
      loadingCities: false,
      pec: "",
      loadingCheckPIVA: false,
      password: "",
      confirmPassword: "",
      userCheck: false,
      isVATValid: false,
      signupErrors: [],
      loadingSignup: false,
      clientId: null,
      privacy: false,
      spedDifferent: false,
      fromClient: {},
      nations: [],
      isItalia: false,
      international_cap: "",
      international_city: "",
      international_cap_sped: "",
      international_city_sped: "",
      nazione_sped: "",
      isItalia_sped: false,
      international_vat: "",
      international_cod_fiscale: "",
      companyUser: false,
    };
  },
  validations: {
    clientType: {
      required,
    },
    email: {
      required,
      email,
    },
    international_city: {
      required: requiredIf(function () {
        return false;//!this.isItalia;
      }),
    },
    international_vat: {
      required: requiredIf(function () {
        return !this.isItalia && this.companyUser;
      }),
    },
    p_iva: {
      required: requiredIf(function () {
        return this.clientType === "b2b" && this.isItalia;
      }),
      validVAT: (value) =>
          !helpers.req(value) || checkVATNumber("IT" + value) !== false,
    },
    cod_fiscale: {
      validCF: function (value) {
        return (
            !helpers.req(value) ||
            checkFiscalCode(value.toUpperCase()) !== false ||
            (this.clientType === "b2b" && checkVATNumber("IT" + value) !== false)
        );
      },
// validCF: (value) => !helpers.req(value) || (checkFiscalCode(value.toUpperCase()) !== false || (this.clientType === 'b2b' && checkVATNumber('IT' + value) !== false))
    },
    password: {
      required: requiredIf(function () {
        return !this.isGuest;
      }),
      security: (value) =>
          !helpers.req(value) ||
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value),
    },
    confirmPassword: {
      required: requiredIf(function () {
        return !this.isGuest;
      }),
      sameAsPassword: sameAs("password"),
    },
    ragione_soc: {
      required,
    },
    ragione_soc_sped: {
      required: requiredIf(function () {
        return this.spedDifferent;
      }),
    },
    pec: {
      email,
    },
    nazione: {
      required,
    },
    indirizzo: {
      required,
    },
    indirizzo_sped: {
      required: requiredIf(function () {
        return this.spedDifferent;
      }),
    },
    cap: {
      required: requiredIf(function () {
        return true;//this.isItalia;
      }),
    },
    international_cap: {
      required: requiredIf(function () {
        return false;//!this.isItalia;
      }),
    },
    international_cap_sped: {
      required: requiredIf(function () {
        return false;//this.spedDifferent && !this.isItalia_sped;
      }),
    },
    cap_sped: {
      required: requiredIf(function () {
        return this.spedDifferent;//&& this.isItalia_sped;
      }),
    },
    citta: {
      required: requiredIf(function () {
        return true;//this.isItalia;
      }),
    },
    citta_sped: {
      required: requiredIf(function () {
        return this.spedDifferent;//&& this.isItalia_sped;
      }),
    },
    international_city_sped: {
      required: requiredIf(function () {
        return false;//this.spedDifferent && !this.isItalia_sped;
      }),
    },
    provincia: {
      required: requiredIf(function () {
        return this.isItalia;
      }),
    },
    provincia_sped: {
      required: requiredIf(function () {
        return this.spedDifferent && this.isItalia_sped;
      }),
    },
    telefono: {
      numeric,
    },
    telefono_sped: {
      numeric,
    },
    cellulare: {
      numeric,
    },
    nazione_sped: {
      required: requiredIf(function () {
        return this.spedDifferent;
      }),
    },
    privacy: {
      sameAs: sameAs(() => true),
    },
  },
  mounted() {
    const self = this;
    axios.get("/api/v1/nations").then((res) => {
      console.log(res.data.data[0]);
      this.nations = res.data.data[0];
      this.nazione = "ITALIA";
    });

    $("#checkout-postcode-newadd_sped").select2({
      minimumInputLength: 3,
      language: "it",
      width: "100%",
      ajax: {
        url: "/api/v1/cap",
        dataType: "json",
        data: function (params) {
          console.log('sel1');
          return {
            q: params.term,
            nation: document.querySelector('#checkout-country-newadd_sped').value
          };
        },
        processResults: function (data) {
          return {
            results: data.data.map((el) => {
              return {
                id: el.cap,
                text: el.cap,
              };
            }),
          };
        },
      },
    });

    $("#checkout-postcode-newadd").on("change", function () {
      self.cap = this.value;
    });

    $("#checkout-postcode-newadd_sped").on("change", function () {
      self.cap_sped = this.value;
    });

    $("#checkout-city-newadd").select2({
      language: "it",
      width: "100%",
    });

    $("#checkout-city-newadd_sped").select2({
      language: "it",
      width: "100%",
    });

    $("#checkout-city-newadd").on("change", function () {
      self.citta = this.value;
    });

    $("#checkout-city-newadd_sped").on("change", function () {
      self.citta_sped = this.value;
    });
  },
  methods: {
    getCap(value) {
      console.log("qui");
      if (value == false) {
        $("#checkout-postcode-newadd").select2({
          minimumInputLength: 3,
          language: "it",
          width: "100%",
          ajax: {
            url: "/api/v1/cap",
            dataType: "json",
            data: function (params) {
              console.log('sel3');
              return {
                q: params.term,
                nation: document.querySelector('#checkout-country-newadd').value
              };
            },
            processResults: function (data) {
              console.log("quo?", data.data);
              return {
                results: data.data.map((el) => {
                  return {
                    id: el.cap,
                    text: el.cap,
                  };
                }),
              };
            },
          },
        });
      } else {
        console.log("false");
        $("#checkout-postcode-newadd_sped").select2({
          minimumInputLength: 3,
          language: "it",
          width: "100%",
          ajax: {
            url: "/api/v1/cap",
            dataType: "json",
            data: function (params) {
              console.log('sel2');
              return {
                q: params.term,
                nation: document.querySelector('#checkout-country-newadd_sped').value
              };
            },
            processResults: function (data) {
              return {
                results: data.data.map((el) => {
                  return {
                    id: el.cap,
                    text: el.cap,
                  };
                }),
              };
            },
          },
        });
      }
    },
    resetPrincipalData: function (total = true) {
      if(total) {
        this.p_iva = this.cod_fiscale = this.email = "";
      }
      this.ragione_soc =
          this.indirizzo =
              this.identificativo_fatt_elett =
                  this.pec =
                      this.citta =
                          this.cap =
                              this.provincia =
                                  this.telefono =
                                      this.cellulare =
                                          this.ragione_soc_sped =
                                              this.telefono_sped =
                                                  this.indirizzo_sped = "";
      this.clientId = null;
      this.userCheck = this.spedDifferent = false;
      this.fromClient = {};

      $("#checkout-postcode-newadd").val(null).trigger("change");
      $("#checkout-city-newadd").val(null).trigger("change");
      $("#checkout-postcode-newadd_sped").val(null).trigger("change");
      $("#checkout-city-newadd_sped").val(null).trigger("change");
    },
    checkPIVA: function () {
      this.$v.email.$touch();
      this.$v.p_iva.$touch();
      if (
          this.p_iva &&
          this.email &&
          !this.$v.p_iva.$error &&
          !this.email.$error
      ) {
        this.loadingCheckPIVA = true;
        axios
            .get(`/api/v1/client/check-vat?vat=${this.p_iva}&email=${this.email}&country=${this.nazione}`)
            .then((result) => {
              let client = null;
              if (result.data.data && result.data.data.length > 0) {
                client = result.data.data[0];
              }
              this.checkEmail(client);
            })
            .catch(res => {
              const resp = res.response.data;
              this.loadingCheckPIVA = false;
              CustomSwal({
                title: "Avviso",
                text: resp.errors && resp.errors.includes('Iva code not valid')
                    ? "La partita iva inserita non risulta valida"
                    : "La partita iva inserita è già presente nel nostro archivio clienti, la tua richiesta di registrazione verrà elaborata dai nostri uffici e ti verrà inviata via mail la password di accesso",
                confirmButtonText: "Chiudi",
                icon: "info",
              });
            });
      }
    },
    checkCF: function () {
      this.$v.email.$touch();
      this.$v.cod_fiscale.$touch();

      if (this.email && !this.$v.email.$error && !this.$v.cod_fiscale.$error) {
        this.loadingCheckPIVA = true;
        if (this.cod_fiscale) {
          axios
              .get(
                  "/api/v1/client/check-fiscal-code?fiscal_code=" + this.cod_fiscale
              )
              .then((result) => {
                let client = null;
                if (result.data.data && result.data.data.length > 0) {
                  client = result.data.data[0];
                }
                this.checkEmail(client);
              })
              .catch((error) => {
                this.loadingCheckPIVA = false;
                CustomSwal({
                  title: "Errore",
                  html: error.response.data.errors.join("<br>"),
                  confirmButtonText: "Ok",
                  icon: "error",
                });
              });
        } else {
          this.checkEmail();
        }
      }
    },
    checkEmail: function (client) {
      this.loadingCheckPIVA = true;
      axios
          .get("/api/v1/user/check-email?email=" + this.email)
          .then(() => {
            if (client) {
              this.clientId = client.id;
              this.ragione_soc = client.ragione_soc;
              this.indirizzo = client.indirizzo;
              this.citta = client.citta.toUpperCase();
              this.provincia = client.provincia;
              this.telefono = client.telefono;
              this.cellulare = client.cellulare;
              this.identificativo_fatt_elett = client.identificativo_fatt_elett;
              this.pec = client.pec;

              this.fromClient = JSON.parse(JSON.stringify(client));

              const capData = {
                id: client.cap,
                text: client.cap,
              };

              const option = new Option(capData.text, capData.id, true, true);
              const capField = $("#checkout-postcode-newadd");
              capField.append(option).trigger("change");
              capField.trigger({
                type: "select2:select",
                params: {
                  data: capData,
                },
              });
            }
            this.userCheck = true;
          })
          .catch((error) => {
            CustomSwal({
              title: "Errore",
              html: error.response.data.errors.join("<br>"),
              confirmButtonText: "Ok",
              icon: "error",
            });
          })
          .finally(() => {
            this.loadingCheckPIVA = false;
          });
    },
    loadCities: function () {
      this.loadingCities = true;
      axios
          .get("/api/v1/city?q=" + this.cap  + "&nation=" + this.nazione)
          .then((res) => {
            this.cities = res.data.data;

            if (!this.loadinProfile) {
              if (this.cities.length === 1) {
                this.citta = this.cities[0].citta;
              } else {
                if (
                    this.fromClient.citta &&
                    this.cities.find(
                        (el) =>
                            el.citta.toUpperCase() ===
                            this.fromClient.citta.toUpperCase()
                    )
                ) {
                  this.citta = this.fromClient.citta.toUpperCase();
                } else {
                  this.fromClient.citta = null;
                  this.fromClient.provincia = null;
                  this.citta = "";
                  this.provincia = "";
                  if (this.cities.length === 0) {
                    this.cap = "";
                    this.fromClient.cap = null;
                    $("#checkout-postcode-newadd").val(null).trigger("change");
                  }
                }
              }
            } else {
              this.loadinProfile = false;
            }
          })
          .finally(() => {
            this.loadingCities = false;
          });
    },
    loadCitiesSped: function () {
      this.loadingCities = true;
      axios
          .get("/api/v1/city?q=" + this.cap_sped + "&prova=true&nation=" + this.nazione_sped)
          .then((res) => {
            this.cities_sped = res.data.data;

            if (!this.loadinProfile) {
              if (this.cities_sped.length === 1) {
                this.citta_sped = this.cities[0].citta;
              } else {
                if (
                    this.fromClient.citta &&
                    this.cities_sped.find(
                        (el) =>
                            el.citta.toUpperCase() ===
                            this.fromClient.citta.toUpperCase()
                    )
                ) {
                  this.citta_sped = this.fromClient.citta.toUpperCase();
                } else {
                  this.fromClient.citta = null;
                  this.fromClient.provincia = null;
                  this.citta_sped = "";
                  this.provincia_sped = "";
                  if (this.cities_sped.length === 0) {
                    this.cap_sped = "";
                    this.fromClient.cap = null;
                    $("#checkout-postcode-newadd_sped")
                        .val(null)
                        .trigger("change");
                  }
                }
              }
            } else {
              this.loadinProfile = false;
            }
          })
          .finally(() => {
            this.loadingCities = false;
          });
    },
    submit: function () {
      this.signupErrors = [];
      this.$v.$touch();
      // console.log("v", this.$v.$invalid, this.$v, {
      //   id: this.clientId,
      //   client_type: this.clientType,
      //   email: this.email,
      //   password: this.password,
      //   confirmPassword: this.confirmPassword,
      //   p_iva: this.isItalia ? this.p_iva : this.international_vat,
      //   cod_fiscale: this.isItalia
      //       ? this.cod_fiscale
      //       : this.international_cod_fiscale,
      //   ragione_soc: this.ragione_soc,
      //   identificativo_fatt_elett: this.identificativo_fatt_elett,
      //   pec: this.pec,
      //   nazione: this.nazione,
      //   indirizzo: this.indirizzo,
      //   provincia: this.provincia,
      //   telefono: this.telefono,
      //   cellulare: this.cellulare,
      //   referrer: this.referrer,
      //   isGuest: this.isGuest,
      //   spedDifferent: this.spedDifferent,
      //   ragione_soc_sped: this.ragione_soc_sped,
      //   nazione_sped: this.nazione_sped,
      //   indirizzo_sped: this.indirizzo_sped,
      //   provincia_sped: this.provincia_sped,
      //   telefono_sped: this.telefono_sped,
      //   cap: this.isItalia ? this.cap : '',
      //   citta: this.isItalia ? this.citta : '',
      //   cap_sped: this.isItalia ? this.cap_sped : '',
      //   citta_sped: this.isItalia ? this.citta_sped : '',
      //   international_cap: !this.isItalia ? this.cap : '',
      //   international_city: !this.isItalia ? this.citta : '',
      //   international_city_sped: !this.isItalia ? this.citta_sped : '',
      //   international_cap_sped: !this.isItalia ? this.cap_sped : '',
      // });return;
      if (this.$v.$invalid === false) {
        this.loadingSignup = true;
        axios.post("/api/v1/check-address", {
          cap: this.cap,
          citta: this.citta,
          nazione: this.nazione,
        }).then(resp => {
          const r = resp.data;
          console.log(resp);
          if(r.success) {
            const nazione = r.data[0].nation.nazione.toUpperCase();
            axios
                .post("/api/v1/user/register", {
                  id: this.clientId,
                  client_type: this.clientType,
                  email: this.email,
                  password: this.password,
                  confirmPassword: this.confirmPassword,
                  p_iva: this.isItalia ? this.p_iva : this.international_vat,
                  cod_fiscale: this.isItalia
                      ? this.cod_fiscale
                      : this.international_cod_fiscale,
                  ragione_soc: this.ragione_soc,
                  identificativo_fatt_elett: this.identificativo_fatt_elett,
                  pec: this.pec,
                  nazione: this.nazione.toUpperCase() !== nazione ? nazione : this.nazione.toUpperCase(),
                  indirizzo: this.indirizzo,
                  provincia: this.provincia,
                  telefono: this.telefono,
                  cellulare: this.cellulare,
                  referrer: this.referrer,
                  isGuest: this.isGuest,
                  spedDifferent: this.spedDifferent,
                  ragione_soc_sped: this.ragione_soc_sped,
                  nazione_sped: this.nazione_sped,
                  indirizzo_sped: this.indirizzo_sped,
                  provincia_sped: this.provincia_sped,
                  telefono_sped: this.telefono_sped,
                  cap: this.isItalia ? this.cap : '',
                  citta: this.isItalia ? this.citta : '',
                  cap_sped: this.isItalia ? this.cap_sped : '',
                  citta_sped: this.isItalia ? this.citta_sped : '',
                  international_cap: !this.isItalia ? this.cap : '',
                  international_city: !this.isItalia ? this.citta : '',
                  international_city_sped: !this.isItalia ? this.citta_sped : '',
                  international_cap_sped: !this.isItalia ? this.cap_sped : '',
                })
                .then((res) => {
                  CustomSwal({
                    title: this.$trans("js-files.benvenuto-title"),
                    text: this.$trans("js-files.benvenuto"),
                    confirmButtonText: "Ok",
                    icon: "success",
                  }).then(() => {
                    console.log(res);
                    if (res.status === 204) {
                      window.location.href = "/";
                    } else {
                      if (res.data.data.type === "path") {
                        window.location.href = res.data.data.path;
                      } else {
                        window.location.reload();
                      }
                    }
                  });
                })
                .catch((error) => {
                  console.log(error);
                  if (error.response.status === 422) {
                    this.signupErrors = error.response.data.errors;
                  } else {
                    CustomSwal({
                      title: "Errore",
                      html: error.response.data.errors.join("<br>"),
                      confirmButtonText: "Ok",
                      icon: "error",
                    });
                  }
                })
                .finally(() => {
                  this.loadingSignup = false;
                });
          }
        }).catch(e => {
          console.error(e);
          this.loadingSignup = false;
          CustomSwal({
            title: "Errore",
            html: this.$trans("sign-up.errore-spedizione"),
            confirmButtonText: "Ok",
            icon: "error",
          });
        });
      } else {
        console.log("invalid");
      }
    },
  },
  computed: {},
  watch: {
    nazione_sped: function () {
      this.nazione_sped === "ITALIA"
          ? (this.isItalia_sped = true)
          : (this.isItalia_sped = false);
      if (this.nazione_sped === "ITALIA") {
        this.getCap(true);
        this.international_cap_sped = "";
      }
    },
    nazione: function () {
      this.userCheck = false;
      this.nazione === "ITALIA"
          ? (this.isItalia = true)
          : (this.isItalia = false);
      if (this.nazione === "ITALIA") {
        this.getCap(false);
        this.international_cap = "";
      }
      this.resetPrincipalData(false);
    },
    clientType: function () {
      this.userCheck = false;
      this.resetPrincipalData();
    },
    cap: function (newValue) {
      this.loadCities();
    },
    citta: function (newValue) {
      const filtered = this.cities.filter((el) => {
        return el.citta === this.citta;
      });
      if (filtered.length > 0) {
        this.provincia = filtered[0].provincia;
      }
    },
    cap_sped: function (newValue) {
      this.loadCitiesSped();
    },
    citta_sped: function (newValue) {
      const filtered = this.cities_sped.filter((el) => {
        return el.citta === this.citta_sped;
      });
      if (filtered.length > 0) {
        this.provincia_sped = filtered[0].provincia;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .filter-list__input.input-radio{display: flex; align-items: center;}
</style>
