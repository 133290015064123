<template>
  <div class="container container-xl">
    <!--    {{order}}-->
    <div
        class="col-12 col-lg-12"
        :style="[
          fromOrder ? {'padding': '0px'} : {'padding': '15px'},
        ]"
    >
      <div class="card mb-4">
        <div class="card-body card-body--padding--2">

          <h3 class="card-title">{{ $trans('checkout.sede-ritiro') }}<sup>*</sup></h3>
          <div
              class="alert alert-warning font-weight-bold"
              v-if="suauto.length > 0"
          >
            <p class="m-0">
              {{ $trans('checkout.warning-smontaggio') }}:
            </p>
            <ul class="m-0">
              <li v-for="item in suauto" :key="item.id">
                {{ item.dsMar }} {{ item.dsMod }} {{ item.dsVer }}
                {{ item.descr }}
              </li>
            </ul>
          </div>
          <div class="alert alert-info">
            {{ $trans('checkout.preselect-sede') }}
          </div>
          <div
              class="payment-methods__item-details text-muted"
              style="padding-top: 8px"
          >
            <div class="form-row">
              <div class="form-check col-md-6" v-for="item in sedi">
                <span class="input-check form-check-input">
                  <span class="input-check__body">
                    <input
                        class="input-check__input"
                        :id="item.nome_sede.toLowerCase()"
                        v-model="sedeRitiro"
                        name="ritiro"
                        type="radio"
                        :value="item.id_sede"
                    />
                    <span class="input-check__box"></span>
                    <span class="input-check__icon">
                      <svg height="7px" width="9px" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"></path>
                      </svg>
                    </span>
                  </span>
                </span>
                <label :for="item.nome_sede.toLowerCase()">
                  {{ item.nome_sede }}
                  <small>({{ item.indirizzo_abbreviato.split(', ')[1] }})</small>
                  <i @click="setDetails(item.id_sede)" data-toggle="modal" data-target="#mapModal" class="fas fa-info-circle"></i>
                </label>
              </div>

              <div v-if="showAlertMontaggio" class="v-alert alert--info">
                {{$trans('checkout.alert-montaggio1')}}
                <strong>
                  <a href="tel:0309985330" draggable="false">030-9985330</a>
                </strong>
              </div>
              <div v-else-if="showAlertMontaggioAniaGomme" class="v-alert alert--info">
                {{$trans('checkout.alert-montaggio2')}}
                <strong>
                  <a href="tel:0309985330" draggable="false">030-9985330</a>
                </strong>
              </div>

              <p class="m-0 alert alert-danger font-weight-bold" style="width:100%">
                Ricordiamo che le sedi di Brescia, Capriolo, Mantova, Busto Arsizio, Inzago, Lombardore, Voghera saranno chiuse dal 5 al 18 agosto.
              </p>
            </div>
          </div>
        </div>

<!--        <p class="m-0 alert alert-danger font-weight-bold" v-if="sedeRitiro !== 'K'">-->
<!--          <i class="fas fa-exclamation"></i>-->
<!--          {{ $trans('js-files.sede-chiusa') }}-->
<!--        </p>-->
<!--        <p class="m-0 alert alert-danger font-weight-bold" v-else-if="sedeRitiro == 'K' && ritiroMag">-->
<!--          <i class="fas fa-exclamation"></i>-->
<!--          {{ $trans('js-files.ricambio-sede-chiusa') }}-->
<!--        </p>-->
        <p class="m-0 alert alert-warning font-weight-bold" v-if="ritiroMag">
          <i class="fas fa-exclamation"></i>
          {{ ritiroMsg }}
        </p>

        <Voucher class="mt-2 mr-2" :cart="cart" @voucherApplied="voucherApplied" />
      </div>
    </div>

    <!--SOSTITUZIONE COMPONENTE-->


    <div class="card mb-4" v-if="sedeRitiroHasProduct">
      <div class="card-body card-body--padding--2">
        <h3 class="card-title">{{ $trans('checkout.sostituzione-componente') }}</h3>
        <div class="card">
          <p class="m-0 alert alert-info font-weight-bold">
            {{ $trans('checkout.prodotti-sede') }}
          </p>

          <br/>


        </div>
        <div v-if="!fromOrder" class="row no-gutters">
          <div class="products-view">
            <div class="products-view__list products-list products-list--grid--4" data-layout="grid"
                 data-with-features="false">
              <div class="products-list__content">
                <div class="products-list__item col-lg-4 col-md-12 col-sm-12 col-12 m-0 mt-5"
                     v-for="item in itemsCanChange" :key="item.idcartellino">
                  <div class="product-card" :class="{'compatibile': item.is_compatibile}">
                    <div class="product-tags">
                      <div class="lato-tag sinistro" v-if="item.component.lato === 'S'">
                                      <span class="info-icon">
                                          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                               xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                               viewBox="0 0 493 493" style="enable-background:new 0 0 493 493;"
                                               xml:space="preserve">
                                                <path d="M427.6,34H260.1c-27.5,0-50,22.8-50,50.3v44.5c0,6.9,5.6,12.5,12.5,12.5s12.5-5.6,12.5-12.5V84.4
                                                    c0-13.9,11.2-25.2,25.1-25.4h167.5c13.8,0,24.6,11.6,24.6,25.4v322.8c0.2,13.7-10.8,24.9-24.5,25.1H260.1
                                                    c-13.8-0.1-25-11.2-25.1-25.1v-43.6c0-6.9-5.6-12.5-12.5-12.5s-12.5,5.6-12.5,12.5v43.6c0.1,27.6,22.4,50,50,50h167.5
                                                    c27.5-0.1,49.7-22.5,49.6-50V84.4C477.2,56.8,455.1,34,427.6,34z"/>
                                                <path d="M57.8,258.7h295.4c6.9,0,12.5-5.6,12.5-12.5c0-6.9-5.6-12.5-12.5-12.5H55.1l64-74c4.5-5.3,4-13.2-1.2-17.7
                                                    c-5.2-4.5-13.1-4-17.6,1.2v0l-80.7,93c-3,2.4-4.6,6.1-4.5,10c0.1,3.9,2,7.4,5.1,9.7l80.7,80.7c4.9,4.9,12.8,4.9,17.6,0
                                                    c4.8-4.7,4.9-12.4,0.2-17.3c-0.1-0.1-0.1-0.1-0.2-0.2L57.8,258.7z"/>
                          </svg></span>
                        {{ $trans('cart.lato') }}: {{ $trans('common.sx') }}
                      </div>
                      <div class="lato-tag destro" v-if="item.component.lato === 'D'">
                <span class="info-icon">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 493 493" style="enable-background:new 0 0 493 493;" xml:space="preserve">
    <path d="M64.6,457.2h167.5c27.5,0,50-22.8,50-50.3v-44.5c0-6.9-5.6-12.5-12.5-12.5c-6.9,0-12.5,5.6-12.5,12.5v44.5
        c0,13.9-11.2,25.2-25.1,25.4H64.6c-13.8,0-24.6-11.6-24.6-25.4V84.1C39.8,70.4,50.7,59.2,64.4,59h167.6c13.8,0.1,25,11.2,25.1,25.1
        v43.6c0,6.9,5.6,12.5,12.5,12.5c6.9,0,12.5-5.6,12.5-12.5V84.1c-0.1-27.6-22.4-50-50-50H64.6C37.1,34.1,14.9,56.5,15,84v322.8
        C15,434.4,37,457.2,64.6,457.2z"/>
    <path d="M434.3,232.5H138.9c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5h298.2l-64,74c-4.5,5.3-4,13.2,1.2,17.7
        c5.2,4.5,13.1,4,17.6-1.2v0l80.7-93c3-2.4,4.6-6.1,4.5-10c-0.1-3.9-2-7.4-5.1-9.7l-80.7-80.7c-4.9-4.9-12.8-4.9-17.6,0
        c-4.8,4.7-4.9,12.4-0.2,17.3c0.1,0.1,0.1,0.1,0.2,0.2L434.3,232.5z"/>
    </svg></span>
                        {{ $trans('cart.lato') }}: {{ $trans('common.dx') }}
                      </div>
                      <!-- blocco da mostrare solo sui prodotti compatibili-->
                      <div class="compatibile-tag" v-if="item.is_compatibile">
                    <span class="info-icon" data-toggle="tooltip"
                          title="Questo prodotto non è perfettamente uguale a quello originale ma può essere installato in sostituzione">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                            <g>
                                <path d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M298.667,416
                                c0,5.896-4.771,10.667-10.667,10.667h-64c-5.896,0-10.667-4.771-10.667-10.667V256h-10.667c-5.896,0-10.667-4.771-10.667-10.667
                                v-42.667c0-5.896,4.771-10.667,10.667-10.667H288c5.896,0,10.667,4.771,10.667,10.667V416z M256,170.667
                                c-23.531,0-42.667-19.135-42.667-42.667S232.469,85.333,256,85.333s42.667,19.135,42.667,42.667S279.531,170.667,256,170.667z"/>
                            </g>
                          </g>
                          <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                        </svg>
                    </span>
                        {{ $trans('cart.compatibile') }}
                      </div>
                    </div>
                    <!-- fine blocco da mostrare solo sui prodotti compatibili-->

                    <div class="product-card__actions-list">
                      <button class="product-card__action product-card__action--quickview" type="button"
                              aria-label="Quick view" @click="'openPhotoSwipe(\'' + item.foto.join(',') + '\')'"
                              v-if="item.foto.length > 0">
                        <!-- v-on:click="openGallery(item.foto.path)" -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                          <path d="M15,18c-2,0-3.8-0.6-5.2-1.7c-1,1.3-2.1,2.8-3.5,4.6c-2.2,2.8-3.4,1.9-3.4,1.9s-0.6-0.3-1.1-0.7
                        c-0.4-0.4-0.7-1-0.7-1s-0.9-1.2,1.9-3.3c1.8-1.4,3.3-2.5,4.6-3.5C6.6,12.8,6,11,6,9c0-5,4-9,9-9s9,4,9,9S20,18,15,18z M15,2
                        c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S18.9,2,15,2z M16,13h-2v-3h-3V8h3V5h2v3h3v2h-3V13z"></path>
                        </svg>
                      </button>
                    </div>
                    <div class="product-card__image">
                      <a @click="openGallery(item.foto)">
                        <picture v-if="item.foto.length > 0">
                          <source
                              :data-srcset="item.foto[0] + '_thumb.webp 1x, ' + item.foto[0] + '_thumb@2x.webp 2x'"
                              type="image/webp">
                          <img :data-src="item.foto[0] + '_thumb.jpg 1x, ' + item.foto[0] + '_thumb@2x.jpg 2x'"
                               class="lazyload" src="/images/placeholder-700x700-loading.png"
                               :alt="item.component.descr">
                        </picture>
                        <picture v-else>
                          <source data-srcset="/images/img-not-found.webp 1x, /images/img-not-found@2x.webp 2x"
                                  type="image/webp">
                          <img data-srcset="/images/img-not-found.jpg 1x, /images/img-not-found@2x.jpg"
                               class="lazyload"
                               src="/images/placeholder-700x700-loading.png" :alt="item.component.descr">
                        </picture>
                      </a>
                    </div>
                    <div class="product-card__info">
                      <div class="product-card__name comp-description mb-0">
                        <strong>{{ $trans('checkout.descrizione') }}: </strong>{{ item.component.descr }} <span
                          v-if="item.component.lato">{{ $trans('cart.lato') }} {{
                          item.component.lato == 'S' ? $trans('cart.sinistro') : $trans('cart.destro')
                        }}</span>
                      </div>
                      <div class="product-card__name comp-description {% if tag.km %}mb-0{% endif %}">
                        <strong>{{ $trans('checkout.veicolo') }}: </strong> {{ items_list[0].dsMar }}
                        {{ items_list[0].dsMod }}
                        {{ items_list[0].dsVer }}
                      </div>
                      <div class="product-card__name comp-description" v-if="item.km">
                        <strong>{{ $trans('checkout.kilometri') }}: </strong> {{ item.km }}
                      </div>
                      <div class="product-card__name comp-description" v-if="item.codicebase">
                        <strong>{{ $trans('checkout.cod-motore') }}: </strong> {{ item.codicebase }}
                      </div>
                      <div class="product-card__name comp-description" v-if="item.note">
                        <strong>{{ $trans('checkout.note') }}: </strong> {{ item.note }}
                      </div>
                      <div class="product-card__name comp-description" v-for="attr in item.attributes"
                           :key="attr.nome_attributo">
                        <strong>{{ attr.nome_attributo }}: </strong>
                        <!-- {% if attr.valore_attributo is iterable %}
                            {% for valore in attr.valore_attributo %}
                                <div class="tag-badge tag-badge--gray">
                                    {{ valore }}
                                </div>
                            {% endfor %}
        {#                        {{ attr.valore_attributo | join(', ') }}#}
                        {% else %}
                            {{ attr.valore_attributo }}
                        {% endif %} -->
                      </div>
                      <div class="alert alert-warning" v-if="item.stato !== 'B'"><i
                          class="fas fa-exclamation-triangle"></i> {{ $trans('checkout.difetti') }}
                      </div>
                    </div>
                    <div class="alert alert-danger"
                         v-if="[-734,-733, -189, -187, -186].indexOf(item.component.ania) >= 0">
                      <b><i class="fas fa-exclamation-triangle"></i> {{ $trans('checkout.alert-pneumatico') }}</b>
                    </div>
                    <div class="alert alert-danger"
                         v-if="[-645, -557, -193, -192, -125].indexOf(item.component.ania) >= 0">
                      <b><i class="fas fa-exclamation-triangle"></i> {{ $trans('checkout.alert-cerchio') }}</b>
                    </div>
                    <div class="product-card__footer">
                      <div class="product-card__prices">
                        <div class="product-card__price product-card__price--current">
                          <!-- {% if discount > 0 %}
                              <span class="whole-price">{{ tag.prezzocurr | localizedcurrency('EUR') }}</span>
                          {% endif %}
                          {{ tag.prezzocurr | discount(discount) | localizedcurrency('EUR') }}
                          {% if is_business() %}<span class="small pl-1 price-vat-excl">({{ tag.prezzocurr | discount(discount) | removeVat | localizedcurrency('EUR') }} + IVA)</span>{% endif %}
                          <span class="small pl-1">+ eventuali spese di spedizione</span> -->
                        </div>
                      </div>
                    </div>
                    <div class="product-card__button">
                      <a
                          href="#"
                          type="button"
                          class="btn btn-primary btn-lg text-white btn-block"
                          data-toggle="modal"
                          data-target="#exampleModal"
                          @click="itemtoreplace(item)"
                      >{{ $trans('checkout.cambia') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--METODO PAGAMENTO-->

    <div class="card mb-4">
      <div class="card-body card-body--padding--2">
        <div class="checkout-sede-payments">
          <h3 class="card-title">{{ $trans('checkout.metodo-pagamento') }}<sup>*</sup></h3>
          <div class="d-flex" style="gap: 8px">
            <a type="button" class="btn btn-primary text-white "
               @click="pagaMag = true">{{ $trans('checkout.paga-sede') }}</a>
            <a
                v-if="fromOrder"
                v-show="sedeRitiro !== 'A'"
                class="btn btn-primary text-white request-confirmation"
                :data-text="$trans('common.necessario-login')"
                :data-confirmText="$trans('common.si')"
                :data-cancelText="$trans('common.no')"
                :data-url="'/' + this.locale + '/auth/accedi'"
                style="cursor: pointer"
            >{{ $trans('checkout.paga-subito') }}</a>
            <a
                v-else-if="sedeRitiro !== 'A'"
                type="button"
                class="btn btn-primary text-white"
                id="payNow"
                @click="payNow()"
            >{{ $trans('checkout.paga-subito') }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-4" v-if="pagaMag">
      <div class="card-body card-body--padding--2">
        <div class="checkout-sede-contact">
          <h3 v-if="!auth && !hasContactOrClient" class="card-title">{{ $trans('checkout.recapiti') }}
            <sup v-if="ritiroMag || this.suauto.length > 0">*</sup>
          </h3>
          <h3 v-else-if="auth || hasContactOrClient" class="card-title">
            {{ $trans('checkout.conferma-ordine') }}
          </h3>
          <div v-if="!hasContactOrClient" class="card checkout-alert" style="margin: 0">
              <span v-if="auth">
              {{ $trans('checkout.annullamento') }}.
              </span>
            <span v-if="!auth">
                {{ $trans('checkout.compila') }}
                <br>
                {{ $trans('checkout.annullato-automatico') }}
              </span>
          </div>
          <br v-if="!hasContactOrClient"/>
          <!--                        <p v-if="auth" class="alert alert-info m-0">-->
          <!--                            Riceverai messaggio automatico quando-->
          <!--                            <span v-if="suauto && items_list.length > 1">-->
          <!--                               il prodotto sarà disponibile-->
          <!--                           </span>-->
          <!--                            <span v-if="suauto() && items_list.length == 1">-->
          <!--                                i prodotti saranno disponibili-->
          <!--                            </span>-->
          <!--                            presso la sede di ritiro.-->
          <!--                        </p>-->
          <!--                        <p v-if="!auth && ritiroMag" class="alert alert-info m-0"-->

          <!--                        >Inserendo i tuoi recapiti riceverai un messaggio automatico quando-->
          <!--                            <span v-if="items_list.length > 1">-->
          <!--                               il prodotto sarà disponibile-->
          <!--                           </span>-->
          <!--                            <span v-if="items_list.length == 1">-->
          <!--                                i prodotti saranno disponibili-->
          <!--                            </span>-->
          <!--                            presso la sede di ritiro.</p-->
          <!--                        >-->
          <!--                        <br/>-->
          <div v-if="!auth && !hasContactOrClient" class="d-flex flex-wrap">
            <div class="col-lg-12  checkout-sede-input">
              <div class="form-group">
                <label for="nome">{{ $trans('form-input.nome-cognome') }}
                  <small>*</small>
                </label>
                <input
                    required
                    :class="{'is-invalid': formAlert && $v.nome.$error}"
                    type="text"
                    class="form-control"
                    name="nome"
                    v-model="nome">
                <div v-if="$v.nome.$error">
                  <div class="vue-field-error" v-if="formAlert && !$v.nome.required">
                    {{ $trans('form-input.inserisci-nome') }}
                  </div>
                </div>
                <div v-if="$v.nome.$error">
                  <div class="vue-field-error" v-if="formAlert && !$v.nome.minLength">
                    {{ $trans('form-input.nome-valido') }}
                  </div>
                </div>
              </div>

              <div class="form-group ">
                <label for="checkout-tel-newadd">{{ $trans('form-input.telefono') }}
                  <small>*</small>
                </label>
                <input
                    required
                    :class="{'is-invalid': formAlert && $v.telefono.$error}"
                    class="form-control"
                    id="checkout-tel-newadd"
                    name="telefono"
                    type="tel"
                    v-model="telefono"
                />
                <div v-if="$v.telefono.$error">
                  <div class="vue-field-error" v-if="formAlert && !$v.telefono.numeric">
                    {{ $trans('form-input.telefono-solo-numeri') }}
                  </div>
                  <div class="vue-field-error"
                       v-if="formAlert && !$v.telefono.required">
                    {{ $trans('form-input.inserisci-numero') }}
                  </div>
                </div>
              </div>
              <div class="form-group ">
                <label for="checkout-email">
                  Email
                  <small><i>(Facoltativo)</i></small>
                </label>
                <input
                    :class="{'is-invalid': $v.email.$error}"
                    class="form-control"
                    id="checkout-email"
                    name="email"
                    type="email"
                    v-model="email">
                <div v-if="$v.email.$error">
                  <div class="vue-field-error" v-if="!$v.email.email">{{ $trans('form-input.email-invalid') }}</div>
                </div>
              </div>
            </div>


            <!--              <div class="col-12 p-0">-->
            <!--                <p-->
            <!--                    v-if="this.formAlert  && this.email.length == 0 && this.telefono.length == 0"-->
            <!--                    class="m-0 mb-3 font-weight-bold alert alert-danger"-->
            <!--                    style="margin: 0 0 5px 0"-->
            <!--                >-->
            <!--                  <i class="fas fa-exclamation"></i>-->
            <!--                  Per confermare l'ordine inserisci almeno un recapito.-->
            <!--                </p>-->
            <!--              </div>-->
          </div>
          <div class="alert alert-danger" v-if="errors">
            <div class="mb-0" v-for="error in errors">
              <p
                  class="mb-2"
                  v-for="e in error"
                  v-if="Array.isArray(error)"
              >
                {{ e }}
              </p>
              <p class="mb-2" v-if="!Array.isArray(error)">{{ error }}</p>
            </div>
          </div>
          <a type="button" class="btn btn-primary text-white"
             @click="closeCart()">{{ $trans('checkout.conferma-ordine') }}</a>
        </div>
      </div>
    </div>

    <!-- Modal -->

    <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button
                type="button"
                class="close checkout-sede-modal"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="checkout-sede-modal-content">
              {{ $trans('checkout.stai-per') }}
              <strong v-if="this.items_list">
                {{ this.items_list[0].descr }}
              </strong>
              {{ $trans('checkout.con') }}

              <strong v-if="this.itemToReplace">
                {{ this.itemToReplace.component.descr }}
              </strong>

              {{ $trans('checkout.continuare') }}
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
            >
              {{ $trans('checkout.annulla') }}
            </button>
            <button
                type="button"
                @click="substituteItem()"
                class="btn btn-primary"
                data-dismiss="modal"
            >
              {{ $trans('checkout.cambia-prodotto') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
        class="modal fade"
        id="login-necessario"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        v-if="modalLoginNecessario"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button
                type="button"
                class="close checkout-sede-modal"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="checkout-sede-modal-content">
              {{ $trans('checkout.stai-per') }}
              <strong v-if="this.items_list">
                {{ this.items_list[0].descr }}
              </strong>
              {{ $trans('checkout.con') }}

              <strong v-if="this.itemToReplace">
                {{ this.itemToReplace.component.descr }}
              </strong>

              {{ $trans('checkout.continuare') }}
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
            >
              {{ $trans('checkout.annulla') }}
            </button>
            <button
                type="button"
                @click="substituteItem()"
                class="btn btn-primary"
                data-dismiss="modal"
            >
              {{ $trans('checkout.cambia-prodotto') }}
            </button>
          </div>
        </div>
      </div>
      <div class="modal fade" id="mapModal" tabindex="-1" role="dialog" aria-labelledby="mapModalTitle" aria-hidden="true" v-if="infoSede">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">{{infoSede.sedeTitle}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{infoSede.sedeAddress}}
              <iframe :src="infoSede.realMap" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <p class="lower-btn"><a target="_blank" :href="infoSede.roadTo"><span>▶</span> {{ $trans("store-finder.naviga") }}</a></p>
            </div>
            <div class="modal-footer">
              <button @click="infoSede = null" type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import axios from "axios";
import {validationMixin} from "vuelidate";
import {hideFullScreenLoader, showFullScreenLoader,} from "../full-screen-loader-functions";
import {email, numeric, required, minLength} from "vuelidate/lib/validators";
import {CustomSwal, getSediRitiro} from "../utils";
import {onCompleteOrder} from "../tag-manager/gtm";
import trans from "../i18n";
import Voucher from "./Voucher.vue";

export default {
  name: "checkout-sede",
  components: { Voucher },
  mixins: [validationMixin],
  props: {
    checkoutSede: Boolean,
    items_list: Array,
    order: Object,
    client: Object,
    order_confirmed_path: String,
    fromOrder: Boolean,
    infoSedi: Object,
    cart: Object,
  },
  data() {
    return {
      hasContactOrClient: false,
      modalLoginNecessario: false,
      magazzini: [],
      maxMag: "",
      iMag: "",
      oneItem: false,
      sedeRitiro: null,
      ritiroMag: false,
      itemsCanChange: [],
      showModal: false,
      itemToReplace: null,
      show: false,
      changedMag: "",
      formAlert: false,
      telefono: "",
      email: "",
      nome: "",
      pagaMag: false,
      errors: null,
      auth: false,
      sedeConsigliata: "",
      inCoppia: [],
      isInCoppia: false,
      anias: [],
      locale: '',
      ritiroMsg: '',
      infoSede: null,
      sedi: [],
      valoreVoucher: null,
    };
  },
  validations: {
    email: {
      email,
    },
    nome: {
      required,
      minLength: minLength(5),
    },
    telefono: {
      numeric,
      required,
    },
  },
  computed: {
    suauto: function () {
      return this.items_list.filter((el) => el.tag && el.tag.suauto === 1);
    },
    showAlertMontaggio: function () {
      let selected = Array.from(document.querySelectorAll('input[name^="order_check"]:checked'));
      if(selected.length){
        selected = selected.map(r => JSON.parse(r.getAttribute('data-data')));
      }
      const arr = selected.length ? selected : this.items_list;
      console.log('asd', selected, this.items_list)
      return this.sedeRitiro === "G" && arr.find(r => {
        return r.isGomma === true;
      });
    },
    showAlertMontaggioAniaGomme: function () {
      let selected = Array.from(document.querySelectorAll('input[name^="order_check"]:checked'));
      if(selected.length){
        selected = selected.map(r => JSON.parse(r.getAttribute('data-data')));
      }
      const arr = selected.length ? selected : this.items_list;
      console.log('asd', selected, this.items_list)
      return this.sedeRitiro !== "G" && arr.find(r => {
        return r.isGomma === true;
      });
    },
    sedeRitiroHasProduct() {
      // console.log(this.itemsCanChange.length > 0);
      return this.itemsCanChange.length > 0;
    },
  },
  watch: {
    sedeRitiro: function (value) {
      if (new Date().getHours() >= 17) {
        this.ritiroMsg =
            trans("js-files.prod-non-disponibili");
      } else {
        this.ritiroMsg = value === "A"
            ? trans("js-files.prod-disponibili-autore")
            : trans("js-files.prod-disponibili-domani")
        ;
      }
      this.ritiroMsg += value !== "A"
          ? trans("js-files.data-accurata")
          : ""
      ;


      if (
          this.items_list.length == 1 &&
          this.items_list[0].magazzino !== value &&
          !this.fromOrder
      ) {
        this.ritiroMag = true;
        showFullScreenLoader();
        axios
            .get("/api/v1/cart/oneitem-change", {
              params: {
                item: this.items_list[0]["idmag"],
                mag: this.sedeRitiro,
              },
            })
            .then((res) => {
              this.itemsCanChange = res.data.data;
              // console.log("itemsCanchange", this.itemsCanChange);
              // console.log("img", this.itemsCanChange[1].images[0].path);
            })
            .finally(() => hideFullScreenLoader());
      }
      if (this.sedeConsigliata != value) {
        this.ritiroMag = true;
      } else {
        // this.sedeRitiroHasProduct = false;
        this.formAlert = false;
        this.ritiroMag = false;
        this.itemsCanChange = [];
      }
    },
  },
  mounted() {
    // this.locale = location.href.split("/");
    axios.get('/api/v1/sedi').then(res => {
      this.sedi = getSediRitiro(res.data.data[0]);
      console.log(this.sedi);
    });

    this.locale = localStorage.getItem('locale');

    this.items_list.forEach((el) => {
      // console.log('el', el);
      if (el.in_coppia) {
        this.anias.push(el.ania);
      }
    })
    this.checkInCoppia();
    if (this.client) {
      this.auth = true;
      console.log("auth", this.auth);
    }

    console.log(this.order);
    if (this.fromOrder && (this.order.clienteid != 0 || this.order.id_contatto != 0)) {
      this.hasContactOrClient = true;
    }
    console.log(this.hasContactOrClient);

    //sedi dei cartellini
    this.magazzini = this.items_list.map((el) => el.magazzino);

    // calcolo il magazzino che contiene più cartellini
    const counts = {};
    let maxCount = 0;
    let maxKey;


    this.magazzini.forEach((el) => {
      const key = el;
      const count = (counts[key] = (counts[key] || 0) + 1);
      if (count > maxCount) {
        maxCount = count;
        maxKey = key;
      }
    });
    this.sedeRitiro = maxKey;
    this.sedeConsigliata = maxKey;
  },
  methods: {
    checkInCoppia() {
      //Conto le occorrenze di ogni ania che deve essere in coppia
      var counts = {};
      for (let num of this.anias) {
        counts[num] = counts[num] ? counts[num] + 1 : 1;
      }

      this.anias.forEach((el) => {
        //Se un ania ha una sola occorrenza lo pusho nell'array di elementi
        // che devono essere acquistati in coppia e verrà fatto visualizzare all'utente
        if (counts[el] % 2 != 0) {
          this.isInCoppia = true;
          CustomSwal({
            title: "Attenzione",
            html: 'Il tuo carrello contiene dei pezzi che devono essere venduti in coppia',
            confirmButtonText: "Vai alla Homepage",
            icon: "error",
            allowOutsideClick: false,
          }).then(function (result) {
            window.location = '/';
          })
        }
      });
    },
    payNow() {
      $cookies.set("Ritiro", this.sedeRitiro, "3h", "/checkout");
      window.location.href = "/" + this.locale + "/checkout";
    },
    setDetails(sede) {
      console.log('dese', sede)
      this.infoSede = this.infoSedi[sede];
      // switch(sede) {
      //   case 'PB':
      //     this.sedeTitle = 'Pollini Bedizzole (BS)';
      //     this.sedeAddress = 'Via Gavardina n° 30, 25081 Bedizzole (BS)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11188.181918972035!2d10.3919027!3d45.4890289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47819a473bd1513d%3A0x7c2c581e69e509c!2sAutodemolizione%20Pollini%20Bedizzole!5e0!3m2!1sit!2sit!4v1683635932332!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.4890289%2C10.3919027';
      //     break;
      //   case 'PC':
      //     this.sedeTitle = 'Pollini Capriolo (BS)';
      //     this.sedeAddress = 'Viale Lombarda n° 92, 25031 Capriolo (BS)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11161.892815197965!2d9.9204147!3d45.6212294!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478167dee6bc8589%3A0xf4f04696c946c3d3!2sAutodemolizione%20Pollini%20Capriolo!5e0!3m2!1sit!2sit!4v1683637352980!5m2!1sit!2sit'
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.6212294%2C9.9204147';
      //     break;
      //   case 'PBS':
      //     this.sedeTitle = 'Pollini Brescia';
      //     this.sedeAddress = 'Via Del Mella n° 44/H/G, 25131 Brescia (BS)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11185.144009050971!2d10.160003!3d45.504321!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47817443ea760b97%3A0xdfd912db44685b29!2sAutodemolizione%20Pollini%20Brescia%20Via%20del%20Mella!5e0!3m2!1sit!2sit!4v1683637896575!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.504321%2C10.160003';
      //     break;
      //   case 'PBC':
      //     this.sedeTitle = 'Pollini Brescia Centro';
      //     this.sedeAddress = 'Via Borgosatollo n° 38, 25124 Brescia (BS)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11184.149032161356!2d10.2326134!3d45.5093286!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478175d0e470a845%3A0x2d4a9f97321be021!2sAutodemolizione%20Pollini%20Brescia%20Centro!5e0!3m2!1sit!2sit!4v1683638188591!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.5093286%2C10.2326134';
      //     break;
      //   case 'PM':
      //     this.sedeTitle = 'Pollini Mantova';
      //     this.sedeAddress = 'Via Primo Maggio n° 14, San Martino Dall\'Argine (MN)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11264.158315721399!2d10.5249687!3d45.1052657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478034dbaae6ac57%3A0x760412a69bd90c93!2sAutodemolizione%20Pollini%20San%20Martino%20d%2FA!5e0!3m2!1sit!2sit!4v1683638247623!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.1052657%2C10.5249687';
      //     break;
      //   case 'PV':
      //     this.sedeTitle = 'Pollini Busto Arsizio (VA)';
      //     this.sedeAddress = 'Strada Comunale di Arconate n° 31, 21052 Busto Arsizio (VA)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11171.059814287724!2d8.8361193!3d45.5751651!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47868b5b38cddef3%3A0xc2ad0e24684c6f84!2sAutodemolizione%20Pollini%20Busto!5e0!3m2!1sit!2sit!4v1683638292158!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.5751651%2C8.8361193';
      //     break;
      //   case 'PT':
      //     this.sedeTitle = 'Autodemolizione Autore, Settimo Torinese (TO)';
      //     this.sedeAddress = 'Via Sicilia, 2, 10036 Settimo Torinese (TO)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11253.79936464416!2d7.7674056!3d45.1577399!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4788712da8082889%3A0x46f63a8ee6b62f77!2sAutodemolizione%20Autore!5e0!3m2!1sit!2sit!4v1683638321292!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.1577399%2C7.7674056';
      //     break;
      // }
    },
    closeCart() {
      if (this.order) {
        this.telefono = !this.telefono
            ? (this.order.telefono || this.order.cellulare)
            : this.telefono;
        this.email = !this.email
            ? this.order.email
            : this.email;
      }

      showFullScreenLoader();
      this.pagaMag = true;
      this.formAlert = true;
      this.$v.$touch();
      if (this.$v.$invalid && !this.auth && !this.hasContactOrClient) {
        hideFullScreenLoader();
        return;
      }
      if (this.fromOrder) {
        const selected = JSON.parse(localStorage.order_checks);
        if (!selected || selected.length === 0) {
          CustomSwal({
            title: "Errore",
            html: trans('checkout.nessun-componente'),
            confirmButtonText: "Ok",
            icon: "error",
          });
          return;
        }
        axios
            .post("/api/v1/order/" + this.order.id + "/confirm", {
              for_confirmation: selected,
              clienteid: this.client ? this.client.id : 0,
              ritiro: this.sedeRitiro,
              metodo_evasione: "NOWAIT",
              checkout_sede: true,
              tipo_pagamento: 3,
              speseSpedizione: 0,
              speseImballaggio: 0,
              email: this.email,
              cellulare: this.telefono,
              nome: this.nome,
              // speseSpedizione: this.shipping_net,
              // speseImballaggio: this.packaging,
              // indirizzo_spedizione: this.indirizzo_spedizione,
              // documento: this.documento,
              // cod_fiscale: this.cod_fiscale,
              // pec: this.pec,
              // identificativo_fatt_elett: this.identificativo_fatt_elett,
              // note: this.note
            })
            .then((res) => {
              location.reload();
            })
            .catch((error) => {
              if (error.response.data.code === 404) {
                // this.buttonForceDisable = true;
                CustomSwal({
                  title: "Errore",
                  html: error.response.data.errors.join("<br><br>"),
                  confirmButtonText: "Ok",
                  icon: "error",
                });
              } else {
                this.errors = error.response.data.errors;
              }
            })
            .finally(() => {
              this.formAlert = false;
              hideFullScreenLoader();
            });
      } else {
        axios
            .post("/api/v1/cart/close", {
              clienteid: this.client ? this.client.id : 0,
              ritiro: this.sedeRitiro,
              metodo_evasione: "NOWAIT",
              tipo_pagamento: 3,
              speseSpedizione: 0,
              speseImballaggio: 0,
              fromCheckoutSede: true,
              email: this.email,
              cellulare: this.telefono,
              nome: this.nome,
              documento: 4,
            })
            .then((res) => {
              onCompleteOrder(res.data.data, this.order_confirmed_path);
            })
            .catch((error) => {
              if (error.response.data.code === 404) {
                CustomSwal({
                  title: "Errore",
                  html: error.response.data.errors.join("<br><br>"),
                  confirmButtonText: "Ok",
                  icon: "error",
                }).then(() => {
                  location.reload();
                });
              } else {
                this.errors = error.response.data.errors;
                console.log(this.errors);
              }
            })
            .finally(() => {
              this.formAlert = false;
              hideFullScreenLoader();
            });
      }
    },

    substituteItem() {
      showFullScreenLoader();
      console.log("ok");
      axios
          .post("/api/v1/cart/sustitute-item", {
            params: {
              actualItem: this.items_list,
              itemToReplace: this.itemToReplace,
            },
          })
          .then(() => {
          })
          .finally(() => {
            window.location.reload();
          });
    },

    //Check al click di "cambia" per avere i dati del prodotto da cambiare
    itemtoreplace(item) {
      this.itemToReplace = item;
      console.log("replace", this.itemToReplace);
    },
    openGallery: function (item) {
      window.openPhotoSwipe(...item.path);
    },
    voucherApplied(voucher) {
      this.valoreVoucher = voucher * -1;
    }
  },
};
</script>
<style scoped>
</style>
