jQuery(document).ready(() => {
    const slideshows = document.querySelectorAll('[data-component="slideshow"]');

    slideshows.forEach(initSlideshow);

    function setActiveSlide(slides, slidePoints, index) {
        slides.forEach(s => s.classList.remove('active'));
        slidePoints.forEach(s => s.classList.remove('active'));

        slides[index].classList.add('active');
        slidePoints[index].classList.add('active');
    }

    function initSlideshow(slideshow) {
        const time = (slideshow.getAttribute('data-time') || 5) * 1000;
        const container = slideshow.querySelector('.slides-container');
        container.classList.add('loading');
        let index = 0;

        const slides = slideshow.querySelectorAll('.slide');
        const slidePoints = slideshow.querySelectorAll('.slide-point');

        slidePoints.forEach(slidePoint => {
            slidePoint.addEventListener('click', e => {
                index = parseInt(e.target.getAttribute('data-slide')) - 1;
                setActiveSlide(slides, slidePoints, index);
            });
        });

        const maxHeight = Array.from(slides).reduce((max, slide) => {
            return Math.max(max, slide.offsetHeight);
        }, 0);
        container.style.height = maxHeight + 'px';
        container.classList.remove('loading');

        slides[index].classList.add('active');
        slidePoints[index].classList.add('active');

        setInterval(() => {
            index = (index + 1) % slides.length;
            setActiveSlide(slides, slidePoints, index);
        }, time);
    }
});