<template>
  <form @submit="search" class="block-finder__form" novalidate>
    <div class="flex-grow-1">
      <div
        class="block-finder__form-control block-finder__form-control--select"
      >
        <select
          :id="'brand_select-' + tipoveicolo"
          aria-label="Seleziona Marca"
          class="form-control"
          name="brand_id"
          required
          v-model="selectedBrand"
          v-on:change="changeBrand(selectedBrand)"
        >
          <option :value="null" selected>
            {{ $trans("filter-model.sel-marca") }}
          </option>
          <option
            :value="brand.id"
            v-bind:key="brand.id"
            v-for="brand in brands"
          >
            {{ brand.descrEx }}
          </option>
        </select>
        <div
          class="text-danger text-left pl-1"
          v-if="showErrors && !selectedBrand"
        >
          {{ $trans("filter-model.compila-marca") }}
        </div>
      </div>
      <div
        class="block-finder__form-control block-finder__form-control--select"
      >
        <select
          :disabled="disableSelect"
          :id="'model_select-' + tipoveicolo"
          aria-label="Seleziona Modello"
          class="form-control"
          name="model_id"
          required
          v-model="selectedModel"
        >
          <option :value="null" selected>
            {{ $trans("filter-model.sel-modello") }}
          </option>
          <option
            :value="model.idmar + '#' + model.modello"
            v-bind:key="model.idmar + '#' + model.modello"
            v-for="model in models"
          >
            {{ model.descr }}
          </option>
        </select>
        <div
          class="text-danger text-left pl-1"
          v-if="showErrors && !selectedModel"
        >
          {{ $trans("filter-model.compila-modello") }}
        </div>
      </div>
      <div
        class="block-finder__form-control block-finder__form-control--select"
      >
        <select
          :disabled="disableSelect"
          :id="'version_select-' + tipoveicolo"
          aria-label="Vehicle Engine"
          class="form-control"
          name="version_id"
          required
          v-model="selectedVersion"
        >
          <option :value="null" selected>
            {{ $trans("filter-model.sel-versione") }}
          </option>
          <option
            :value="version.id"
            v-bind:key="version.id"
            v-for="version in versions"
          >
            {{ version.descrEx }}
          </option>
        </select>
        <div
          class="text-danger text-left pl-1"
          v-if="showErrors && !selectedVersion"
        >
          {{ $trans("filter-model.compila-versione") }}
        </div>
      </div>
      <div
        v-if="!searchByCodiceMotore"
        class="block-finder__form-control block-finder__form-control--select"
        style="height: 44px"
      >
        <select
          :id="'description_select-' + tipoveicolo"
          name="descrizione"
          aria-label="es: paraurti"
          v-model="selectedDescription"
          class="form-control"
          :disabled="!searchDescr"
        >
          <option selected :value="null">
            {{ $trans("filter-model.descrizione") }}
          </option>

          <option
            v-for="(description, i) in descriptions"
            :key="i"
            :value="description.value"
            v-bind:key="description.text"
          >

            {{ description.text }}
          </option>
        </select>
      </div>
    </div>
    <div class="button-container">
      <button
        class="
          btn btn-primary
          block-finder__form-control block-finder__form-control--button
        "
        type="submit"
      >
        {{ $trans("common.cerca") }}
      </button>
      <button
        class="
          btn btn-black
          block-finder__form-control block-finder__form-control--button
        "
        type="button"
        @click="reset"
      >
        {{ $trans("common.reset") }}
      </button>
    </div>
  </form>
</template>
<script>
import axios from "axios";
import toastr from "toastr";

export default {
  name: "filter-model",
  props: {
    routeDescription: String,
    route: String,
    tipoveicolo: Number,
    slug: "",
  },
  mounted: function () {
    const route = this.route;
    if (route.includes("ricambi-usati-codice-motore")) {
      this.searchByCodiceMotore = true;
      console.log("searchBy", this.searchByCodiceMotore);
    }

    $("#description_select-" + this.tipoveicolo).select2({
      minimumInputLength: 3,
      language: "it",
      // ajax: {
      //     url: "/api/v1/filter/description",
      //     data: function (term) {
      //         return {
      //             "q": term,
      //             "slug": self.slug,
      //             "slug_gruppo": self.slugGruppo,
      //         }
      //     },
      //     dataType: "json",
      //     processResults: function (data) {
      //         return {
      //             results: Object.values(data.data).map(el => {
      //                 return {
      //                     id: el,
      //                     text: el
      //                 };
      //             })
      //         };
      //     }
      // },
    });

    if (parseInt($cookies.get("filtro-tipoveicolo")) === this.tipoveicolo) {
      this.selectedBrandCookie = $cookies.get("filtro-marca");
      this.selectedModelCookie = $cookies.get("filtro-modello");
      this.selectedVersionCookie = $cookies.get("filtro-versione");
      this.selectedDescriptionCookie = $cookies.get("filtro-descrizione");
    }

    if (
      this.selectedBrandCookie &&
      this.selectedModelCookie &&
      this.selectedVersionCookie &&
      this.selectedDescriptionCookie
    ) {
      this.reloadingData = true;
      this.disableSelect = false;
    }

    var self = this;

    $("#description_select-" + this.tipoveicolo).on("change", (e) => {
      const data = $(e.target).val();
      self.selectedDescription = data;
      console.log("data", data);
    });

    $(document).on("keyup keydown", "input.select2-search__field", (e) => {
      const data = $(e.target).val();
      this.searchDescription(data);
    });
    $("#brand_select-" + this.tipoveicolo).on("change", function () {
      self.selectedBrand = this.value;
      self.$emit("changeBrand", this.value);
    });

    $("#model_select-" + this.tipoveicolo).on("change", function () {
      self.selectedModel = this.value;
      const brand = this.value.split("#")[0];
      if (brand !== self.selectedBrand) {
        self.selectedBrand = brand;
      }
      self.$emit("changeModel", this.value);
    });
    $("#version_select-" + this.tipoveicolo).on("change", function () {
      self.selectedVersion = this.value;
    });
    $("#version_select-" + this.tipoveicolo).on("change", (e) => {
      this.loadDescription();
    });
    self.$on("changeBrand", function (data) {
      self.loadModels(data);
    });
    self.$on("changeModel", function (data) {
      const modelId = data.split("#")[1];
      self.loadVersions(self.selectedBrand, modelId);
    });
  },
  data() {
    return {
      brands: [],
      models: [],
      versions: [],
      selectedBrand: null,
      selectedModel: null,
      selectedDescription: null,
      selectedVersion: null,
      selectedBrandCookie: null,
      selectedModelCookie: null,
      selectedVersionCookie: null,
      selectedDescriptionCookie: null,
      reloadingData: false,
      disableSelect: true,
      showErrors: false,
      descriptions: [],
      searchDescr: false,
      searchByCodiceMotore: false,
    };
  },
  created() {
    this.loadBrands();
    this.$set(this, "models", []);
  },
  methods: {
    loadDescription() {
      const brandSlug = this.brands.filter(
        (el) => el.id === this.selectedBrand
      )[0].slug;
      const modelId = this.selectedModel.split("#")[1];
      const modelSlug = this.models.filter(
        (el) => el.idmar === this.selectedBrand && el.modello === modelId
      )[0].slug;
      const versionSlug = this.versions.filter(
        (el) =>
          el.idMar === this.selectedBrand &&
          el.idMod === modelId &&
          el.id === this.selectedVersion
      )[0].slug;
      this.selectedDescription = this.preselectedDescription;
      axios
        .get("/api/v1/filter/description", {
          params: {
            slug: brandSlug + "-" + modelSlug + "-" + versionSlug,
            slug_gruppo: this.slugGruppo,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.descriptions = response.data.data;
            // console.log(
            //   "response",
            //   this.descriptions,
            //   this.descriptions,
            //   "data",
            //   response.data.data
            // );
          }
        })
        .finally(() => {
          this.searchDescr = true;
        });
    },

    searchDescription(data) {
      this.selectedDescription = data;
      let descr = { text: data, value: data };
      this.descriptions.push(descr);
      var index = this.descriptions.indexOf(descr);
      this.descriptions.splice(index - 1, 1);
      $("#select2-description_select-" + this.tipoveicolo + "-container").text(
        data
      );
    },

    loadBrands: function () {
      return axios
        .request({
          method: "GET",
          url: "/api/v1/filter/brand?type=" + this.tipoveicolo,
        })
        .then((response) => {
          this.$set(this, "brands", response.data.data[0]);
          if (this.reloadingData) {
            this.selectedBrand = this.selectedBrandCookie;
            this.$emit("changeBrand", this.selectedBrand);
          } else {
            this.$set(this, "selectedModel", null);
            this.$set(this, "selectedVersion", null);
          }
        })
        .catch((error) => {
          toastr.error(error.message, "Errore");
        });
    },
    loadModels: function (brandId) {
      this.$set(this, "models", []);
      this.$set(this, "versions", []);
      this.$set(this, "selectedModel", null);
      this.$set(this, "selectedVersion", null);
      if (brandId) {
        return axios
          .request({
            method: "GET",
            url:
              "/api/v1/filter/model/" + brandId + "?type=" + this.tipoveicolo,
          })
          .then((response) => {
            this.$set(this, "models", response.data.data);
            if (this.reloadingData) {
              this.selectedModel = this.selectedModelCookie;
              this.$emit("changeModel", this.selectedModel);
            } else {
              this.$set(this, "selectedVersion", null);
            }
          })
          .catch((error) => {
            toastr.error(error.message, "Errore");
          });
      }
    },
    loadVersions: function (brandId, modelId) {
      this.$set(this, "versions", []);
      this.$set(this, "selectedVersion", null);
      if (brandId && modelId) {
        return axios
          .request({
            method: "GET",
            url: "/api/v1/filter/version/" + brandId + "/" + modelId,
          })
          .then((response) => {
            this.$set(this, "versions", response.data.data[0]);
            if (this.reloadingData) {
              $("#version_select").removeAttr("disabled");
              this.selectedVersion = this.selectedVersionCookie;
              this.reloadingData = false;
            }
          })
          .catch((error) => {
            toastr.error(error.message, "Errore");
          });
      }
    },
    reset: function () {
      this.$set(this, "models", []);
      this.$set(this, "versions", []);
      this.$set(this, "brands", []);
      this.$set(this, "selectedVersion", null);
      this.$set(this, "selectedModel", null);
      this.$set(this, "selectedBrand", null);
      this.$set(this, "selectedDescription", null);
      $("#select2-description_select-" + this.tipoveicolo + "-container").text(
        "Seleziona Descrizione"
      );
      this.loadBrands();
    },
    updateCookie: function () {
      return (
        this.selectedBrand != this.selectedBrandCookie ||
        this.selectedModel != this.selectedModelCookie ||
        this.selectedVersion != this.selectedVersionCookie
      );
    },
    search: function (e) {
      e.preventDefault();
      this.showErrors = false;
      if (!this.isValid) {
        this.showErrors = true;
        return;
      }
      const brandSlug = this.brands.filter(
        (el) => el.id === this.selectedBrand
      )[0].slug;
      const modelId = this.selectedModel.split("#")[1];
      const modelSlug = this.models.filter(
        (el) => el.idmar === this.selectedBrand && el.modello === modelId
      )[0].slug;
      const versionSlug = this.versions.filter(
        (el) =>
          el.idMar === this.selectedBrand &&
          el.idMod === modelId &&
          el.id === this.selectedVersion
      )[0].slug;
      const descriptionSlug = this.selectedDescription;
      if (this.updateCookie()) {
        $cookies.set("filtro-marca", this.selectedBrand, "1h");
        $cookies.set("filtro-modello", this.selectedModel, "1h");
        $cookies.set("filtro-versione", this.selectedVersion, "1h");
        $cookies.set("filtro-tipoveicolo", this.tipoveicolo, "1h");
        $cookies.remove("filtro-codice-motore");
      }
      $cookies.remove("targa");
      $cookies.remove("anno-immatricolazione");

      if (descriptionSlug) {
        window.location.href = this.routeDescription.replace(
            "vue",
            brandSlug +
            "-" +
            modelSlug +
            "-" +
            versionSlug
        ) + "?descrizione=" + encodeURIComponent(descriptionSlug);
      } else {
        window.location.href = this.route.replace(
            "vue",
            brandSlug + "-" + modelSlug + "-" + versionSlug
        );
      }
    },
  },
  computed: {
    isValid: function () {
      return this.selectedBrand && this.selectedModel && this.selectedVersion;
    },
  },
};
</script>
<style scoped>
.button-container {
  display: flex;
  flex-direction: column;
}

.button-container .btn {
  flex: 1;
}

.button-container .btn-black {
  margin-left: 7px !important;
}

@media screen and (max-width: 320px) {
  .button-container {
    flex-direction: row;
    width: 100%;
  }
}
</style>
