<template>
  <form
    id="form-filter-complete"
    class="col-lg-12 col-md-12 block-finder__form"
    @submit="search"
  >
    <div class="col-lg-6 col-md-6 left-line-arrow">
      <div class="form-group form-inline">
        <label for="BrandSelect" class="label-auto-selezione"
          >{{ $trans("common.marca") }}:
        </label>
        <div
          class="block-finder__form-control block-finder__form-control--select"
        >
          <select
            id="brand_select"
            class="form-control"
            placeholder="Seleziona Marca"
            name="brand_id"
            aria-label="Seleziona Marca"
            v-on:change="changeBrand(selectedBrandId)"
            v-model="selectedBrandId"
            required
            enabled
          >
            <option :value="null" selected>
              {{ $trans("filter-model.sel-marca") }}
            </option>
            <option
              v-for="brand in brands"
              :value="brand.id"
              v-bind:key="brand.id"
              :selected="brand.id == selectedBrandId"
            >
              {{ brand.descrEx }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group form-inline">
        <label for="ModelSelect" class="label-auto-selezione"
          >{{ $trans("common.modello") }}:
        </label>
        <div
          class="block-finder__form-control block-finder__form-control--select"
        >
          <select
            id="model_select"
            class="form-control"
            placeholder="Seleziona Modello"
            name="model_id"
            aria-label="Seleziona Modello"
            v-model="selectedModelId"
            :disabled="models.length < 1"
            required
          >
            <option :value="null" selected>
              {{ $trans("filter-model.sel-modello") }}
            </option>
            <option
              v-for="model in models"
              :value="model.idmar + '#' + model.modello"
              :selected="model.idmar + '#' + model.modello == selectedModelId"
              v-bind:key="model.idmar + '#' + model.modello"
            >
              {{ model.descr }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 left-line-arrow last">
      <div class="form-group form-inline">
        <label for="VersionSelect" class="label-auto-selezione"
          >{{ $trans("common.versione") }}:
        </label>
        <div
          class="block-finder__form-control block-finder__form-control--select"
        >
          <select
            id="version_select"
            class="form-control"
            placeholder="Seleziona Versione"
            name="version_id"
            aria-label="Seleziona Versione"
            v-model="selectedVersionId"
            :disabled="versions.length < 1"
            required
          >
            <option :value="null" selected>
              {{ $trans("filter-model.sel-versione") }}
            </option>
            <option
              v-for="version in versions"
              :value="version.id"
              :selected="version.id == selectedVersionId"
              v-bind:key="version.id"
            >
              {{ version.descrEx }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group form-inline">
        <label for="search_code" class="label-auto-selezione"
          >{{ $trans("checkout.cod-motore") }}
        </label>
        <input
          name="search_code"
          id="search_code"
          type="text"
          class="form-control form-control-lg"
          :placeholder='$trans("form-input.inserisci-cod-motore")'
          v-model="selectedSearchCode"
        />
      </div>
    </div>
    <input hidden name="group_id" :value="selectedGroupId" />
    <button
      class="
        btn btn-black
        block-finder__form-control block-finder__form-control--button
      "
      type="submit"
      v-bind:disabled="!isValid"
    >
      {{ $trans("common.cerca") }}
    </button>
  </form>
</template>
<script>
import axios from "axios";
import toastr from "toastr";

export default {
  name: "filter-complete",
  props: {
    route: String,
    strclass: String,
    groupid: String,
    brandid: String,
    modelid: String,
    versionid: String,
    searchCode: String,
    tipoveicolo: Number,
  },
  mounted: function () {
    if (this.searchCode) {
      this.selectedSearchCode = this.searchCode;
    }

    if (this.groupid) {
      this.selectedGroupId = this.groupid;
    }
    if (this.strclass) {
      $("#form-filter-complete").addClass(this.strclass);
    }
    if (this.brandid) {
      this.selectedBrandId = this.brandid;
      this.loadModels(this.brandid);
    }
    if (this.modelid) {
      this.selectedModelId = this.brandid + "#" + this.modelid;
      this.loadVersions(this.selectedBrandId, this.modelid);
    }
    if (this.versionid) {
      this.selectedVersionId = this.versionid;
    }
    var self = this; //vue
    $("#brand_select").on("change", function () {
      self.selectedBrandId = this.value;
      self.$emit("changeBrand", this.value);
    });
    self.$on("changeBrand", function (data) {
      self.loadModels(data);
    });
    $("#model_select").on("change", function () {
      self.selectedModelId = this.value;
      const brand = this.value.split("#")[0];
      if (brand !== self.selectedBrandId) {
        self.selectedBrandId = brand;
      }
      self.$emit("changeModel", this.value);
    });
    self.$on("changeModel", function (data) {
      const modelId = data.split("#")[1];
      self.loadVersions(self.selectedBrandId, modelId);
    });
    $("#version_select").on("change", function () {
      self.selectedVersionId = this.value;
    });
  },
  data() {
    return {
      brands: [],
      models: [],
      versions: [],
      selectedGroupId: null,
      selectedBrandId: null,
      selectedModelId: null,
      selectedVersionId: null,
      selectedSearchCode: null,
    };
  },
  created() {
    this.loadBrands();
    this.$set(this, "models", []);
  },
  methods: {
    loadBrands: function () {
      return axios
        .request({
          method: "GET",
          url: "/api/v1/filter/brand?type=" + this.tipoveicolo,
        })
        .then((response) => {
          this.$set(this, "brands", response.data.data[0]);
        })
        .catch((error) => {
          toastr.error(error.message, "Errore");
        });
    },
    loadModels: function (brandId) {
      this.$set(this, "models", []);
      this.$set(this, "versions", []);
      this.$set(this, "selectedModelId", null);
      this.$set(this, "selectedVersionId", null);
      if (brandId) {
        return axios
          .request({
            method: "GET",
            url:
              "/api/v1/filter/model/" + brandId + "?type=" + this.tipoveicolo,
          })
          .then((response) => {
            this.$set(this, "models", response.data.data);
          })
          .catch((error) => {
            toastr.error(error.message, "Errore");
          });
      }
    },
    loadVersions: function (brandId, modelId) {
      this.$set(this, "versions", []);
      this.$set(this, "selectedVersionId", null);
      if (brandId && modelId) {
        return axios
          .request({
            method: "GET",
            url: "/api/v1/filter/version/" + brandId + "/" + modelId,
          })
          .then((response) => {
            this.$set(this, "versions", response.data.data[0]);
          })
          .catch((error) => {
            toastr.error(error.message, "Errore");
          });
      }
    },
    search: function (e) {
      e.preventDefault();
      const brandSlug = this.brands.filter(
        (el) => el.id === this.selectedBrandId
      )[0].slug;
      const modelId = this.selectedModelId.split("#")[1];
      const modelSlug = this.models.filter(
        (el) => el.idmar === this.selectedBrandId && el.modello === modelId
      )[0].slug;
      const versionSlug = this.versions.filter(
        (el) =>
          el.idMar === this.selectedBrandId &&
          el.idMod === modelId &&
          el.id === this.selectedVersionId
      )[0].slug;

      if (
        $cookies.get("filtro-marca") !== this.selectedBrandId ||
        $cookies.get("filtro-modello") !== this.selectedModelId ||
        $cookies.get("filtro-versione") !== this.selectedVersionId
      ) {
        $cookies.remove("targa");
        $cookies.remove("anno-immatricolazione");
      }

      $cookies.set("filtro-marca", this.selectedBrandId, "1h");
      $cookies.set("filtro-modello", this.selectedModelId, "1h");
      $cookies.set("filtro-versione", this.selectedVersionId, "1h");
      $cookies.set("filtro-codice-motore", this.selectedSearchCode, "1h");

      var formUrl = this.route.replace(
        "vue",
        brandSlug + "-" + modelSlug + "-" + versionSlug
      );
      if (this.selectedSearchCode) {
        formUrl =
          formUrl +
          (formUrl.includes("?") ? "&" : "?") +
          "search_code=" +
          this.selectedSearchCode;
      }

      window.location.href = formUrl;
    },
  },
  computed: {
    isValid: function () {
      return (
        this.selectedBrandId && this.selectedModelId && this.selectedVersionId
      );
    },
  },
};
</script>
<style scoped>
</style>
