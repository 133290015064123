import {
    pushDataLayer,
    getProductLayered,
    getProductLayer,
    getRemarketingLayer,
    getProductDetailLayer,
    getAddToCartLayer,
    getCheckoutLayer,
    getOrderLayer
} from './layers';

function callbackWithTimeout(callback, timeout) {
    let called = false;

    function fn() {
        if (!called) {
            called = true;
            callback();
        }
    }

    setTimeout(fn, timeout || 1000);

    return fn;
}

function applyCustomDimensions(product) {
    if (gtmSettings.gua.dimensions) {
        for (const key in gtmSettings.gua.dimensions) {
            if (gtmSettings.gua.dimensions.hasOwnProperty(key)) {
                if (product[key]) {
                    product['dimension' + gtmSettings.gua.dimensions[key]] = product[key];
                }
            }
        }
    }

    return product;
}

export function normalizeComponent(product, origin) {
    let result = {};
    if (origin === 'component') {
        // Componenti derivata dalla pagina dei componenti
        result = {
            id: product.idmag,
            name: product.descr + (product.lato ? ' lato ' + product.lato : ''),
            price: parseFloat(product.przc),
            category: product.marcadescr + '/' + product.mdescr,
            mmv: product.marcadescr + ' ' + product.mdescr + ' ' + product.vdescr,
            list: 'category',
            variant: '',
            brand: product.marcadescr,
            model: product.mdescr,
            ania: product.descrAnia,
            versionId: product.idVer,
            quantity: 1
        }
    } else if (origin === 'product') {
        // Componente derivato dalla pagina dei cartellini
        result = {
            id: product.idmag,
            name: product.descr + (product.lato ? ' lato ' + product.lato : ''),
            price: parseFloat(product.przc),
            category: product.dsMar + '/' + product.dsMod,
            mmv: product.dsMar + ' ' + product.dsMod + ' ' + product.dsVer,
            list: 'product_page',
            variant: product.variant ? product.variant : '',
            brand: product.dsMar,
            model: product.dsMod,
            ania: product.descrAnia,
            versionId: product.idVer,
            quantity: 1
        }
    } else if (origin === 'cart') {
        result = {
            id: product.idmag,
            name: product.descr + (product.lato ? ' lato ' + product.lato : ''),
            price: parseFloat(product.prezzo),
            category: product.dsMar + '/' + product.dsMod,
            mmv: product.dsMar + ' ' + product.dsMod + ' ' + product.dsVer,
            list: 'cart',
            variant: product.cartellino !== 0 ? product.cartellino : null,
            brand: product.dsMar,
            model: product.dsMod,
            ania: product.descrAnia,
            versionId: product.idVer,
            quantity: 1
        }
    } else if (origin === 'category-list') {
        result = {
            id: product.idmag,
            name: product.descr + (product.lato ? ' lato ' + product.lato : ''),
            price: parseFloat(product.przc),
            category: product.category.descrEcommerce + '/' + product.dsMar + '/' + product.dsMod,
            mmv: product.dsMar + ' ' + product.dsMod,
            list: 'product_page',
            variant: '',
            brand: product.dsMar,
            model: product.dsMod,
            ania: product.replacement.descrEx,
            versionId: product.idVer
        }
    }

    return applyCustomDimensions(result);
}

export function normalizeComponents(products, origin) {
    let result = [];
    if (Array.isArray(products)) {
        result = products.map(product => {
            return normalizeComponent(product, origin);
        });
    }

    return result;
}

export function onComponentList(products) {
    let remarketingLayer = {};
    const dataLayerObject = {
        'event': 'scrollTracking',
        'eventCategory': 'engagement',
        'eventAction': 'view_item_list',
        'eventLabel': '',
        'eventValue': ''
    };

    if (Array.isArray(products) && products.length > 0) {

        dataLayerObject.ecommerce = {
            'currencyCode': gtmSettings.common.currency,
            'impressions': ''
        };

        if (gtmSettings.gua.trackingId) {
            dataLayerObject.ecommerce = {
                'currencyCode': gtmSettings.common.currency,
                'impressions': getProductLayered(products, 'gua')
            };
        }

        if (gtmSettings.gua.trackingId || gtmSettings.googleAds.trackingId) {
            if (gtmSettings.gua.remarketingFeature) {
                remarketingLayer = getRemarketingLayer(products, 'category');

                Object.assign(dataLayerObject, remarketingLayer);
            }
        }

        pushDataLayer(dataLayerObject);
    }
}

export function onComponentClick(product, link) {
    const dataLayerObject = {
        'event': 'productClick',
        'eventCategory': 'engagement',
        'eventAction': 'select_content',
        'eventLabel': 'product_list',
        'eventValue': ''
    };

    if (gtmSettings.gua.trackingId) {
        dataLayerObject.ecommerce = {
            'currencyCode': gtmSettings.common.currency,
            'click': {
                'actionField': {
                    'list': product.list,
                },
                'products': [
                    getProductLayer(product, 'gua')
                ]
            }
        };
    }

    if (link) {
        dataLayerObject.eventCallback = callbackWithTimeout(
            function () {
                window.location.href = link;
            },
            2000
        )
    }

    pushDataLayer(dataLayerObject);
}

export function onComponentView(product) {
    const dataLayerObject = {
        'event': 'productDetail',
        'eventCategory': 'engagement',
        'eventAction': 'view_item',
        'eventLabel': '',
        'eventValue': ''
    };

    const productDetailLayer = getProductDetailLayer(product);

    Object.assign(dataLayerObject, productDetailLayer);

    pushDataLayer(dataLayerObject);
}

export function onAddToCart(product, actualPrice, variant) {
    product.price = parseFloat(actualPrice);
    product.variant = variant || '';

    const dataLayerObject = {
        'event': 'addToCart',
        'eventCategory': 'ecommerce',
        'eventAction': 'add_to_cart',
        'eventLabel': '',
        'eventValue': ''
    };
    const addToCartLayer = getAddToCartLayer(product);

    Object.assign(dataLayerObject, addToCartLayer);

    dataLayerObject.eventCallback = callbackWithTimeout(
        function () {
            window.location.reload();
        },
        2000
    );

    pushDataLayer(dataLayerObject);
}

export function onRemoveFromCart(product) {
    const dataLayerObject = {
        'event': 'removeFromCart',
        'eventCategory': 'ecommerce',
        'eventAction': 'remove_from_cart',
        'eventLabel': '',
        'eventValue': ''
    };

    if (gtmSettings.gua.trackingId) {
        dataLayerObject.ecommerce = {
            'currencyCode': gtmSettings.common.currency,
            'remove': {
                'actionField': {
                    'list': product.list
                },
                'products': [getProductLayer(product, 'gua')]
            }
        };
    }

    dataLayerObject.eventCallback = callbackWithTimeout(
        function () {
            window.location.reload();
        },
        2000
    );

    pushDataLayer(dataLayerObject);
}

export function onCheckout(products) {
    const dataLayerObject = {
        'event': 'checkout',
        'eventCategory': 'ecommerce',
        'eventAction': 'begin_checkout',
        'eventLabel': 'step_1',
        'eventValue': ''
    };

    const checkoutLayer = getCheckoutLayer(products);

    Object.assign(dataLayerObject, checkoutLayer);

    pushDataLayer(dataLayerObject);
}

export function onCompleteOrder(orders, order_confirmed_path) {
    orders.forEach((orderComplete, index) => {
        const dataLayerObject = {
            'event': 'orderComplete',
            'eventCategory': 'ecommerce',
            'eventAction': 'purchase',
            'eventLabel': '',
            'eventValue': ''
        };

        const orderLayer = getOrderLayer(orderComplete);

        Object.assign(dataLayerObject, orderLayer);

        if (index === orders.length - 1) {
            dataLayerObject.eventCallback = callbackWithTimeout(
                function () {
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = order_confirmed_path;

                    orders.forEach((order) => {
                        let element = document.createElement('input');
                        element.value = JSON.stringify(order);
                        element.name = 'orders[]';
                        element.type = 'hidden';
                        form.appendChild(element);
                    });

                    document.body.appendChild(form);
                    form.submit();
                },
                2000
            );
        }
        pushDataLayer(dataLayerObject);
    });
}

export function trackFormSubmit(identificativo_form, page_path) {
    pushDataLayer({
        event: 'contact_submission',
        identificativo_form,
        page_path,
    });
}