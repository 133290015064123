import Vue from 'vue';
import AccountProfile from './account-profile.vue';
import DescriptionSelect from './description-select.vue';
import FilterComplete from './filter-complete.vue';
import FilterLicenseNumber from './filter-license-number.vue';
import FilterModel from './filter-model.vue';
import FilterTires from './filter-tires.vue';
import HeaderCart from './header-cart.vue';
import HeaderMobileCart from './header-mobile-cart.vue';
import ViewCart from './view-cart.vue';
import Checkout from './checkout.vue';
import CheckoutSede from './checkout-sede.vue';
import VueNumerals from 'vue-numerals';
import './filters/removeVat';
import './filters/getTax';
import VueCookies from 'vue-cookies';
import NewAddress from './new-address.vue';
import SiteHeaderSearch from './site-header-search.vue';
import SignUp from './sign-up.vue';
import ProductReturn from './product-return.vue';
import EditShippingAddress from './edit-shipping-address.vue';
import trans from "../i18n";
import RottamaGratis from './rottama-gratis.vue';
import SediPuntiVendita from './sedi-punti-vendita.vue';
import Voucher from './voucher.vue';

Vue.use(VueNumerals, {
  locale: 'it',
});
Vue.use(VueCookies);

Vue.prototype.$trans = trans;

Vue.filter('kilometers', function (value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' km';
})

/**
 * Create a fresh Vue Application instance
 */
var vm = new Vue({
  el: '#app',
  components: {
    AccountProfile,
    DescriptionSelect,
    FilterComplete,
    FilterLicenseNumber,
    FilterModel,
    FilterTires,
    HeaderCart,
    HeaderMobileCart,
    ViewCart,
    Checkout,
    CheckoutSede,
    NewAddress,
    SiteHeaderSearch,
    SignUp,
    ProductReturn,
    EditShippingAddress,
    RottamaGratis,
    SediPuntiVendita,
    Voucher,
  }
});


