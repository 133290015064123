<template>
  <div class="indicator indicator--trigger--click">
    <a href="" class="indicator__button">
      <span
          v-if="loading"
          class="indicator__icon btn btn-light btn-loading btn-lg btn-icon"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <path
              d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z
                    M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z"
          ></path>
        </svg>
      </span>
      <span v-if="!loading" class="indicator__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
          <circle cx="10.5" cy="27.5" r="2.5"/>
          <circle cx="23.5" cy="27.5" r="2.5"/>
          <path
              d="M26.4,21H11.2C10,21,9,20.2,8.8,19.1L5.4,4.8C5.3,4.3,4.9,4,4.4,4H1C0.4,4,0,3.6,0,3s0.4-1,1-1h3.4C5.8,2,7,3,7.3,4.3
                    l3.4,14.3c0.1,0.2,0.3,0.4,0.5,0.4h15.2c0.2,0,0.4-0.1,0.5-0.4l3.1-10c0.1-0.2,0-0.4-0.1-0.4C29.8,8.1,29.7,8,29.5,8H14
                    c-0.6,0-1-0.4-1-1s0.4-1,1-1h15.5c0.8,0,1.5,0.4,2,1c0.5,0.6,0.6,1.5,0.4,2.2l-3.1,10C28.5,20.3,27.5,21,26.4,21z"
          />
        </svg>
        <span class="indicator__counter" v-if="cart.items">{{
            cart.items.length
          }}</span>
      </span>
      <span class="indicator__title">{{ $trans('cart.titolo') }}</span>
      <span v-if="cart.items && cart.items.length > 0" class="indicator__value" style="white-space: nowrap">
        <span v-if="isBusiness">{{
            cartTotal | removeVat(vat) | numeralFormat("0.00 $")
          }}
          <span style="font-size: 11px;">+ {{ $trans('cart.iva') }}</span>
        </span>
        <span v-else>{{ cartTotal | numeralFormat("0.00 $") }}</span>
      </span>
      <span
          v-if="!cart.items || cart.items.length <= 0"
          class="indicator__value"
      >{{ $trans('cart.vuoto') }}</span
      >
    </a>
    <div class="indicator__content">
      <div
          v-if="(!cart.items || cart.items.length <= 0) && !loading"
          class="alert alert-secondary mb-3 mb-md-5 text-center"
      >
        {{ $trans('cart.vuoto') }}
      </div>
      <div class="dropcart" v-if="cart.items && cart.items.length > 0">
        <ul class="dropcart__list">
          <li
              class="dropcart__item margin-bottom-05"
              v-for="item in cart.items"
              :key="item.id"
          >
            <div class="dropcart__item-image">
              <picture
                  v-if="typeof item.path !== 'undefined' && item.path.length > 0"
              >
                <source
                    :data-srcset="
                    item.path[0] +
                    '_thumb.webp 1x, ' +
                    item.path[0] +
                    '_thumb@2x.webp 2x'
                  "
                    type="image/webp"
                />
                <img
                    width="60"
                    height="60"
                    :data-srcset="
                    item.path[0] +
                    '_thumb.jpg 1x, ' +
                    item.path[0] +
                    '_thumb@2x.jpg'
                  "
                    class="lazyload"
                />
              </picture>
              <picture v-else>
                <source
                    data-srcset="/images/img-not-found.webp 1x, /images/img-not-found@2x.webp 2x"
                    type="image/webp"
                />
                <img
                    width="60"
                    height="60"
                    data-srcset="/images/img-not-found.jpg 1x, /images/img-not-found@2x.jpg"
                    class="lazyload"
                    src="/images/placeholder-700x700-loading.png"
                />
              </picture>
            </div>
            <div class="dropcart__item-info">
              <div class="dropcart__item-name">
                {{ item.descr }}
                <br v-if="item.tag && item.tag.km"><span v-if="item.tag && item.tag.km">
                  <strong>{{ $trans('common.chilometri') }}: </strong>
                  <span>{{item.tag.km }}</span>
                </span>
                <span v-if="item.lato"
                >{{ $trans('common.lato', {lato: item.lato === "S" ? $trans('common.sinistro') : $trans('common.destro')}) }}</span
                >
              </div>
              <div class="dropcart__item-meta">
                <div
                    v-if="item.cartellino == '' || item.cartellino == 0"
                    class="dropcart__item-quantity da-verificare"
                >
                  {{ $trans('cart.da-verificare') }}
                </div>
                <div
                    v-if="item.cartellino != '' && item.cartellino != 0"
                    class="dropcart__item-quantity pronta-consegna"
                >
                  {{ $trans('cart.pronta-consegna') }}
                </div>
                <div class="dropcart__item-price" v-if="isBusiness">
                  {{ item.prezzo | removeVat(vat) | numeralFormat("0.00 $") }}
                </div>
                <div class="dropcart__item-price" v-else>
                  {{ formatPrice(item.prezzo) | numeralFormat("0.00 $") }}
                </div>
              </div>
            </div>
            <button
                type="button"
                class="dropcart__item-remove"
                v-on:click="removeItem(cart.id, item)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                <path
                    d="M8.8,8.8L8.8,8.8c-0.4,0.4-1,0.4-1.4,0L5,6.4L2.6,8.8c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L3.6,5L1.2,2.6
                                c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L5,3.6l2.4-2.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L6.4,5l2.4,2.4
                                C9.2,7.8,9.2,8.4,8.8,8.8z"
                />
              </svg>
            </button>
          </li>
        </ul>
        <div class="dropcart__totals">
          <table>
            <tr>
              <th v-if="isBusiness">{{ $trans('common.tot-iva-excl') }}</th>
              <th v-else>{{ $trans('common.tot-iva-incl') }}</th>
              <td v-if="isBusiness">
                <strong>{{
                    cartTotal | removeVat(vat) | numeralFormat("0.00 $")
                  }}</strong>
              </td>
              <td v-else>
                <strong>{{ cartTotal | numeralFormat("0.00 $") }}</strong>
              </td>
            </tr>
          </table>
        </div>
        <div class="dropcart__actions">
          <a :href="this.cartpath" class="btn btn-primary"> {{ $trans('cart.vai') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import toastr from "toastr";
import {CustomSwal} from "../utils";
import {normalizeComponent, onRemoveFromCart} from "../tag-manager/gtm";
import trans from "../i18n";

export default {
  name: "header-cart",
  props: {
    cartpath: String,
    checkoutpath: String,
    isBusiness: {
      required: false,
      type: Number,
      default: 0,
    },
    vat: Number
  },
  mounted: function () {
    // this.isBusiness = 1;
  },
  data() {
    return {
      cart: {
        items: [],
      },
      cartTotal: 0,
      loading: false,
    };
  },
  created() {
    this.$set(this, "cart", {items: []});
    this.loadCart();
  },
  methods: {
    loadCart: function () {
      this.$set(this, "loading", true);
      return axios
          .request({
            method: "GET",
            url: "/api/v1/cart",
          })
          .then((response) => {
            if (response && response.data && response.data.data) {
              if (response.data.data[0] != null) {
                this.$set(this, "cart", response.data.data[0]);
                this.$set(this, "cartTotal", this.totalCart());
              } else {
                this.$set(this, "loading", true);
              }
            }
            this.$set(this, "loading", false);
          })
          .catch((error) => {
            this.$set(this, "cart", {items: []});
            this.$set(this, "loading", false);

            toastr.error(error.message, "Errore");
          });
    },
    removeItem: function (cartId, item) {
      if (item.in_coppia) {
        console.log(item);
        CustomSwal({
          title: trans("form-input.attenzione"),
          html: trans("form-input.in-coppia-cancellare"),
          confirmButtonText: trans("js-files.si-elimina"),
          cancelButtonText: trans("js-files.no-annulla"),
          icon: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            return axios
                .request({
                  method: "delete",
                  url: "/api/v1/cart/remove/" + cartId + "/" + item.id,
                })
                .then((response) => {
                  onRemoveFromCart(normalizeComponent(item, "cart"));
                })
                .catch((error) => {
                  toastr.error(error.message, "Errore");
                });
          }
        });
      } else {
        return axios
            .request({
              method: "delete",
              url: "/api/v1/cart/remove/" + cartId + "/" + item.id,
            })
            .then((response) => {
              onRemoveFromCart(normalizeComponent(item, "cart"));
            })
            .catch((error) => {
              toastr.error(error.message, "Errore");
            });
      }
    },
    totalCart: function () {
      if (this.cart && this.cart.items && this.cart.items.length > 0) {
        var total = 0;
        for (var index in this.cart.items) {
          if (this.cart.items.hasOwnProperty(index)) {
            var item = this.cart.items[index];
            total = total + Number(item.prezzo);
          }
        }

        return total;
      } else {
        return 0;
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
<style scoped>
</style>
