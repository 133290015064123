import messages from './../lang/vue.json';

Object.byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

const trans = function (key, parameters = {}, locale = null)
{
    if (!locale) {
        locale = document.querySelector('html').getAttribute('lang');
    }
    let langMessages = messages[locale];
    if (!langMessages) {
        langMessages = messages['it'];
    }

    let result = Object.byString(langMessages, key) ? Object.byString(langMessages, key) : key;

    for(const key in parameters) {
        if (parameters.hasOwnProperty(key)) {
            result = result.replace(`:${key}`, parameters[key]);
        }
    }

    return result;
}

export default trans;
