<template>
  <div>
    <div class="d-flex justify-content-end">
      <table v-if="order.id && voucherData && voucherData.valore_voucher">
        <tr>
          <th class="text-right pr-2">Voucher</th>
          <td class="text-right">{{ (voucherData.valore_voucher * -1) | numeralFormat("0.00 $") }}</td>
        </tr>
      </table>
    </div>
    <div class="d-flex justify-content-end form-group align-items-center">
      <label for="voucher" class="mr-2 mb-0" v-if="showLabel">Hai un voucher? Inseriscilo qui</label>
      <input type="text" id="voucher" placeholder="Voucher promozionale" class="form-control" v-model="voucher"/>
      <button class="btn btn-primary h-100" @click="apply()" type="button" v-if="!predefined">Applica</button>
      <button class="btn btn-primary h-100" @click="reset()" type="button" v-else>Reset</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "voucher",
  props: {
    cart: {
      type: Object,
      default: () => ({}),
      required: false
    },
    order: {
      type: Object,
      default: () => ({}),
      required: false
    },
    showLabel: {
      type: Boolean,
      default: true,
      required: false,
    },
    reloadPage: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      voucher: "",
      voucherData: {},
      predefined: false,
    }
  },
  mounted() {
    this.voucher = this.cart.voucher || (this.order.voucher ? this.order.voucher.voucher : "") || "";
    if (this.voucher) {
      this.predefined = true;
      this.apply();
    }
  },
  methods: {
    apply() {
      let url = '/api/v1/cart/apply-voucher';
      if (this.order.id) {
        url = '/api/v1/order/' + this.order.id + '/apply-voucher';
      }

      axios.post(url, {
        voucher: this.voucher,
      }).then(response => {
        this.voucherData = response.data.data[0];
        this.$emit('voucherApplied', this.voucherData.valore_voucher);
        this.predefined = true;
        if (this.reloadPage && !this.order.voucher) window.location.reload();
      }).catch(error => {
        this.voucher = "";
        CustomSwal({
          title: "Errore",
          html: error.response.data.errors.join("<br><br>"),
          confirmButtonText: "Ok",
          icon: "error",
        }).then(() => {
        });
      })
    },
    reset() {
      let url = '/api/v1/cart/reset-voucher';
      if (this.order.id) {
        url = '/api/v1/order/' + this.order.id + '/reset-voucher';
      }

      axios.delete(url).then(() => {
        this.$emit('voucherApplied', null);
        this.voucher = "";
        this.voucherData = {};
        this.predefined = false;
        if (this.reloadPage) window.location.reload();
      }).catch(error => {
        this.voucher = "";
        CustomSwal({
          title: "Errore",
          html: error.response.data.errors.join("<br><br>"),
          confirmButtonText: "Ok",
          icon: "error",
        }).then(() => {
        });
      })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  }
}
</script>

<style scoped>
#voucher {
  max-width: 300px;
}
</style>