<template>
  <div>
    <div class="cart">
      <div class="cart__table cart-table">
        <div v-if="loading" class="alert alert-secondary col-xs-12 text-center">
          <div class="btn btn-secondary btn-loading btn-xl btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
              <path
                  d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z
                        M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z"
              ></path>
            </svg>
          </div>
        </div>
        <div
            v-if="(!cart || !cart.items || cart.items.length <= 0) && !loading"
            class="alert alert-secondary col-xs-12 text-center"
        >
          {{ $trans("cart.carrello-vuoto") }}
        </div>
        <table
            class="cart-table__table"
            v-if="cart && cart.items && cart.items.length > 0"
        >
          {{
            item
          }}
          <thead class="cart-table__head">
          <tr class="cart-table__row">
            <th class="cart-table__column cart-table__column--image">
              {{ $trans("cart.immagine") }}
            </th>
            <th class="cart-table__column cart-table__column--product">
              {{ $trans("cart.automobile") }}
            </th>
            <th class="cart-table__column cart-table__column--product">
              {{ $trans("cart.nome-prodotto") }}
            </th>
            <th class="cart-table__column cart-table__column--notes text-center">
              {{ $trans("checkout.note") }}
            </th>
            <th class="cart-table__column cart-table__column--cartellino text-center">
              {{ $trans("cart.riferimento-interno") }}
            </th>
            <th class="cart-table__column cart-table__column--sede text-center">
              {{ $trans("cart.sede")}}
            </th>
            <th class="cart-table__column cart-table__column--price">
              {{ $trans("cart.prezzo") }}<br />
              <span class="text-small" v-if="isBusiness">
                  {{ $trans("cart.iva-esclusa") }}</span
              >
              <span class="text-small" v-else>
                  {{ $trans("cart.iva-inclusa") }}</span
              >
            </th>
            <th class="cart-table__column cart-table__column--quantity">
              {{ $trans("cart.stato") }}
            </th>
            <th class="cart-table__column cart-table__column--remove"></th>
          </tr>
          </thead>
          <tbody class="cart-table__body">
          <tr
              class="cart-table__row"
              v-for="item in cart.items"
              :key="item.id"
          >
            <td class="cart-table__column cart-table__column--image">
              <picture
                  v-if="
                    typeof item.path !== 'undefined' && item.path.length > 0
                  "
                  v-on:click="openGallery(item)"
              >
                <source
                    :data-srcset="
                      item.path[0] +
                      '_thumb.webp 1x, ' +
                      item.path[0] +
                      '_thumb@2x.webp 2x'
                    "
                    type="image/webp"
                />
                <img
                    :data-srcset="
                      item.path[0] +
                      '_thumb.jpg 1x, ' +
                      item.path[0] +
                      '_thumb@2x.jpg'
                    "
                    class="lazyload"
                />
              </picture>
              <picture v-else>
                <source
                    data-srcset="/images/img-not-found.webp 1x, /images/img-not-found@2x.webp 2x"
                    type="image/webp"
                />
                <img
                    data-srcset="/images/img-not-found.jpg 1x, /images/img-not-found@2x.jpg"
                    class="lazyload"
                    src="/images/placeholder-700x700-loading.png"
                />
              </picture>
              <button
                  v-if="
                    typeof item.path !== 'undefined' && item.path.length > 0
                  "
                  class="btn btn-black btn-sm btn-block"
                  v-on:click="openGallery(item)"
              >
                <i class="fas fa-images"></i>
              </button>
            </td>
            <td class="cart-table__column cart-table__column--product">
              {{ item.dsMar }} {{ item.dsMod }} {{ item.dsVer }}
              <p class="m-0" v-if="item.targa">
                <strong>{{ $trans("cart.targa") }}: </strong
                ><span class="text-uppercase">{{ item.targa }}</span>
              </p>
              <p class="m-0" v-if="item.codice_motore">
                <strong>{{ $trans("cart.cod-motore") }}: </strong>
                {{ item.codice_motore }}
              </p>
              <p class="m-0" v-if="item.anno_immatricolazione">
                <strong>{{ $trans("cart.anno-imm") }}: </strong>
                {{ item.anno_immatricolazione }}
              </p>
              <div
                  class="alert alert-warning mb-1"
                  v-if="
                    item.stato_cartellino !== '' &&
                    item.stato_cartellino !== 'B'
                  "
              >
                <i class="fas fa-exclamation-triangle"></i>
                {{ $trans("cart.difetti-ricambio") }}
              </div>
              <div
                  class="alert alert-warning mt-1"
                  v-if="item.removed_components.length > 0"
              >
                {{ $trans("cart.non-presenti") }}:
                <ul class="mb-0">
                  <li
                      class="small"
                      v-for="component in item.removed_components"
                  >
                    {{ component.descr_figlio }}
                  </li>
                </ul>
              </div>
            </td>
            <td class="cart-table__column cart-table__column--product">
              {{ item.descr }}
              <div v-if="item.tag && item.tag.km">
                <strong>{{ $trans('common.chilometri') }}: </strong>
                <span>{{item.tag.km }}</span>
              </div>
              <span v-if="item.lato"
              >{{ $trans('common.lato', {lato: item.lato === "S" ? $trans('common.sinistro') : $trans('common.destro')}) }}</span
              >
            </td>
            <td
                class="cart-table__column cart-table__column--notes text-center"
                data-title="Note"
            >
              <small v-if="!item.tag">&mdash;</small>
              <small v-else>{{ item.tag.component.replacement.visualizza_note == 'both' ? item.tag.note : (item.tag.component.replacement.visualizza_note == 'nota1' ? item.tag.note1 : item.tag.note2)}}</small>
            </td>
            <td
                class="cart-table__column cart-table__column--cartellino text-center"
                data-title="Riferimento interno"
            >
              {{ item.cartellino }}
            </td>
            <td
                class="cart-table__column cart-table__column--sede text-center"
                data-title="Sede"
            >
              {{ item.seat.nome_sede }}
            </td>
            <td
                class="cart-table__column cart-table__column--price"
                data-title="Prezzo"
            >
              <div class="whole-price" v-if="item.prezzo < item.listino">
                {{ formatPrice(item.listino) | numeralFormat("0.00 $") }}
              </div>
              {{ formatPrice(item.prezzo) | numeralFormat("0.00 $") }}
              <div class="small" v-if="isBusiness">
                ({{
                  item.prezzo | removeVat(vat) | numeralFormat("0.00 $")
                }}
                + {{ $trans("cart.iva") }})
              </div>
            </td>
            <td
                class="cart-table__column cart-table__column--quantity"
                data-title="Stato"
            >
              <div
                  v-if="item.cartellino != '' && item.cartellino != 0"
                  class="cart-table__quantity"
              >
                <div
                    class="
                      status-badge
                      status-badge--style--success
                      status-badge--has-icon
                      status-badge--has-text
                    "
                >
                  <div class="status-badge__body">
                    <div class="status-badge__icon">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                      >
                        <path
                            d="M12,4.4L5.5,11L1,6.5l1.4-1.4l3.1,3.1L10.6,3L12,4.4z"
                        ></path>
                      </svg>
                    </div>
                    <div class="status-badge__text">
                      {{ $trans("cart.pronta-consegna") }}
                    </div>
                    <div
                        class="status-badge__tooltip"
                        tabindex="0"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Pronta Consegna"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                  v-if="item.cartellino == '' || item.cartellino == 0"
                  class="
                    status-badge
                    status-badge--style--failure
                    status-badge--has-icon
                    status-badge--has-text
                  "
              >
                <div class="status-badge__body">
                  <div class="status-badge__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                    >
                      <path
                          d="M6.5,0C2.9,0,0,2.9,0,6.5S2.9,13,6.5,13S13,10.1,13,6.5S10.1,0,6.5,0z M6.5,2c0.9,0,1.7,0.3,2.4,0.7L2.7,8.9
                                            C2.3,8.2,2,7.4,2,6.5C2,4,4,2,6.5,2z M6.5,11c-0.9,0-1.7-0.3-2.4-0.7l6.2-6.2C10.7,4.8,11,5.6,11,6.5C11,9,9,11,6.5,11z"
                      ></path>
                    </svg>
                  </div>
                  <div class="status-badge__text">
                    {{ $trans("cart.da-verificare") }}
                  </div>
                  <div
                      class="status-badge__tooltip"
                      tabindex="0"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Da Verificare"
                  ></div>
                </div>
              </div>
            </td>
            <td class="cart-table__column cart-table__column--remove">
              <button
                  style="color: red"
                  type="button"
                  class="cart-table__remove btn btn-sm btn-icon btn-muted"
                  v-on:click="removeItem(cart.id, item)"
              >
                <i class="fas fa-trash" style="font-size: 24px"></i>
              </button>
            </td>
          </tr>
          </tbody>
          <tfoot class="cart-table__foot">
          <tr>
            <td colspan="6">
              <div class="cart-table__actions"></div>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div
        class="cart__totals col-lg-10 mt-2 ml-0 mb-2 float-right"
        v-if="cart && cart.items && cart.items.length > 0"
    >
      <div class="card">
        <div class="card-body card-body--padding--2">
          <div class="col-12 p-0">
            <b>
              {{ $trans("cart.inserisci-cap") }}.
            </b>
          </div>
          <div class="d-flex flex-nowrap col-12 align-items-center border-bottom" style="gap: 50px;">
            <div>
              <select
                  id="country"
                  class="form-control"
                  name="nazione"
                  v-model="nazione"
              >
                <option v-for="item in nations">{{ item.nazione }}</option>
              </select>
              <div v-if="nazione && nations.find(el => el.nazione === nazione).blacklistEcommerce">
                <div class="vue-field-error">
                  {{ $trans("form-input.nazione-blacklist") }}
                </div>
              </div>
            </div>
            <cap-address
                ref="capComponent"
                :key="triggerUpdate.capAddress"
                :noStyle="true"
                class="p-0 pb-2 pt-2 mb-2"
                :nation="nazione"
                value="cap"
                @updateShipping="updateShippingText"
                style="width: 300px"
            ></cap-address>
          </div>
          <div class="d-flex pt-2">
<!--            <div class="offset-container" style="width: 40%; height: 0"></div>-->

            <div class="col-12 text-right" v-if="shipLoader">
              <div
                  class="btn btn-secondary btn-loading btn-xl btn-iconbg-transparent"
                  style="width: 100%"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path
                      d="M14,15h-4v-2h3v-3h2v4C15,14.6,14.6,15,14,15z M13,3h-3V1h4c0.6,0,1,0.4,1,1v4h-2V3z M6,3H3v3H1V2c0-0.6,0.4-1,1-1h4V3z
                        M3,13h3v2H2c-0.6,0-1-0.4-1-1v-4h2V13z"
                  ></path>
                </svg>
              </div>
            </div>
            <div
                v-if="!shipLoader"
                class="card-title border-none m-0 text-right card-price-checkout w-full"
            >
              <div class="card-price-span" v-if="isBusiness">
                <span class="t-text">
                  {{ $trans("cart.totale-carrello") }}
                </span>
                <span class="t-price">
                  <span v-if="isBusiness">{{
                      cartTotal | removeVat(vat) | numeralFormat("0.00 $")
                    }}</span>
                  <span v-else>{{ cartTotal | numeralFormat("0.00 $") }}</span>
                </span>
              </div>

              <div>
                <div class="card-price-span" v-if="shippingText">
                  <span class="t-text"
                  >{{ $trans("cart.spedizione-imballaggio") }}
                  </span>
                  <span class="t-price" v-if="isBusiness">
                    {{
                      shippingText | removeVat(vat) | numeralFormat("0.00 $")
                    }}
                  </span>
                  <span class="t-price" v-else>
                    {{ shippingText | numeralFormat("0.00 $") }}
                  </span>
                </div>

                <div v-if="isBusiness" class="card-price-span">
                  <span class="t-text">{{ $trans("cart.iva") }} </span>
                  <span class="t-price">
                    {{
                      (cartTotal + shippingText)
                          | removeVat(vat)
                          | getTax(vat)
                          | numeralFormat("0.00 $")
                    }}
                  </span>
                </div>

                <div class="card-price-span" v-if="valoreVoucher">
                  <span class="t-text font-weight-bolder">
                    Voucher
                  </span>
                  <span class="t-price">
                    <strong>
                      {{ valoreVoucher | numeralFormat("0.00 $") }}
                    </strong>
                  </span>
                </div>

                <div class="card-price-span">
                  <span class="t-text font-weight-bolder">
                    {{ $trans("cart.totale-ordine") }}
                  </span>
                  <span class="t-price">
                    <strong>
                      {{ (cartTotal + shippingText) | numeralFormat("0.00 $") }}
                    </strong>
                  </span>
                </div>

              </div>
            </div>
          </div>
          <Voucher class="mt-2" :cart="cart" @voucherApplied="voucherApplied" />
          <div class="my-3 d-flex justify-content-end">
            <div
                data-pp-message
                data-pp-style-layout="text"
                data-pp-style-logo="inline"
                data-pp-placement="cart"
                data-pp-style-logo-position="top"
                data-pp-style-text-color="white"
                style="background-color:#0e3383;padding-left:12px;padding-top:12px"
                :data-pp-amount="cartTotal + shippingText">
            </div>
          </div>
          <div class="mt-2 button-container">
            <div class="alert alert-danger" v-if="isInCoppia">
              <b><i class="fas fa-exclamation-triangle"></i>
                {{ $trans('cart.coppia-lista') }}: </b>
              <ul class="m-0">
                <li v-for="item in itemsInCoppia">{{ item.descr }}</li>
              </ul>
            </div>
          </div>
          <div class="d-flex mt-2 button-container" v-if="!isInCoppia">
            <a
                class="btn btn-primary btn-xl btn-block ritiro-sede-btn"
                :href="checkoutsedepath"
            >{{ $trans("cart.ritiro-sede") }}</a
            >
            <a
                class="btn btn-primary btn-xl btn-block spedizione-btn"
                style="margin-top: 0px !important; margin-left: 7px"
                :href="checkoutpath"
            >{{ $trans("cart.spedizione") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import toastr from "toastr";
import {normalizeComponent, onRemoveFromCart} from "../tag-manager/gtm";
import {CustomSwal} from "../utils";
import CapAddress from "./cap-address.vue";
import Voucher from './voucher.vue';
import trans from "../i18n";

export default {
  name: "view-cart",
  components: {
    CapAddress,
    Voucher,
  },
  props: {
    cartpath: String,
    checkoutpath: String,
    checkoutsedepath: String,
    isBusiness: {
      required: false,
      type: Number,
      default: 0,
    },
    vat: Number,
    user: {
      required: false,
      type: String|Object,
      default: null,
    },
  },
  mounted: function () {
    console.log(this.client, this.user)
    localStorage.setItem('locale', this.checkoutsedepath.substring(1,3));
    axios.get('/api/v1/nations').then((res) => {
      this.nations = res.data.data[0];
    });
    // this.isBusiness = 1;
  },
  data() {
    return {
      nations: [],
      nazione: "ITALIA",
      cart: {
        items: [],
      },
      cartTotal: 0,
      loading: false,
      shippingText: 0,
      shipLoader: false,
      anias: [],
      isInCoppia: false,
      itemsInCoppia: [],
      item: '',
      triggerUpdate: {
        capAddress: 0,
      },
      valoreVoucher: null,
    };
  },
  watch: {
    nazione: function(v){
      this.refreshCap();
      console.log('nazione changed', v);//image.png
    },
  },
  created() {
    if(this.user){
      const user = typeof this.user === "string" ? JSON.parse(this.user) : this.user;
      if(user && user.client && user.client.nazione){
        this.nazione = user.client.nazione;
      }
    }

    this.$set(this, "cart", {items: []});
    this.loadCart();
  },
  methods: {
    refreshCap() {
      this.$set(this, "cap-address", null);
      ++this.triggerUpdate.capAddress;
      this.shippingText = 0;
    },
    updateShippingText() {
      this.shippingText = this.$refs.capComponent.shippingText;
      this.cap = this.$refs.capComponent.cap;
      this.shipLoader = this.$refs.capComponent.shipLoading;
    },

    loadCart: function () {
      this.$set(this, "loading", true);
      return axios
          .request({
            method: "GET",
            url: "/api/v1/cart",
          })
          .then((response) => {
            if (response && response.data && response.data.data) {
              if (response.data.data[0] != null) {
                response.data.data[0].items.forEach((el) => {
                  if (el.in_coppia) {
                    this.anias.push(el.ania);
                  }
                });
                this.$set(this, "cart", response.data.data[0]);
                this.$set(this, "cartTotal", this.totalCart());
                this.checkInCoppia();
              } else {
                this.$set(this, "cart", {items: []});
              }
            }
            this.$set(this, "loading", false);
          })
          .catch((error) => {
            this.$set(this, "cart", {items: []});
            this.$set(this, "loading", false);
            toastr.error(error.message, "Errore");
          });
    },
    checkInCoppia() {
      //Conto le occorrenze di ogni ania che deve essere in coppia
      var counts = {};
      for (let num of this.anias) {
        counts[num] = counts[num] ? counts[num] + 1 : 1;
      }
      this.anias.forEach((el) => {
        //Se un ania ha una sola occorrenza lo pusho nell'array di elementi
        // che devono essere acquistati in coppia e verrà fatto visualizzare all'utente
        console.log('cart', counts[el] % 2 != 0)

        if (counts[el] % 2 != 0) {

          this.cart.items.forEach((element) => {
            if (element.ania == el) {
              this.itemsInCoppia.push(element);
              el = null;
            }
            this.itemsInCoppia = [...new Set(this.itemsInCoppia)];
          })
          this.isInCoppia = true;

          CustomSwal({
            title: trans('form-input.attenzione'),
            text: trans('cart.alert-coppia'),
            confirmButtonText: trans('form-input.continua'),
            icon: "warning",
          });
        }  else {
          this.isInCoppia = false;
        }
      });
    },

    removeItem: function (cartId, item) {
      //Controllo che l'ania dell'item non sia contenuto nell'array di ania da vendere in coppia,
      //se è presente e se la variabile di controllo isInCoppia è falsa (quindi non ci sono componenti da vendere in coppia oppure la coppia di ania è completa)
      //allora comparirà un pop-up warning all'utente.
      if (this.anias%2 != 0 && item.in_coppia && !this.isInCoppia) {
        CustomSwal({
          title: trans('form-input.attenzione'),
          text: trans('cart.coppia-delete'),
          confirmButtonText: trans('js-files.si-elimina'),
          cancelButtonText: trans('js-files.no-annulla'),
          icon: "warning",
          showCancelButton: true,
        }).then(function (result) {
          if (result.value) {
            return axios
                .request({
                  method: "delete",
                  url: "/api/v1/cart/remove/" + cartId + "/" + item.id,
                })
                .then((response) => {
                  onRemoveFromCart(normalizeComponent(item, "cart"));
                })
                .catch((error) => {
                  toastr.error(error.message, "Errore");
                });
          }
        });
      } else {
        return axios
            .request({
              method: "delete",
              url: "/api/v1/cart/remove/" + cartId + "/" + item.id,
            })
            .then((response) => {
              onRemoveFromCart(normalizeComponent(item, "cart"));
            })
            .catch((error) => {
              toastr.error(error.message, "Errore");
            });
      }
    },
    openGallery: function (item) {
      window.openPhotoSwipe(...item.path);
    },
    totalCart: function () {
      if (this.cart && this.cart.items && this.cart.items.length > 0) {
        let total = 0;
        for (let index in this.cart.items) {
          if (this.cart.items.hasOwnProperty(index)) {
            let item = this.cart.items[index];
            total = total + Number(item.prezzo);
          }
        }
        return total;
      }
      return 0;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    voucherApplied(voucher) {
      console.log(voucher);
      this.valoreVoucher = voucher ? voucher * -1 : null;
      this.loadCart();
    }
  },
};
</script>
<style scoped>
.t-text {
  display: inline-block;
}

.t-price {
  width: 25%;
  display: inline-block;
}
@media(min-width: 599px){
  .t-price{
    width: auto;
  }
}

@media (max-width: 600px) {
  .offset-container {
    display: none;
  }
  .t-price {
    width: auto;
  }

  .card-price-checkout {
    width: 100% !important;
  }
  .card-price-span {
    display: flex;
    justify-content: space-between;
  }
  .card-title {
    font-size: 15px !important;
  }
  .button-container {
    flex-direction: column;
  }

  .spedizione-btn {
    margin: 0px !important;
  }
  .ritiro-sede-btn {
    margin-bottom: 3px;
  }
}
</style>
