<template>
  <div class="checkout block" v-show="!hidePaginaForError">
    <div class="container container-xl" >
      <form @submit.prevent="closeCart" id="checkout-form" novalidate>
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-7" v-show="!is_satellite.id">
            <div class="card mb-4" v-if="has_generic">
              <div class="card-body card-body--padding--2">
                <h3 id="metodo_evasione" class="card-title">
                  {{$trans('checkout.come-ricevere')}}<sup>*</sup>
                  <i
                    v-if="showCloseAlert && !metodo_evasione"
                    class="fas fa-exclamation-circle text-danger"
                  ></i>
                </h3>
                <div class="checkout__payment-methods payment-methods">
                  <ul class="payment-methods__list">
                    <li class="payment-methods__item">
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                                :disabled="only_generic"
                                class="input-radio__input collapsible-radio"
                                name="metodo_evasione"
                                required
                                type="radio"
                                v-model="metodo_evasione"
                                value="NOWAIT"
                            />
                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title">
                         {{$trans('checkout.spedisci-subito')}}
                          <span v-if="only_generic"
                            ><small
                              ><i class="fas fa-exclamation-circle"></i> {{$trans('checkout.opzione-disattiva')}}</small
                            ></span
                          >
                        </span>
                      </label>
                      <div class="payment-methods__item-details text-muted">
                       {{$trans('checkout.opzione1')}}
                        <br />
                        {{$trans('checkout.opzione2')}}
                      </div>
                    </li>
                    <li class="payment-methods__item">
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                                class="input-radio__input collapsible-radio"
                                name="metodo_evasione"
                                required
                                type="radio"
                                v-model="metodo_evasione"
                                value="WAIT"
                            />
                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title"
                          >{{$trans('checkout.attendo')}}</span
                        >
                      </label>
                      <div class="payment-methods__item-details text-muted">
                       {{$trans('checkout.opzione3')}}<br>
                      {{$trans('checkout.opzione4')}}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="card mb-4">
              <div class="card-body card-body--padding--2">
                <h3 class="card-title" id="ritiro">
                  {{$trans('checkout.modalita-spedizione')}}<sup>*</sup>
                  <i
                    v-if="showCloseAlert && !ritiro"
                    class="fas fa-exclamation-circle text-danger"
                  ></i>
                </h3>
                <div
                  class="checkout__payment-methods payment-methods"
                  v-show="metodo_evasione"
                >
                  <ul class="payment-methods__list">
                    <li class="payment-methods__item" id="corriere_espresso">
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                                class="input-radio__input collapsible-radio"
                                name="ritiro"
                                required
                                type="radio"
                                v-model="ritiro"
                                v-on:change="calcShipping"
                                value="SP"
                            />
                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title shipping-radio"
                          >{{$trans('checkout.corriere')}}
                          <small>({{$trans('checkout.nostro-corriere')}})</small></span
                        >
                      </label>
                      <div class="payment-methods__item-container">
                        <div class="payment-methods__item-details text-muted">
                        {{$trans('checkout.costo-varia')}}
                        </div>
                      </div>
                    </li>
                    <li class="payment-methods__item" id="ritiro_sede">
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                              class="input-radio__input collapsible-radio"
                              type="radio"
                              v-model="ritiroSede"
                              name="ritiroSede"
                              v-on:change="setRitiroSede"
                            />

                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title"
                          >{{$trans('checkout.ritiro-sede')}}</span
                        >
                      </label>

                      <div class="payment-methods__item-container">
                        <div class="payment-methods__item-details text-muted">
                          <div class="form-row">
                            <div class="form-check col-md-6" v-for="item in sedi">
                              <span class="input-check form-check-input">
                                <span class="input-check__body">
                                  <input
                                      class="input-check__input"
                                      :id="item.nome_sede.toLowerCase()"
                                      v-model="ritiro"
                                      name="ritiro"
                                      type="radio"
                                      :value="item.id_sede"
                                  />
                                  <span class="input-check__box"></span>
                                  <span class="input-check__icon">
                                    <svg height="7px" width="9px" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </span>
                              <label :for="item.nome_sede.toLowerCase()">
                                {{ item.nome_sede }}
                                <small>({{ item.indirizzo_abbreviato.split(', ')[1] }})</small>
                                <i @click="setDetails(item.id_sede)" data-toggle="modal" data-target="#mapModal" class="fas fa-info-circle"></i>
                              </label>
                            </div>

                            <div v-if="showAlertMontaggio" class="v-alert alert--info">
                              {{$trans('checkout.alert-montaggio1')}}
                              <strong>
                                <a href="tel:0309985330" draggable="false">030-9985330</a>
                              </strong>
                            </div>
                            <div v-else-if="showAlertMontaggioAniaGomme" class="v-alert alert--info">
                              {{$trans('checkout.alert-montaggio2')}}
                              <strong>
                                <a href="tel:0309985330" draggable="false">030-9985330</a>
                              </strong>
                            </div>

                            <p class="m-0 alert alert-danger font-weight-bold" style="width:100%">
                                Ricordiamo che le sedi di Brescia, Capriolo, Mantova, Busto Arsizio, Inzago, Lombardore, Voghera saranno chiuse dal 5 al 18 agosto.
                            </p>
<!--                            <p class="m-0 alert alert-danger font-weight-bold" style="width:100%" v-if="ritiro && ritiro !== 'K'">-->
<!--                              <i class="fas fa-exclamation"></i>-->
<!--                              {{ $trans('js-files.sede-chiusa') }}-->
<!--                            </p>-->
<!--                            <p class="m-0 alert alert-danger font-weight-bold" v-else-if="ritiro == 'K' && ritiroMsg">-->
<!--                              <i class="fas fa-exclamation"></i>-->
<!--                              {{ $trans('js-files.ricambio-sede-chiusa') }}-->
<!--                            </p>-->
                            <div
                              v-if="ritiroMsg"
                              class="alert alert-warning mb-0"
                            >
                              <strong>{{ ritiroMsg }}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      class="payment-methods__item"
                      id="corriere_cliente"
                      v-if="client_group.nome !== 'B2C' || client.id === 105892"
                    >
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                              class="input-radio__input collapsible-radio"
                              name="ritiro"
                              required
                              type="radio"
                              v-model="ritiro"
                              v-on:change="calcShipping"
                              value="SP-C"
                            />
                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title"
                          >{{$trans('checkout.corr-cliente')}}
                          <small
                            >({{$trans('checkout.tuo-carico')}})</small
                          ></span
                        >
                      </label>
                      <div class="payment-methods__item-container">
                        <div class="payment-methods__item-details text-muted">
                         {{$trans('checkout.costo-imb')}}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="card mb-4" v-if="ritiro === 'SP' || ritiro === 'SP-C'">
              <div class="card-body card-body--padding--2">
                <h3 class="card-title" id="indirizzo_spedizione" style="margin-bottom: 20px !important;">
                  {{$trans('checkout.dett-spedizione')}}<sup>*</sup>
                  <i
                    v-if="
                      showCloseAlert &&
                      !indirizzo_spedizione &&
                      (ritiro === 'SP' || ritiro === 'SP-C')
                    "
                    class="fas fa-exclamation-circle text-danger"
                  ></i>
                </h3>
                <div class="alert alert-warning mt-n10 d-flex" role="alert" style="align-items: center; margin-bottom: 20px !important;">
                  <i class="fas fa-exclamation-circle" style="color: #eaaf00; font-size: 20px;"></i>
                  <p class="mb-0 ml-3" style="font-size: 13px;">
                    {{ $trans('checkout.consegna', {'days': delivery_time}) }}
                  </p>
                </div>
                <address-list
                    :for_cart="for_cart"
                    :client="client"
                    :addresses="addresses"
                    @nazioneChanged="onNazioneChanged"
                    @newAddress="setNewAddress"
                    v-model="indirizzo_spedizione"
                />
<!--                <div class="addresses-list">-->
<!--                  <ul class="shipping-address__list">-->
<!--                    <li-->
<!--                      class="addresses-list__item card address-card"-->
<!--                      v-bind:class="{-->
<!--                        selected: indirizzo_spedizione === 'fatturazione',-->
<!--                        'from-single-order': !for_cart,-->
<!--                      }"-->
<!--                    >-->
<!--                      <label class="shipping-address__item-label">-->
<!--                        <span class="shipping-address__input input-radio">-->
<!--                          <span class="input-radio__body">-->
<!--                            <input-->
<!--                              class="input-radio__input"-->
<!--                              name="shipping-address"-->
<!--                              required-->
<!--                              type="radio"-->
<!--                              v-model="indirizzo_spedizione"-->
<!--                              value="fatturazione"-->
<!--                              @click="addressNazione = client.nazione"-->
<!--                            />-->
<!--                            <span class="input-radio__circle"></span>-->
<!--                          </span>-->
<!--                        </span>-->
<!--                        <span class="shipping-address__item-title"-->
<!--                          >{{$trans('checkout.sel-indirizzo')}}</span-->
<!--                        >-->
<!--                      </label>-->
<!--                      <div class="address-card__body">-->
<!--                        <div class="address-card__name">-->
<!--                          {{ client.ragione_soc }}-->
<!--                        </div>-->
<!--                        <div class="address-card__row">-->
<!--                          {{ client.indirizzo }}<br />-->
<!--                          {{ client.cap }}, {{ client.citta }} ({{-->
<!--                            client.provincia-->
<!--                          }}) <br>-->
<!--                          {{client.nazione}}-->
<!--                        </div>-->
<!--                        <div class="address-card__row" v-if="client.telefono">-->
<!--                          <div class="address-card__row-title">{{$trans('form-input.telefono')}}</div>-->
<!--                          <div class="address-card__row-content">-->
<!--                            {{ client.telefono }}-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </li>-->
<!--                    <li-->
<!--                      class="addresses-list__item card address-card"-->
<!--                      v-for="address in addresses"-->
<!--                      v-bind:class="{-->
<!--                        selected: indirizzo_spedizione === address.id,-->
<!--                        'from-single-order': !for_cart,-->
<!--                      }"-->
<!--                    >-->
<!--                      <label class="shipping-address__item-label">-->
<!--                        <span class="shipping-address__input input-radio">-->
<!--                          <span class="input-radio__body">-->
<!--                            <input-->
<!--                              :value="address.id"-->
<!--                              class="input-radio__input"-->
<!--                              name="shipping-address"-->
<!--                              required-->
<!--                              type="radio"-->
<!--                              v-model="indirizzo_spedizione"-->
<!--                              @click="addressNazione = address.nazione; shippingData(address) "-->
<!--                            />-->
<!--                            <span class="input-radio__circle"></span>-->
<!--                          </span>-->
<!--                        </span>-->
<!--                                                <span class="shipping-address__item-title"-->
<!--                                                >{{$trans('checkout.sel-indirizzo')}}</span-->
<!--                                                >-->
<!--                                            </label>-->
<!--                                            <div class="address-card__body">-->
<!--                                                <div class="address-card__name">-->
<!--                                                    {{ address.ragione_soc }}-->
<!--                                                </div>-->
<!--                                                <div class="address-card__row">-->
<!--                                                    {{ address.indirizzo }}<br/>-->
<!--                                                    {{ address.cap }}, {{ address.citta }} ({{-->
<!--                                                    address.provincia-->
<!--                                                    }}) <br>-->
<!--                                                  {{address.nazione}}-->
<!--                                                </div>-->
<!--                                                <div class="address-card__row" v-if="address.telefono">-->
<!--                                                    <div class="address-card__row-title">{{$trans('form-input.telefono')}}</div>-->
<!--                                                    <div class="address-card__row-content">-->
<!--                                                        {{ address.telefono }}-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                    <div class="addresses-list__item addresses-list__item&#45;&#45;new">-->
<!--                                        <button-->
<!--                                                class="btn btn-secondary btn-sm"-->
<!--                                                type="button"-->
<!--                                                v-on:click="showNewAddress = true"-->
<!--                                        >-->
<!--                                             {{$trans('checkout.aggiungi-nuovo')}}-->
<!--                                        </button>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="card-divider" v-show="showNewAddress"></div>-->
<!--                                <new-address-->
<!--                                        class="mt-3 mb-3"-->
<!--                                        v-show="showNewAddress"-->
<!--                                        :from-checkout="for_cart"-->
<!--                                        @newAddress="setNewAddress"-->
<!--                                ></new-address>-->
                                <div v-if="ritiro === 'SP'">
                                  <div class="card-divider"></div>
                                  <div class="form-group mt-3">
                                      <label for="checkout-comment"
                                      >{{$trans('checkout.note-ordine')}}
                                          <span class="text-muted">{{$trans('form-input.facoltativo')}}</span></label
                                      >
                                      <textarea
                                              class="form-control"
                                              id="checkout-comment"
                                              rows="2"
                                              v-model="note"
                                              maxlength="70"
                                      ></textarea>
                                  </div>
                                </div>
                            </div>
                </div>
              </div>

          <div
              class="col-12 mt-4 mt-lg-0"
              :class="{ 'col-lg-6 col-xl-5': !is_satellite.id }"
          >
            <div class="card mb-4" v-if="ritiro === 'SP' || ritiro === 'SP-C'">
              <div class="card-body card-body--padding--2">
                <h3 class="card-title" id="documento">
                  {{ $trans('cart.doc-fiscale') }}<sup>*</sup>
                </h3>
                <div
                  class="checkout__payment-methods payment-methods"
                  v-show="metodo_evasione"
                >
                  <ul class="payment-methods__list">
                    <li class="payment-methods__item">
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                              class="input-radio__input collapsible-radio"
                              name="documento"
                              required
                              type="radio"
                              v-model="documento"
                              :value="1"
                            />
                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title">{{$trans('checkout.fattura')}}</span>
                      </label>
                    </li>
                    <li class="payment-methods__item">
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                              class="input-radio__input collapsible-radio"
                              name="documento"
                              required
                              type="radio"
                              v-model="documento"
                              :value="7"
                            />
                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title"
                          >{{$trans('checkout.scontr-ddt')}}</span
                        >
                      </label>
                    </li>
                  </ul>
                  <div
                    class="form-group mt-3"
                    v-if="
                      documento === 1 && !client.p_iva && !client.cod_fiscale
                    "
                  >
                    <label for="checkout-cf">{{$trans('form-input.codice-fiscale')}}<sup>*</sup></label>
                    <input
                      type="text"
                      class="form-control"
                      id="checkout-cf"
                      v-model="$v.cod_fiscale.$model"
                    />
                  </div>
                  <div
                    class="form-row mt-3"
                    v-if="documento === 1 && client.p_iva"
                  >
                    <div class="form-group col-md-6" v-if="!client.pec">
                      <label for="checkout-pec">PEC<sup>*</sup></label>
                      <input
                        type="email"
                        class="form-control"
                        id="checkout-pec"
                        v-model="$v.pec.$model"
                      />
                    </div>
                    <div
                      class="form-group col-md-6"
                      v-if="!client.identificativo_fatt_elett"
                    >
                      <label for="checkout-sdi">{{$trans('form-input.cod-sdi')}}<sup>*</sup></label>
                      <input
                        type="text"
                        class="form-control"
                        id="checkout-sdi"
                        v-model="$v.identificativo_fatt_elett.$model"
                        maxlength="7"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-0">
              <div class="card-body card-body--padding--2">
                <!-- //TRADUZIONI da qui// -->
                <h3 class="card-title">{{ $trans('cart.riepilogo')}}</h3>
                <div
                  class="alert alert-warning mb-1 text-center text-uppercase"
                  v-if="genericTableItems.length > 0"
                >
                  {{ $trans('cart.componenti-verifica')}}
                </div>
                <table
                  class="checkout__totals mb-1"
                  v-if="genericTableItems.length > 0"
                >
                  <thead class="checkout__totals-header">
                    <tr>
                      <th>{{ $trans('cart.prodotti')}}</th>
                      <th>
                        <span>
                      {{ $trans('cart.prezzo-iva')}}
                          {{ is_business ? $trans('cart.esclusa') : $trans('cart.inclusa')  }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody class="checkout__totals-products">
                    <tr v-for="item in genericTableItems">
                      <td>
                        <em
                          >{{ item.dsMar }} {{ item.dsMod }}
                          {{ item.dsVer }}</em
                        >
                        <p class="m-0 pl-2" v-if="item.targa">
                          <strong>{{ $trans('cart.targa')}}: </strong
                          ><span class="text-uppercase">{{ item.targa }}</span>
                        </p>
                        <p class="m-0 pl-2" v-if="item.codice_motore">
                          <strong>{{ $trans('cart.cod-motore')}}: </strong>
                          {{ item.codice_motore }}
                        </p>
                        <p class="m-0 pl-2" v-if="item.anno_immatricolazione">
                          <strong>{{ $trans('cart.anno-imm')}}: </strong>
                          {{ item.anno_immatricolazione }}
                        </p>
                        <p class="m-0 pl-2" v-if="item.tag && item.tag.km"><em>
                          {{ $trans('common.chilometri')}}: {{ item.tag.km }}</em>
                        </p>
                        <strong
                          >{{ item.descr }}
                          <span v-if="item.lato"
                            >{{ $trans('cart.lato')}}
                            {{
                              item.lato === "S" ? $trans('common.sinistro') : $trans('common.destro')
                            }}</span
                          ></strong
                        >
                        <span class="notes" v-if="item.tag && (item.tag.note1 || item.tag.note2)">
                            {{item.tag.note1}}<br v-if="item.tag.note2">{{item.tag.note2}}
                        </span>
                      </td>
                      <td v-if="is_business">
                        {{
                          item.prezzo | removeVat(vat) | numeralFormat("0.00 $")
                        }}
                      </td>
                      <td v-else>
                        {{ item.prezzo | numeralFormat("0.00 $") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="alert alert-success mb-1 text-center text-uppercase"
                  v-if="genericTableItems.length > 0"
                >
                  {{ $trans('cart.componenti-disponibili')}}
                </div>
                <table class="checkout__totals">
                  <thead class="checkout__totals-header">
                    <tr>
                      <th>{{ $trans('cart.totale-netto')}}</th>
                      <th>
                       {{ $trans('cart.prezzo-iva')}}<br />
                       <span class='text-small'>
                          {{ is_business ? $trans('cart.esclusa') : $trans('cart.inclusa') }}
                          
                          {{ $trans('cart.iva')}}
                          .</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody class="checkout__totals-products">
                    <tr v-for="item in tableItems">
                      <td>
                        <div class="d-flex mb-2" style="align-items: center;">
                          <div class="form-check" v-if="!for_cart" style="height: 24px; cursor: pointer">
                            <span class="input-check form-check-input">
                              <span class="input-check__body">
                                <input
                                  class="input-check__input order_checks"
                                  type="checkbox"
                                  @change="selectForConfirmation(item)"
                                  :id="item.id"
                                  :checked="item.selected"
                                  :key="'{{item.id}}-{{item.selected}}'"
                                />
                                <span class="input-check__box"></span>
                                <span class="input-check__icon"
                                  ><svg
                                    height="7px"
                                    width="9px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </span>
                        </div>
                          <div v-if="item.images" class="mr-2" >
                        <picture
                            v-if="item.images.length"
                            @click="_openPhotoSwipe(item)"
                            style="cursor:pointer; height: 64px; min-width: 64px; margin-right: 12px;">
                              <source :data-srcset="item.images[0]+'_thumb.webp 1x, '+item.images[0]+'_thumb@2x.webp 2x'"
                                      type="image/webp">
                              <img :data-srcset="item.images[0]+'_thumb.jpg 1x, '+item.images[0]+'_thumb@2x.jpg'"
                                  class="lazyload"
                                  src="/images/placeholder-700x700-loading.png"
                                  style="object-fit: contain; width: 100%; height: 100%;">
                        </picture>
                        <picture v-else style="height: 64px; min-width: 64px; margin-right: 12px;">
                          <source data-srcset="/images/img-not-found.webp 1x, /images/img-not-found@2x.webp 2x"
                                  type="image/webp">
                          <img data-srcset="/images/img-not-found.jpg 1x, /images/img-not-found@2x.jpg"
                               class="lazyload"
                               src="/images/placeholder-700x700-loading.png"
                               style="object-fit: contain; width: 100%; height: 100%;">
                        </picture>
                            </div>
                        <div>
                          <em
                          >{{ item.dsMar }} {{ item.dsMod }}
                            {{ item.dsVer }}</em
                          >
                          <p class="m-0 pl-2" v-if="item.targa">
                            <em
                            >Targa:
                              <span class="text-uppercase">{{
                                  item.targa
                                }}</span></em
                            >
                          </p>
                          <p class="m-0 pl-2" v-if="item.codice_motore">
                            <em>{{ $trans('cart.cod-motore')}} {{ item.codice_motore }}</em>
                          </p>
                          <p
                              class="m-0 pl-2"
                              v-if="item.anno_immatricolazione"
                          >
                            <em
                            >{{$trans('cart.anno-imm')}}:
                              {{ item.anno_immatricolazione }}</em
                            >
                          </p>
                          <p class="m-0 pl-2" v-if="item.tag && item.tag.km"><em>
                            {{ $trans('common.chilometri')}}: {{ item.tag.km }}</em>
                          </p>
                          <strong
                          >{{ item.descr }}

                            <span v-if="item.lato"
                            >{{ $trans('cart.lato')}}
                                 {{
                                  item.lato === "S" ? $trans('common.sinistro') : $trans('common.destro')
                                }}</span
                            ></strong
                          >

                            <!-- tradurre -->
                          <span class="badge badge-danger" v-if="item.in_coppia"><i
                              class="fas fa-warning"></i> {{$trans('cart.acquistare-coppia')}}</span>

                          <span class="notes" v-if="item.tag && (item.tag.note1 || item.tag.note2)">
                            {{item.tag.note1}}<br v-if="item.tag.note2">{{item.tag.note2}}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td v-if="is_business">
                      <div
                          class="whole-price"
                          v-if="item.prezzo < item.listino"
                      >
                        {{
                          item.listino
                              | removeVat(vat)
                              | numeralFormat("0.00 $")
                        }}
                      </div>
                      {{
                        item.prezzo | removeVat(vat) | numeralFormat("0.00 $")
                      }}
                    </td>
                    <td v-else>
                      <div v-if="item.prezzo < item.listino">
                        {{ item.listino | numeralFormat("0.00 $") }}
                      </div>
                      {{ item.prezzo | numeralFormat("0.00 $") }}
                    </td>
                  </tr>
                  </tbody>
                </table>
<!-- tradurre -->
                <div class="mt-2 button-container m-0" v-if="inCoppiaInfo.length > 0">
                  <div class="alert alert-danger mb-0">
                    <strong>{{ $trans('checkout.attenzione-coppia') }}:</strong>
                    <ul style="padding: 5px 0 0 16px;">
                      <li v-for="item in inCoppiaInfo">
                        {{ item.descr }}
                      </li>
                    </ul>
                  </div>
                </div>

                <table class="checkout__totals">
                  <tbody class="checkout__totals-subtotals">
                  <tr>
                    <th>{{ $trans('cart.totale-netto')}}</th>
                    <td v-if="is_business">
                      {{
                        itemsTotal | removeVat(vat) | numeralFormat("0.00 $")
                      }}
                    </td>
                    <td v-else>{{ itemsTotal | numeralFormat("0.00 $") }}</td>
                  </tr>
                  <tr>
                    <th>
                      {{
                        ritiro === "SP-C"
                            ? $trans('cart.imballaggio')
                            : $trans('cart.spedizione-imballaggio')
                      }}
                    </th>
                    <td
                        id="shipping-cost"
                        v-bind:class="{ loading: shippingLoading }"
                    >
                        <span v-if="shipping !== null">
                          <span v-if="is_business">{{
                              shipping | removeVat(vat) | numeralFormat("0.00 $")
                            }}</span>
                          <span v-else>{{
                              shipping | numeralFormat("0.00 $")
                            }}</span>
                        </span>
                        <span v-else-if="!shippingLoading">{{ $trans('cart.da-calcolare')}}</span>
                      </td>
                    </tr>
                    <tr v-if="shipping > 0 && logoVettore">
                      <th>{{ $trans('cart.corriere') }}</th>
                      <td>
                        <img
                            :src="'https://logo.clearbit.com/' + logoVettore"
                            width="48"
                            style="border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;"
                        />
                      </td>
                    </tr>
                    <tr v-if="is_business">
                      <th>{{ $trans('cart.iva')}}</th>
                      <td>
                        {{
                          (itemsTotal + shipping)
                            | removeVat(vat)
                            | getTax(vat)
                            | numeralFormat("0.00 $")
                        }}
                      </td>
                    </tr>
                    <tr v-if="valoreVoucher">
                      <th>Voucher</th>
                      <td>
                        {{ valoreVoucher | numeralFormat("0.00 $") }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="checkout__totals-footer">
                    <tr>
                      <th>{{ $trans('cart.totale-ordine')}}</th>
                      <td>
                        {{ (itemsTotal + shipping) | numeralFormat("0.00 $") }}
                      </td>
                    </tr>
                    <tr v-if="has_generic && metodo_evasione">
                      <td colspan="2" style="padding: 0; text-align: left">
                        <div class="alert alert-warning mt-3">
                          <i class="fas fa-exclamation-circle"></i>
                          <span v-if="metodo_evasione === 'NOWAIT'"
                            >{{ $trans('cart.componenti-pagamento')}}</span
                          >
                          <span v-if="metodo_evasione === 'WAIT'"
                            >{{ $trans('cart.ordine-pagato')}}</span
                          >
                        </div>
                      </td>
                    </tr>
                    <tr v-if="metodo_evasione === 'WAIT'">
                      <th class="pt-3">{{$trans('cart.totale-da-pagare')}}</th>
                      <td class="pt-3">
                        {{
                          (metodo_evasione === "WAIT"
                            ? 0
                            : itemsAvailableTotal + shipping)
                            | numeralFormat("0.00 $")
                        }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <p class="mb-1">Hai un voucher promozionale? Inseriscilo qui</p>
                <Voucher :cart="cart" :order="order" @voucherApplied="voucherApplied" :show-label="false" />
                <div
                  class="checkout__payment-methods payment-methods"
                  v-if="!is_satellite.id && !isZonaFranca"
                >
                  <h3 id="tipo_pagamento" class="card-title">
                    {{ $trans('checkout.metodo-pagamento')}}<sup>*</sup>
                    <i
                      v-if="showCloseAlert && !tipo_pagamento"
                      class="fas fa-exclamation-circle text-danger"
                    ></i>
                  </h3>
                  <ul class="payment-methods__list">
                    <li
                        v-for="payment in payment_methods"
                        class="payment-methods__item"
                        :style="[payment.id == 21196 && (ritiro === 'SP' || ritiro === 'SP-C') ? {'border': '3px solid #28a745'} : {}]"
                        v-if="ritiro !== 'A'"
                    >
                      <!--                      v-if="ritiro !== 'E'"-->
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                                class="input-radio__input collapsible-radio"
                                name="checkout_payment_method"
                                required
                                type="radio"
                                v-model="tipo_pagamento"
                                :value="payment.id"
                            />
                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title"
                              :class="{'text-success': payment.id == 21196 && (ritiro === 'SP' || ritiro === 'SP-C')}">{{
                            payment.pagamento
                          }} <span class="badge badge-success"
                                   v-if="payment.id == 21196 && (ritiro === 'SP' || ritiro === 'SP-C')">
                                   
                                   <!-- tradurre -->
                                   <i
                              class="fas fa-star"></i> {{ $trans('checkout.prep-immediata') }}</span>
                                              <span class="badge badge-danger" v-else-if="payment.id == 2"><i
                                                  class="fas fa-warning"></i> {{ $trans('checkout.prep-giorni') }}</span></span>
                      </label>
                      <div class="payment-methods__item-container">
                        <div class="payment-methods__item-details text-muted">
                          <p class="text-danger mb-0" v-if="payment.id == 2">{{ $trans('checkout.merce-lavorata') }}</p>
                          <p class="text-success mb-0" v-else-if="payment.id == 21196">{{ $trans('checkout.merce-subito') }}</p>
                        </div>
                      </div>
                    </li>
                    <li
                        class="payment-methods__item"
                        style="border: 3px solid #28a745"
                        v-if="
                        ritiro &&
                        ritiro !== '' &&
                        ritiro !== 'SP' &&
                        ritiro !== 'SP-C'
                      "
                    >
                      <label class="payment-methods__item-header">
                        <span class="payment-methods__item-radio input-radio">
                          <span class="input-radio__body">
                            <input
                                class="input-radio__input collapsible-radio"
                                name="checkout_payment_method"
                                required
                                type="radio"
                                v-model="tipo_pagamento"
                                :value="3"
                            />
                            <span class="input-radio__circle"></span>
                          </span>
                        </span>
                        <span class="payment-methods__item-title text-success"
                        >{{ $trans('cart.paga-cassa')}} <span class="badge badge-success"><i
                            class="fas fa-star"></i> 

                            {{ $trans('checkout.consigliato') }}</span></span>
                      </label>
                      <div class="payment-methods__item-container">
                        <div class="payment-methods__item-details text-muted">
                          {{ $trans('cart.paga-ritiro')}}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="checkout__agree form-group mb-3">
                  <div class="form-check">
                    <span class="input-check form-check-input">
                      <span class="input-check__body">
                        <input
                            class="input-check__input"
                            id="checkout-terms"
                            required
                            type="checkbox"
                            v-model="terms"
                        />
                        <span class="input-check__box" style="border:2px solid #cf2929"></span>
                        <span class="input-check__icon"
                        ><svg
                            height="7px"
                            width="9px"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                    <label class="form-check-label" for="checkout-terms">
                     {{$trans('cart.ho-letto')}}
                      <a :href="terms_path" target="_blank"
                        >{{$trans('cart.termini-condizioni')}}<sup>*</sup></a
                      >
                      <i
                        v-if="showCloseAlert && !terms"
                        class="fas fa-exclamation-circle text-danger"
                      ></i>
                    </label>
                  </div>
                </div>

                <div class="checkout__agree form-group mb-3">
                  <div class="form-check">
                    <span class="input-check form-check-input">
                      <span class="input-check__body">
                        <input
                            class="input-check__input"
                            id="privacy-policy"
                            required
                            type="checkbox"
                            v-model="privacy"
                        />
                        <span class="input-check__box" style="border:2px solid #cf2929"></span>
                        <span class="input-check__icon"
                        ><svg
                            height="7px"
                            width="9px"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                    <label class="form-check-label" for="privacy-policy">
                      {{$trans('cart.dichiarazione')}} <a
                        :href="policy_path"
                        target="_blank"
                        >Privacy Policy<sup>*</sup></a
                      >
                      <i
                        v-if="showCloseAlert && !terms"
                        class="fas fa-exclamation-circle text-danger"
                      ></i>
                    </label>
                  </div>
                </div>

                <div class="alert alert-danger" v-if="errors">
                  <div class="mb-0" v-for="error in errors">
                    <p
                        class="mb-2"
                        v-for="e in error"
                        v-if="Array.isArray(error)"
                    >
                      {{ e }}
                    </p>
                    <p class="mb-2" v-if="!Array.isArray(error)">{{ error }}</p>
                  </div>
                </div>

                <div class="alert alert-danger" v-if="showCloseAlert">
                  <ul class="m-0 pl-3">
                    <li v-for="error in formErrors" v-html="error"></li>
                  </ul>
                </div>

                <div
                    class="alert alert-warning"
                    v-if="
                    tipo_pagamento === 1 &&
                    shippingLoading &&
                    metodo_evasione &&
                    ritiro &&
                    terms &&
                    privacy
                  "
                >
                {{ $trans('cart.attendi')}}
                </div>


                <div
                    v-if="itemsAvailableTotal + shipping >= 30"
                    data-pp-message
                    data-pp-style-layout="text"
                    data-pp-style-logo="inline"
                    data-pp-placement="cart"
                    data-pp-style-logo-position="top"
                    data-pp-style-text-color="white"
                    style="background-color:#0e3383;padding-left:12px;padding-top:12px"
                    class="mb-3"
                    :data-pp-amount="itemsAvailableTotal + shipping">
                </div>

                <button
                    v-if="!isZonaFranca"
                  class="btn btn-primary btn-xl btn-block"
                  type="submit"
                  v-bind:class="{ 'btn-loading': closeLoading }"
                    v-bind:disabled="
                    shippingLoading ||
                    buttonForceDisable ||
                    closeLoading ||
                    ((ritiro === 'SP' || ritiro === 'SP-C') && !shipping)
                  "
                >
                  <span v-if="only_generic || metodo_evasione === 'WAIT'"
                    >{{$trans('cart.invia-verifica')}}</span
                  >
                  <span v-else-if="has_generic"
                    >{{$trans('cart.procedi-invia')}}</span
                  >
                  <span v-else> {{$trans('cart.procedi-acquisto')}}</span>
                </button>

                <button
                    class="btn btn-primary btn-xl btn-block"
                    v-bind:class="{ 'btn-loading': closeLoading }"
                    v-else
                    type="submit">
                  {{ $trans('checkout.conferma-richiesta') }}
                </button>

<!--                <div-->
<!--                  id="paypal-button-container"-->
<!--                  style="height: 39.9px; overflow: hidden"-->
<!--                  v-show="-->
<!--                    tipo_pagamento === 1 &&-->
<!--                    metodo_evasione !== 'WAIT' &&-->
<!--                    for_cart &&-->
<!--                    (ritiro === 'SP' || ritiro === 'SP-C' ? shipping : true)-->
<!--                  "-->
<!--                ></div>-->
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="modal fade" id="mapModal" tabindex="-1" role="dialog" aria-labelledby="mapModalTitle" aria-hidden="true" v-if="infoSede">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">{{infoSede.sedeTitle}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{infoSede.sedeAddress}}
              <iframe :src="infoSede.realMap" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <p class="lower-btn"><a target="_blank" :href="infoSede.road"><span>▶</span> {{ $trans("store-finder.naviga") }}</a></p>
            </div>
            <div class="modal-footer">
              <button @click="infoSede.realMap = ''" type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.addresses-list__item.from-single-order {
  width: 100% !important;
}
.shipping-address__item-label{
  cursor: pointer !important;
}
.notes{
  background-color: #fbfbfb;
  border: 1px dotted #e8e8e8;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 87.5%;
  color: #212529;
  width: 100%;
  display: block;
}
</style>
<script>
import axios from "axios";
import NewAddress from "./new-address.vue";
import {normalizeComponents, onCheckout, onCompleteOrder,} from "../tag-manager/gtm";
import {checkFiscalCode, CustomSwal, getSediRitiro} from "../utils";
import {hideFullScreenLoader, showFullScreenLoader,} from "../full-screen-loader-functions";
import {validationMixin} from "vuelidate";
import {alphaNum, email, helpers, maxLength, minLength,} from "vuelidate/lib/validators";
import trans from "../i18n";
import AddressList from "./AddressList.vue";
import Voucher from "./Voucher.vue";

export default {
  name: "checkout",
  mixins: [validationMixin],
  components: {
    AddressList,
    NewAddress,
    Voucher,
  },
  props: {
    logged: String,
    items_list: Array,
    vat: Number,
    addresses: Array,
    client: Object,
    has_generic: Boolean,
    only_generic: Boolean,
    is_business: {
      required: false,
      type: Number,
      default: 0,
    },
    payment_methods: Array,
    terms_path: String,
    policy_path: String,
    for_cart: {
      required: false,
      type: Boolean,
      default: true,
    },
    order: Object,
    infoSedi: Object,
    is_satellite: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
    client_group: Object,
    order_confirmed_path: String,
    cart: Object,
  },
  data() {
    return {
      shipping_calc: null,
      isZonaFranca: false,
      metodo_evasione: null,
      ritiro: null,
      shipping_net: 0,
      packaging: 0,
      shipping: null,
      shippingLoading: false,
      ritiroSede: false,
      indirizzo_spedizione: null,
      errors: null,
      closeLoading: false,
      tipo_pagamento: null,
      terms: false,
      privacy: false,
      showNewAddress: false,
      buttonForceDisable: false,
      showCloseAlert: false,
      formErrors: [],
      ritiroMsg: null,
      documento: null,
      cod_fiscale: "",
      pec: "",
      identificativo_fatt_elett: "",
      initial_indirizzo_spedizione: null,
      initial_shipping_net: 0,
      initial_packaging: 0,
      hidePaginaForError: false,
      items: [],
      note: '',
      inCoppia: [],
      isInCoppia: false,
      inCoppiaInfo: [],
      addressNazione: this.client.nazione,
      isItalia: true,
      shippingDataSped: [],
      delivery_time: "2/3",
      locale: document.querySelector('html').getAttribute('lang'),
      dhlProductCode: '',
      infoSede: null,
      vettore: null,
      logoVettore: '',
      sedi: [],
      valoreVoucher: null,
    };
  },
  validations: {
    cod_fiscale: {
      validCF: (value) =>
          !helpers.req(value) || checkFiscalCode(value) !== false,
    },
    pec: {
      email,
    },
    identificativo_fatt_elett: {
      minLength: minLength(7),
      maxLength: maxLength(7),
      alphaNum,
    },
  },
  created() {
    this.$set(this, "items", this.items_list);
  },
  mounted() {
    axios.get('/api/v1/sedi').then(res => {
      this.sedi = getSediRitiro(res.data.data[0]);
      console.log('cimone', this.sedi);
    });

    console.log(this.addresses);
    if (localStorage.getItem("order_checks")) {
      let localCheckedArray = JSON.parse(localStorage.getItem('order_checks'));
      if(localCheckedArray.length) {
        [].forEach.call(document.querySelectorAll(".input-check__input.order_checks"), function (element) {
          if( !localCheckedArray.includes( parseInt(element.getAttribute('id')) ) ){
            element.click();
          }
        }, localCheckedArray);
      }
    }
    // Analytics
    setTimeout(() => {
      onCheckout(normalizeComponents(this.items, "cart"));
    }, 1500);

    this.items.forEach((el) => {
      if (el.in_coppia) {
        this.inCoppia.push(el.ania);
      }
    })

    let vue = this;
    // paypal
    //     .Buttons({
    //       onClick: function (data, actions) {
    //         if (vue.closeCart() === false) {
    //           return actions.reject();
    //         } else {
    //           return actions.resolve();
    //         }
    //       },
    //       createOrder: function () {
    //         return axios
    //             .post("/api/v1/paypal/create-order", {
    //               checkout: 1,
    //               ritiro: vue.ritiro,
    //               speseSpedizione: vue.shipping_net,
    //               speseImballaggio: vue.packaging,
    //               indirizzo_spedizione: vue.indirizzo_spedizione,
    //               metodo_evasione: vue.metodo_evasione,
    //             })
    //             .then((res) => res.data.id)
    //             .catch((error) => {
    //               CustomSwal({
    //                 title: "Errore",
    //                 text: error.response.data.errors.join("<br>"),
    //                 confirmButtonText: "Ok",
    //                 icon: "error",
    //               });
    //             });
    //       },
    //       onApprove: function (data, actions) {
    //         showFullScreenLoader();
    //         actions.order.authorize().then(function (authorization) {
    //           const authorizationID =
    //               authorization.purchase_units[0].payments.authorizations[0].id;
    //
    //           return axios
    //               .post("/api/v1/paypal/transaction-complete", {
    //                 orderID: data.orderID,
    //                 authorizationID: authorizationID,
    //                 checkout: 1,
    //               })
    //               .then((res) => {
    //                 vue.createOrder(authorizationID);
    //               })
    //               .catch((error) => {
    //                 hideFullScreenLoader();
    //                 CustomSwal({
    //                   title: "Errore",
    //                   text: error.response.data.errors.join("<br>"),
    //                   confirmButtonText: "Ok",
    //                   icon: "error",
    //                 });
    //                 return actions.restart();
    //               });
    //         });
    //       },
    //     })
    //     .render("#paypal-button-container");

    // Controllo i cookie e reimposto i dati
    this.metodo_evasione = $cookies.get("Evasione");
    if ($cookies.get('Ritiro') === 'SP' || $cookies.get('Ritiro') === 'SP-C') {
      this.ritiro = $cookies.get("Ritiro");
    } else if ($cookies.get('Ritiro')) {
      document.addEventListener('DOMContentLoaded', function () {
        $("#ritiro_sede input").trigger("click");
        $('#ritiro_sede').addClass('payment-methods__item--active');
        vue.ritiro = $cookies.get('Ritiro');
      });
    }
    if (this.for_cart) {
      this.checkInCoppia();
      this.indirizzo_spedizione = $cookies.get("Indirizzo")
          ? parseInt($cookies.get("Indirizzo"))
          : "fatturazione";
    }

    if (this.order) {
      // this.items.forEach(el => {
      //     el.selected = true;
      // });

      this.documento = this.order.tipo_documento;

      // if (!this.payment_methods.find((val) => {
      //     return val.id == this.order.payment_method.id
      // })) {
      //     // if(this.order.payment_method.id != 3) {
      //     //     this.payment_methods.push(this.order.payment_method);
      //     // }
      // }

      this.tipo_pagamento = this.order.payment_method.id;

      if (this.order.indirizzoSpedizione) {
        // Spedizione

        setTimeout(() => {
          if (this.order.porto == "PF") {
            // this.ritiro = 'SP';
            $("#corriere_espresso input").trigger("click");
          } else {
            // this.ritiro = 'SP-C';
            $("#corriere_cliente input").trigger("click");
          }

          this.initial_indirizzo_spedizione = this.order.indirizzo_spedizione;
          this.indirizzo_spedizione = this.order.indirizzo_spedizione;

          this.shipping_net = this.order.speseSpedizione;
          this.packaging = this.order.speseImballaggio;

          this.initial_shipping_net = this.order.speseSpedizione;
          this.initial_packaging = this.order.speseImballaggio;

          // this.indirizzo_spedizione = indirizzo.id;

          // this.waitCalcShipping = false;
        }, 150);
      } else if (this.order.sedeRitiro) {
        // Ritiro
        setTimeout(() => {
          $("#ritiro_sede input").trigger("click");
          this.ritiro = this.order.sedeRitiro;
        }, 300);
      }
    }

    // let uri = window.location.search.substring(1);
    // let params = new URLSearchParams(uri);
    // let sede = params.get("sede");
    //
    // if (sede) {
    //   // Ritiro
    //   document.addEventListener('DOMContentLoaded', function () {
    //     $("#ritiro_sede input").trigger("click");
    //     $('#ritiro_sede').addClass('payment-methods__item--active');
    //     vue.ritiro = sede;
    //   });
    // }


    if (!this.has_generic) {
      this.metodo_evasione = "NOWAIT";
    }

    if (this.only_generic) {
      this.metodo_evasione = "WAIT";
    }

    this.isItalia = this.client.nazione.toLowerCase().startsWith('it') || !this.client.nazione || this.client.nazione === '-';
  },
  computed: {
    showAlertMontaggio: function () {
      console.log('asd', document.querySelectorAll('input[name^="order_check"]'), this.items_list)
      return this.ritiro === "G" && this.items_list.find(r => {
        return r.isGomma === true;
      });
    },
    showAlertMontaggioAniaGomme: function () {
      console.log('asd', document.querySelectorAll('input[name^="order_check"]'), this.items_list)
      return this.ritiro !== "G" && this.items_list.find(r => {
        return r.isGomma === true;
      });
    },
    itemsTotal: function () {
      const me = this;
      return this.items.reduce(function (prev, curr) {
        if (
            (me.metodo_evasione === "NOWAIT" &&
                curr.cartellino == 0 &&
                me.for_cart) ||
            (!me.for_cart && !curr.selected)
        )
          return prev;
        return prev + curr.prezzo;
      }, 0);
    },
    itemsAvailableTotal: function () {
      var me = this;
      return this.items.reduce(function (prev, curr) {
        return prev + (curr.cartellino != "0" || me.for_cart ? curr.prezzo : 0);
      }, 0);
    },

    tableItems: function () {
      if (this.metodo_evasione === "WAIT" || !this.for_cart) return this.items;
      return this.items.filter((el) => el.cartellino != "0");
    },
    genericTableItems: function () {
      if (this.metodo_evasione === "WAIT" || !this.for_cart) return [];
      return this.items.filter((el) => el.cartellino == "0");
    }
  },
  methods: {
    _openPhotoSwipe(item){
      window.openPhotoSwipe(...item.images.map(img => img + ".jpg"));
    },
    shippingData(address){
      !address.nazione.toLowerCase().startsWith('it') ? this.shippingDataSped = address : this.shippingDataSped = [];
    },
    setDetails(sede) {
      this.infoSede = this.infoSedi[sede];
      // switch(sede) {
      //   case 'PB':
      //     this.sedeTitle = 'Pollini Bedizzole (BS)';
      //     this.sedeAddress = 'Via Gavardina n° 30, 25081 Bedizzole (BS)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11188.181918972035!2d10.3919027!3d45.4890289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47819a473bd1513d%3A0x7c2c581e69e509c!2sAutodemolizione%20Pollini%20Bedizzole!5e0!3m2!1sit!2sit!4v1683635932332!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.4890289%2C10.3919027';
      //     break;
      //   case 'PC':
      //     this.sedeTitle = 'Pollini Capriolo (BS)';
      //     this.sedeAddress = 'Viale Lombarda n° 92, 25031 Capriolo (BS)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11161.892815197965!2d9.9204147!3d45.6212294!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478167dee6bc8589%3A0xf4f04696c946c3d3!2sAutodemolizione%20Pollini%20Capriolo!5e0!3m2!1sit!2sit!4v1683637352980!5m2!1sit!2sit'
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.6212294%2C9.9204147';
      //     break;
      //   case 'PBS':
      //     this.sedeTitle = 'Pollini Brescia';
      //     this.sedeAddress = 'Via Del Mella n° 44/H/G, 25131 Brescia (BS)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11185.144009050971!2d10.160003!3d45.504321!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47817443ea760b97%3A0xdfd912db44685b29!2sAutodemolizione%20Pollini%20Brescia%20Via%20del%20Mella!5e0!3m2!1sit!2sit!4v1683637896575!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.504321%2C10.160003';
      //     break;
      //   case 'PBC':
      //     this.sedeTitle = 'Pollini Brescia Centro';
      //     this.sedeAddress = 'Via Borgosatollo n° 38, 25124 Brescia (BS)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11184.149032161356!2d10.2326134!3d45.5093286!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478175d0e470a845%3A0x2d4a9f97321be021!2sAutodemolizione%20Pollini%20Brescia%20Centro!5e0!3m2!1sit!2sit!4v1683638188591!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.5093286%2C10.2326134';
      //     break;
      //   case 'PM':
      //     this.sedeTitle = 'Pollini Mantova';
      //     this.sedeAddress = 'Via Primo Maggio n° 14, San Martino Dall\'Argine (MN)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11264.158315721399!2d10.5249687!3d45.1052657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478034dbaae6ac57%3A0x760412a69bd90c93!2sAutodemolizione%20Pollini%20San%20Martino%20d%2FA!5e0!3m2!1sit!2sit!4v1683638247623!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.1052657%2C10.5249687';
      //     break;
      //   case 'PV':
      //     this.sedeTitle = 'Pollini Busto Arsizio (VA)';
      //     this.sedeAddress = 'Strada Comunale di Arconate n° 31, 21052 Busto Arsizio (VA)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11171.059814287724!2d8.8361193!3d45.5751651!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47868b5b38cddef3%3A0xc2ad0e24684c6f84!2sAutodemolizione%20Pollini%20Busto!5e0!3m2!1sit!2sit!4v1683638292158!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.5751651%2C8.8361193';
      //     break;
      //   case 'PT':
      //     this.sedeTitle = 'Autodemolizione Autore, Settimo Torinese (TO)';
      //     this.sedeAddress = 'Via Sicilia, 2, 10036 Settimo Torinese (TO)';
      //     this.realMap = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11253.79936464416!2d7.7674056!3d45.1577399!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4788712da8082889%3A0x46f63a8ee6b62f77!2sAutodemolizione%20Autore!5e0!3m2!1sit!2sit!4v1683638321292!5m2!1sit!2sit';
      //     this.roadTo = 'https://www.google.com/maps/dir/?api=1&destination=45.1577399%2C7.7674056';
      //     break;
      // }
    },
    checkInCoppia() {
      //Conto le occorrenze di ogni ania che deve essere in coppia
      var counts = {};
      for (let num of this.inCoppia) {
        counts[num] = counts[num] ? counts[num] + 1 : 1;
      }
      console.log('incoppia', this.inCoppia)
      this.inCoppia.forEach((el) => {
        //Se un ania ha una sola occorrenza lo pusho nell'array di elementi
        // che devono essere acquistati in coppia e verrà fatto visualizzare all'utente
        if (counts[el] % 2 != 0) {
          this.isInCoppia = true;
          CustomSwal({
            title: trans('form-input.attenzione'),
            html: trans('cart.coppia-redirect'),
            confirmButtonText: trans('cart.homepage'),
            icon: "error",
            allowOutsideClick: false,
          }).then(function (result) {
            window.location = '/';
          })

        }
      });
    },
    setNewAddress: function (event) {
      console.log('hhh', event);
      $cookies.set("Indirizzo", event.data.data[0].id, "1h", `/${this.locale}/checkout`);
      window.location.reload();
      // this.addresses.push(event.data.data[0]);
      // this.indirizzo_spedizione = event.data.data[0].id;
      // this.showNewAddress = false;
    },
    calcShipping: function () {
      this.isZonaFranca = false;
      this.ritiroSede = false;
      this.shipping = null;
      if (
          !this.indirizzo_spedizione ||
          (this.ritiro !== "SP" && this.ritiro !== "SP-C")
      ) {
        return;
      }
      this.shippingLoading = true;

      // Recupero cap indirizzo
      let city, cap;
      if (this.indirizzo_spedizione === "fatturazione") {
        city = this.client.citta;
        cap = this.client.cap;
      } else {
        const filtered = this.addresses.filter((el) => {
          return el.id === this.indirizzo_spedizione;
        });
        if (filtered.length > 0) {
          city = filtered[0].citta;
          cap = filtered[0].cap;
        } else {
          this.indirizzo_spedizione = null;
          return;
        }
      }

      if (this.indirizzo_spedizione == this.initial_indirizzo_spedizione) {
        this.shipping_net = this.ritiro !== "SP-C"
            ? this.initial_shipping_net
            : 0;
        this.packaging = this.initial_packaging;
        this.shipping = this.shipping_net + this.packaging;
        this.shippingLoading = false;
      } else {
        axios
            .get(
                "/api/v1/cart/calc-shipping?city=" + city +
                "&cap=" + cap +
                "&metodo_evasione=" + this.metodo_evasione +
                "&tipo_spedizione=" + this.ritiro +
                "&order_id=" + (this.order ? this.order.id : "") +
                "&nazione=" + this.addressNazione

            )
            .then((response) => {
              this.shipping_calc = response.data.data;
              console.log(this.shipping_calc);
              if (response.data.success) {
                if (this.metodo_evasione === "NOWAIT") {
                  this.shipping_net = this.shipping_calc.shipping;
                  this.packaging = this.shipping_calc.packaging;
                }
                this.shipping = this.shipping_calc.shipping + this.shipping_calc.packaging;
                this.delivery_time = this.shipping_calc.delivery_time;
                this.isZonaFranca = this.shipping_calc.zona_franca;
                this.dhlProductCode = this.shipping_calc ? this.shipping_calc.dhl_productcode : '';
                this.vettore = this.shipping_calc.vettore;
                this.logoVettore = this.shipping_calc.logo;
              }
            })
            .catch((error) => {
              this.isZonaFranca = !!error.response.data.zona_franca;
              console.log(this.isZonaFranca)
              CustomSwal({
                html: "<i class=\'fas fa-shipping-fast fa-6x\' style=\'color:#c71a1f\'></i> <br><br>" + error.response.data.errors.join("<br><br>"),
                confirmButtonText: "Ok",
              }).then(() => {
              });
            })
            .finally(() => {
              this.shippingLoading = false;
              console.log(this.isZonaFranca)
            });
      }
    },
    setRitiroSede: function () {
      this.shipping = 0;
      this.ritiro = this.for_cart
          ? null
          : this.order.sedeRitiro
              ? this.order.sedeRitiro
              : null;
    },
    closeCart: function () {
      this.formErrors = [];
      this.showCloseAlert = false;

      if (
          !this.metodo_evasione ||
          !this.ritiro ||
          !this.terms ||
          !this.privacy ||
          (!this.tipo_pagamento && this.isItalia && !this.isZonaFranca)
      ) {
        this.showCloseAlert = true;
        console.log('1');
        if (!this.metodo_evasione) {
          this.formErrors.push(
            '<a href="#metodo_evasione">' +
              this.$trans("cart.modalita-prodotti") +
              "</a>"
          );
        }
        if (!this.ritiro) {
          console.log('2');
          this.formErrors.push(
            '<a href="#ritiro">' + this.$trans("cart.seleziona-ritiro") + "</a>"
          );
        }
        if (!this.terms) {
          console.log('3');
          this.formErrors.push(
            '<a href="#checkout-terms">' +
              this.$trans("cart.accetta-condizioni") +
              "</a>"
          );
        }
        if (!this.privacy) {
          console.log('4');
          this.formErrors.push(
            '<a href="#privacy-policy">' +
              this.$trans("cart.conferma-lettura") +
              "</a>"
          );
        }
        if (this.isItalia && !this.tipo_pagamento) {
          console.log('5');
          this.formErrors.push(
            '<a href="#tipo_pagamento">' +
              this.$trans("cart.seleziona-metodo") +
              "</a>"
          );
        }
        return false;
      }

      if ((this.ritiro === "SP" || this.ritiro === "SP-C") && !this.documento) {
        this.showCloseAlert = true;
        console.log('6');
        this.formErrors.push(
          '<a href="#documento">' + this.$trans("cart.doc-fiscale") + "</a>"
        );
        return false;
      }

      if (
          this.documento === 1 &&
          !this.client.p_iva &&
          !this.client.cod_fiscale
      ) {
        if (!this.cod_fiscale) {
          console.log('7');
          this.showCloseAlert = true;
          this.formErrors.push(
            '<a href="#documento">' +
              this.$trans("cart.cod-fisc-obbligatorio") +
              "</a>"
          );
          return false;
        } else if (this.cod_fiscale.validCF) {
          console.log('8');
          this.showCloseAlert = true;
          this.formErrors.push(
            '<a href="#documento">' + this.$trans("cart.cod-invalid") + "</a>"
          );
          return false;
        }
      }

      if (this.documento === 1 && this.client.p_iva) {
        if (!this.client.pec) {
          console.log('9');
          if (!this.pec) {
            this.showCloseAlert = true;
            this.formErrors.push(
              '<a href="#documento">' + this.$trans("cart.pec") + "</a>"
            );
            return false;
          } else if (!this.$v.pec.email) {
            console.log('10');
            this.showCloseAlert = true;
            this.formErrors.push(
              '<a href="#documento">' +
                this.$trans("cart.indirizzo-pec") +
                "</a>"
            );
            return false;
          }
        }
        if (!this.client.identificativo_fatt_elett) {
          if (!this.identificativo_fatt_elett) {
            console.log('11');
            this.showCloseAlert = true;
            this.formErrors.push(
              '<a href="#documento">' +
                this.$trans("cart.codice-univoco") +
                "</a>"
            );
            return false;
          } else if (this.$v.identificativo_fatt_elett.$error) {
            console.log('12');
            this.showCloseAlert = true;
            this.formErrors.push(
              '<a href="#documento">' + this.$trans("cart.sdi") + "</a>"
            );
            return false;
          }
        }
      }

      this.closeLoading = true;
      this.errors = null;
      
      // if (this.tipo_pagamento !== 1 || !this.for_cart) {
        this.createOrder(null);
    },
    createOrder: function (authorizationID) {
      // console.log('QUA', this.ritiroSede, this.ritiro, this.for_cart);return;
      if(this.for_cart && this.ritiro === 'SP') {
        if (this.indirizzo_spedizione === "fatturazione") {
          this.shippingDataSped = {
            id: null,
            id_cliente: this.client.id,
            cap: this.client.cap,
            cellulare: this.client.cellulare,
            citta: this.client.citta,
            indirizzo: this.client.indirizzo,
            nazione: this.client.nazione,
            provincia: this.client.provincia,
            ragione_soc: this.client.ragione_soc,
            telefono: this.client.telefono,
          }
        } else {
          this.shippingDataSped = this.addresses.find(r => r.id == this.indirizzo_spedizione);
        }
      }
      // console.log(this.indirizzo_spedizione, this.addresses, this.shippingDataSped);return;
      // console.log('is', this.indirizzo_spedizione, this.addressNazione, this.isItalia);return;
      if (this.for_cart) {
        axios
            .post("/api/v1/cart/close", {
              clienteid: this.client.id,
              ritiro: this.ritiro,
              metodo_evasione: this.metodo_evasione,
              tipo_pagamento: this.tipo_pagamento,
              speseSpedizione: this.shipping_net,
              speseImballaggio: this.packaging,
              indirizzo_spedizione: this.indirizzo_spedizione,
              authorizationID: authorizationID,
              documento: this.documento,
              cod_fiscale: this.cod_fiscale,
              pec: this.pec,
              identificativo_fatt_elett: this.identificativo_fatt_elett,
              note: this.note,
              nazione: this.addressNazione,
              shippingData: this.shippingDataSped,
              zonaFranca: this.isZonaFranca,
              dhl_productcode: this.dhlProductCode || '',
              vettore: this.vettore
                  ? this.vettore
                  : (this.shipping_calc && this.shipping_calc.hasOwnProperty('vettore')
                    ? this.shipping_calc.vettore
                    : '')
            })
            .then((res) => {
              if (res.status === 200) {
                CustomSwal({
                  title: this.$trans('checkout.richiesta-inviata'),
                  text: this.$trans('checkout.grazie-richiesta'),
                  confirmButtonText: "Ok",
                  icon: "success",
                }).then(() => {
                  window.location.href = res.data.data;
                });

              } else {
                onCompleteOrder(res.data.data, this.order_confirmed_path);
              }
            })
            .catch((error) => {
              if (error.response.data.code === 404) {
                this.buttonForceDisable = true;
                CustomSwal({
                  title: "Errore",
                  html: error.response.data.errors.join("<br><br>"),
                  confirmButtonText: "Ok",
                  icon: "error",
                }).then(() => {
                  location.reload();
                });
              } else if (error.response.data.code === 422) {
                // tradurre
                CustomSwal({
                  title: "Errore",
                  html: error.response.data.errors.join("<br><br>"),
                  confirmButtonText: "Torna alla Homepage",
                  icon: "error",
                }).then(() => {
                  window.location.href = '/';
                });
              } else {
                this.errors = error.response.data.errors;
              }
            })
            .finally(() => {
              this.closeLoading = false;
            });
      } else {
        axios
            .post("/api/v1/order/" + this.order.id + "/confirm", {
              for_confirmation: this.items.filter(x => x.selected).map(x => x.id),
              clienteid: this.client.id,
              ritiro: this.ritiro,
              metodo_evasione: this.metodo_evasione,
              tipo_pagamento: this.tipo_pagamento,
              speseSpedizione: this.shipping_net,
              speseImballaggio: this.packaging,
              indirizzo_spedizione: this.indirizzo_spedizione,
              documento: this.documento,
              cod_fiscale: this.cod_fiscale,
              pec: this.pec,
              identificativo_fatt_elett: this.identificativo_fatt_elett,
              note: this.note,
              dhl_productcode: this.dhlProductCode || '',
              vettore: this.shipping_calc && this.shipping_calc.hasOwnProperty('vettore')
                ? this.shipping_calc.vettore
                : '',
            })
            .then((res) => {
              location.reload();
            })
            .catch((error) => {
              if (error.response.data.code === 404) {
                // this.buttonForceDisable = true;
                CustomSwal({
                  title: "Errore",
                  html: error.response.data.errors.join("<br><br>"),
                  confirmButtonText: "Ok",
                  icon: "error",
                });
              } else {
                this.errors = error.response.data.errors;
              }
            })
            .finally(() => {
              this.closeLoading = false;
            });
      }
    },
    selectForConfirmation: function (item) {
      const row = this.items.findIndex((el) => el.id === item.id);

      this.$set(this.items[row], "selected", !item.selected);

      this.inCoppiaInfo = [];

      const aniaSums = this.items.filter(el => el.selected && el.in_coppiain_coppia)
          .reduce((sums, entry) => {
            if (!sums[entry.ania]) {
              sums[entry.ania] = {
                count: 0,
                items: []
              };
            }
            sums[entry.ania].count = parseInt(sums[entry.ania].count) + 1;
            sums[entry.ania].items.push(entry);
            return sums;
          }, {});

      for (const key in aniaSums) {
        if (aniaSums.hasOwnProperty(key)) {
          if (aniaSums[key].count % 2 !== 0) {
            this.inCoppiaInfo.push(aniaSums[key].items[0]);
          }
        }
      }

      this.$forceUpdate();
      if (this.items.filter((x) => x.selected).length == 0) {
        this.buttonForceDisable = true;
      } else {
        this.buttonForceDisable = false;
      }
    },
    onNazioneChanged: function(address) {
      this.addressNazione = address.nazione;
      this.shippingData(address);
    },
    voucherApplied(voucher) {
      this.valoreVoucher = voucher * -1;
      if (!this.order.voucher) {
        window.location.reload();
      }
    }
  },
  watch: {
    addressNazione: function(){
     this.isItalia = this.addressNazione.toLowerCase().startsWith('it') || !this.addressNazione || this.addressNazione === '-';
    },
    metodo_evasione: function (newValue) {
      if (this.for_cart == true) {
        $cookies.set("Evasione", newValue, "1h", `/${this.locale}/checkout`);
        this.calcShipping();
      }
    },
    ritiro: function (newValue) {
      $cookies.set("Ritiro", newValue, "1h", `/${this.locale}/checkout`);

      this.ritiroMsg = null;
      if (
          newValue &&
          this.metodo_evasione === "NOWAIT" &&
          newValue !== "SP" &&
          newValue !== "SP-C"
      ) {
        if (this.items.filter((el) => el.magazzino !== newValue).length > 0) {
          if (new Date().getHours() >= 17) {
            this.ritiroMsg =
                trans("js-files.prod-non-disponibili");
          } else {
            this.ritiroMsg = newValue === "A"
                ? trans("js-files.prod-disponibili-autore")
                : trans("js-files.prod-disponibili-domani")
            ;
          }
          this.ritiroMsg += newValue !== "A"
              ? trans("js-files.data-accurata")
              : ""
          ;
        }
      }
    },
    shipping: function (newValue) {
      if (!newValue) {
        this.shipping_net = 0;
        this.packaging = 0;
      }
    },
    indirizzo_spedizione: function (newValue) {
      if(newValue === "fatturazione") {
        this.shippingDataSped = {
          id: null,
          id_cliente: this.client.id,
          cap: this.client.cap,
          cellulare: this.client.cellulare,
          citta: this.client.citta,
          indirizzo: this.client.indirizzo,
          nazione: this.client.nazione,
          provincia: this.client.provincia,
          ragione_soc: this.client.ragione_soc,
          telefono: this.client.telefono,
        }
      }
      console.log("44",this.indirizzo_spedizione, this.shipping, this.shippingDataSped);
      this.calcShipping();
    },
  },
};
</script>
