// export const analytics = {
//     sendGtagEvent: function(eventName, eventParams) {
//         gtag('event', eventName, eventParams);
//     }
// }

export function sendGtagConfig(config, configId) {
  config = JSON.parse(config);
  const configElement = config[configId];
  const configFeatures = config.config;
  const configParams = {};

  if (configId === 'analyticsId') {
    configParams['custom_map'] = {};

    configParams['anonymize_ip'] = configFeatures.anonymizeIp;

    // Set custom dimensions
    for (let key in configFeatures.customDimensions) {
      if (configFeatures.customDimensions.hasOwnProperty(key)) {
        configParams.custom_map[
          'dimension' + configFeatures.customDimensions[key]
        ] = key;
      }
    }

    configParams['currency'] = 'EUR';
  }

  gtag('config', configElement, configParams);
  gtag('config', 'AW-416285129');
}

export function sendGtagEvent(eventName, eventParams) {
  gtag('event', eventName, eventParams);
}

export function processProduct(
  product,
  customDimensions,
  pageType,
  totalValue
) {
  if (pageType === 'components' || pageType === 'tags') {
    customDimensions.mmv = product.mmv;
    if (product.category) {
      customDimensions.category = product.category;
    }
  }

  if (pageType === 'tags' || pageType === 'cart' || pageType === 'purchase') {
    customDimensions.totalvalue = totalValue;

    if (pageType === 'cart' || pageType === 'purchase') {
      if (!customDimensions.hasOwnProperty('prodid')) {
        customDimensions.prodid = [];
      }
      customDimensions.prodid.push(product.id);
    } else {
      customDimensions.prodid = product.id;
    }
  }

  customDimensions.pagetype = pageType;
  customDimensions.model = product.model;
  customDimensions.ania = product.ania;
  const user = getCookie('auth-ragione-sociale');
  if (user) {
    customDimensions.user = user;
  }

  return customDimensions;
}

function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export function setCustomDimensions(products, pageType) {
  let customDimensions = {};
  let totalValue = 0;

  products.forEach((product) => {
    totalValue = parseFloat((totalValue + product.price).toFixed(2));
    customDimensions = processProduct(
      product,
      customDimensions,
      pageType,
      totalValue
    );
  });

  return customDimensions;
}

function normalizeProducts(data, origin) {
  if (origin === 'component') {
    // Componenti derivata dalla pagina dei componenti
    return {
      id: data.idmag,
      name: data.descr + (data.lato ? ' lato ' + data.lato : ''),
      price: parseFloat(data.przc),
      category: data.marcadescr + '/' + data.mdescr,
      mmv: data.marcadescr + ' ' + data.mdescr + ' ' + data.vdescr,
      list_name: 'prodotti',
      variant: '',
      brand: data.marcadescr,
      model: data.mdescr,
      ania: data.descrAnia,
    };
  } else if (origin === 'product') {
    // Componente derivato dalla pagina dei cartellini
    return {
      id: data.idmag,
      name: data.descr + (data.lato ? ' lato ' + data.lato : ''),
      price: parseFloat(data.przc),
      category: data.dsMar + '/' + data.dsMod,
      mmv: data.dsMar + ' ' + data.dsMod + ' ' + data.dsVer,
      list_name: 'prodotti',
      variant: '',
      brand: data.dsMar,
      model: data.dsMod,
      ania: data.descrAnia,
    };
  } else if (origin === 'cart') {
    return {
      id: data.idmag,
      name: data.descr + (data.lato ? ' lato ' + data.lato : ''),
      price: parseFloat(data.prezzo),
      category: data.dsMar + '/' + data.dsMod,
      mmv: data.dsMar + ' ' + data.dsMod + ' ' + data.dsVer,
      list_name: 'prodotti',
      variant: data.cartellino !== 0 ? data.cartellino : null,
      brand: data.dsMar,
      model: data.dsMod,
      ania: data.descrAnia,
    };
  } else if (origin === 'category-list') {
    return {
      id: data.idmag,
      name: data.descr + (data.lato ? ' lato ' + data.lato : ''),
      price: parseFloat(data.przc),
      category:
        data.category.descrEcommerce + '/' + data.dsMar + '/' + data.dsMod,
      mmv: data.dsMar + ' ' + data.dsMod,
      list_name: 'prodotti-categoria',
      variant: '',
      brand: data.dsMar,
      model: data.dsMod,
      ania: data.replacement.descrEx,
    };
  }
}

function buildProductsItems(products) {
  return products.map((el) => {
    return {
      id: el.id,
      name: el.name,
      brand: el.mmv,
      category: el.category,
      variant: el.variant,
      price: el.price,
      quantity: 1,
      coupon: '',
      list_name: el.list_name,
    };
  });
}

function callbackWithTimeout(callback, timeout) {
  let called = false;

  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }

  setTimeout(fn, timeout || 1000);

  return fn;
}

export function onComponentList(products) {
  const eventName = 'view_item_list';
  if (Array.isArray(products) && products.length) {
    products = products.map((product) =>
      normalizeProducts(product, 'component')
    );

    const eventParams = {
      non_interaction: 1,
      items: buildProductsItems(products),
    };

    const customDimensions = setCustomDimensions(products, 'components');

    Object.assign(eventParams, customDimensions);

    sendGtagEvent(eventName, eventParams);
  }
}

export function onCategoryComponentList(products) {
  const eventName = 'view_item_list';
  if (Array.isArray(products) && products.length) {
    products = products.map((product) =>
      normalizeProducts(product, 'category-list')
    );

    const eventParams = {
      non_interaction: 1,
      items: buildProductsItems(products),
    };

    const customDimensions = setCustomDimensions(products, 'components');

    Object.assign(eventParams, customDimensions);

    sendGtagEvent(eventName, eventParams);
  }
}

export function onComponentClick(product, link) {
  const eventName = 'select_content';
  product = normalizeProducts(product, 'component');

  const eventParams = {
    content_type: 'product',
    items: buildProductsItems([product]),
  };

  if (link) {
    eventParams['event_callback'] = callbackWithTimeout(function() {
      window.location.href = link;
    }, 2000);
  }

  const customDimensions = setCustomDimensions([product], 'components');

  Object.assign(eventParams, customDimensions);

  sendGtagEvent(eventName, eventParams);
}

export function onComponentView(product) {
  const eventName = 'view_item';
  product = normalizeProducts(product, 'product');

  const eventParams = {
    non_interaction: 1,
    items: buildProductsItems([product]),
  };

  const customDimensions = setCustomDimensions([product], 'tags');

  Object.assign(eventParams, customDimensions);

  sendGtagEvent(eventName, eventParams);
}

export function onAddToCart(product, type, actualPrice, variant) {
  const eventName = 'add_to_cart';
  product = normalizeProducts(product, type);

  product.price = parseFloat(actualPrice);
  product.variant = variant;

  const eventParams = {
    items: buildProductsItems([product]),
  };

  eventParams['event_callback'] = callbackWithTimeout(function() {
    window.location.reload();
  }, 2000);

  const customDimensions = setCustomDimensions([product], 'cart');

  Object.assign(eventParams, customDimensions);

  sendGtagEvent(eventName, eventParams);
}

export function onRemoveFromCart(product) {
  const eventName = 'remove_from_cart';
  product = normalizeProducts(product, 'cart');

  const eventParams = {
    items: buildProductsItems([product]),
  };

  eventParams['event_callback'] = callbackWithTimeout(function() {
    window.location.reload();
  }, 2000);

  sendGtagEvent(eventName, eventParams);
}

export function onCheckout(products) {
  if (Array.isArray(products)) {
    const eventName = 'begin_checkout';
    products = products.map((product) => normalizeProducts(product, 'cart'));

    const eventParams = {
      items: buildProductsItems(products),
      checkout_step: 1,
    };

    const customDimensions = setCustomDimensions(products, 'cart');

    Object.assign(eventParams, customDimensions);

    sendGtagEvent(eventName, eventParams);
  }
}

export function onCompleteOrder(orders) {
  const eventName = 'purchase';

  orders.forEach((order, index) => {
    let products = order.items.map((product) =>
      normalizeProducts(product, 'cart')
    );

    const eventParams = {
      items: buildProductsItems(products),
      transaction_id: order.id,
      value: order.total,
      shipping: order.shipping,
    };

    if (index === orders.length - 1) {
      eventParams['event_callback'] = callbackWithTimeout(function() {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = '/ordine-confermato';

        orders.forEach((order) => {
          let element = document.createElement('input');
          element.value = JSON.stringify(order);
          element.name = 'orders[]';
          element.type = 'hidden';
          form.appendChild(element);
        });

        document.body.appendChild(form);
        form.submit();
      }, 2000);
    }

    const customDimensions = setCustomDimensions(products, 'purchase');

    Object.assign(eventParams, customDimensions);

    sendGtagEvent(eventName, eventParams);

    // Remarketing
    sendGtagEvent('conversion', {
      send_to: 'AW-416285129/5S3TCPzQ9v8BEMmDwMYB',
      transaction_id: order.id,
    });
  });
}
