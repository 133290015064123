import {
    normalizeComponents
} from "./gtm";

function getFeedIdProduct(idProduct, idAttribute, feedPrefix, feedVariant, feedSuffix) {
    let feedIdProduct = idProduct;

    if (feedVariant && idAttribute) {
        feedIdProduct = idProduct + feedVariant + idAttribute;
    }

    return feedPrefix + feedIdProduct + feedSuffix;
}

function processCustomDimensions(product, customDimensions, pageType, totalValue) {
    let feedIdProduct;

    customDimensions.pagetype = pageType;

    if (pageType === 'product' || pageType === 'cart' || pageType === 'purchase') {
        feedIdProduct = getFeedIdProduct(
            product.variant ? product.variant : product.id,
            product.variant ? '' : product.versionId,
            gtmSettings.gua.merchantPrefix,
            gtmSettings.gua.merchantVariant,
            gtmSettings.gua.merchantSuffix
        );

        if (pageType === 'cart' || pageType === 'purchase') {
            if (!customDimensions.hasOwnProperty('prodid')) {
                customDimensions.prodid = [];
            }

            customDimensions.prodid.push(feedIdProduct);
        } else {
            customDimensions.prodid = feedIdProduct;
        }
    }

    if (pageType === 'product' || pageType === 'cart' || pageType === 'purchase') {
        customDimensions.totalvalue = totalValue;
    }

    if ((pageType === 'category' || pageType === 'product') && product.category) {
        customDimensions.category = product.category;
    }

    return customDimensions;
}

function processGoogleTagParams(customDimensions) {
    const gaTagParamsLink = {
        'prodid': 'prodid',
        'pagetype': 'pagetype',
        'category': 'category',
        'totalvalue': 'totalvalue'
    };
    const google_tag_params = {};

    Object.keys(gaTagParamsLink).forEach(key => {
        if (customDimensions.hasOwnProperty(key)) {
            google_tag_params[gaTagParamsLink[key]] = customDimensions[key];
        }
    });

    return google_tag_params;
}

export function pushDataLayer(dataLayerObject) {
    const dataLayer = window.dataLayer || [];

    if (typeof dataLayerObject === 'object') {
        dataLayer.push(dataLayerObject);
    }
}

export function getProductLayered(products, platform) {
    const productsLayered = [];

    products.forEach(product => {
        productsLayered.push(getProductLayer(product, platform));
    });

    return productsLayered;
}

export function getProductLayer(product, platform) {
    const productFields = {
        'gua': [
            'id',
            'name',
            'category',
            'brand',
            'price',
            'list',
            'position',
            'variant',
            'quantity',
            'dimension1',
            'dimension2',
            'mmv',
            'dimension4',
            'dimension5',
            'model',
            'ania',
            'dimension8'
        ],
        'facebook': [
            'id',
            'quantity',
            'price'
        ],
        'common': [
            'id',
            'name',
            'category',
            'brand',
            'price',
            'list',
            'position',
            'variant',
            'quantity',
            'ean13',
            'upc',
            'reference'
        ]
    };

    const renameFields = {
        'facebook': {
            'price': 'item_price'
        }
    };

    const productLayer = {};

    if (productFields.hasOwnProperty(platform)) {
        productFields[platform].forEach(field => {
            if (product.hasOwnProperty(field)) {
                if (platform === 'facebook' && field === 'id') {
                    productLayer[field] = getFeedIdProduct(
                        product.variant ? product.variant : product.id,
                        product.variant ? '' : product.versionId,
                        gtmSettings.facebook.catalogPrefix,
                        gtmSettings.facebook.catalogVariant,
                        gtmSettings.facebook.catalogSuffix
                    );
                } else if (renameFields[platform] && renameFields[platform][field]) {
                    productLayer[renameFields[platform][field]] = product[field];
                } else {
                    productLayer[field] = product[field]
                }
            }
        });
    }

    return productLayer;
}

export function getRemarketingLayer(products, pageType) {
    let customDimensions = {};
    const remarketingLayer = {
        'gua': {},
        'google_tag_params': {}
    };
    let totalValue = 0;

    products.forEach(product => {
        totalValue = parseFloat((totalValue + product.price).toFixed(2));

        if (gtmSettings.gua.remarketingFeature) {
            customDimensions = processCustomDimensions(product, customDimensions, pageType, totalValue);
        }
    });

    Object.assign(remarketingLayer.gua, customDimensions);

    remarketingLayer.google_tag_params = processGoogleTagParams(customDimensions);

    return remarketingLayer;
}

export function getProductDetailLayer(product) {
    let remarketingLayer = {};
    const productDetailLayer = {};
    const pageType = 'product';

    if (gtmSettings.gua.trackingId) {
        productDetailLayer.ecommerce = {
            'currencyCode': gtmSettings.common.currency,
            'detail': {
                'actionField': {
                    'list': product.list
                },
                'products': [getProductLayer(product, 'gua')]
            }
        };
    }

    if (gtmSettings.gua.trackingId || gtmSettings.googleAds.trackingId) {
        if (gtmSettings.gua.remarketingFeature) {
            remarketingLayer = getRemarketingLayer([product], pageType);

            Object.assign(productDetailLayer, remarketingLayer);
        }
    }

    if (gtmSettings.facebook.trackingId) {
        productDetailLayer.facebook = {
            'contents': [getProductLayer(product, 'facebook')],
            'contentType': 'product'
        };
    }

    productDetailLayer.common = {
        'product': getProductLayer(product, 'common')
    };

    return productDetailLayer;
}

export function getAddToCartLayer(product) {
    const addToCartLayer = {};
    let remarketingLayer = {};
    const pageType = 'cart';

    if (gtmSettings.gua.trackingId) {
        addToCartLayer.ecommerce = {
            'currencyCode': gtmSettings.common.currency,
            'add': {
                'actionField': {
                    'list': product.list
                },
                'products': [getProductLayer(product, 'gua')]
            }
        };
    }

    if (gtmSettings.gua.trackingId || gtmSettings.googleAds.trackingId) {
        if (gtmSettings.gua.remarketingFeature) {
            remarketingLayer = getRemarketingLayer([product], pageType);
        }
    }

    if (gtmSettings.facebook.trackingId) {
        addToCartLayer.facebook = {
            'contents': [getProductLayer(product, 'facebook')],
            'contentType': 'product'
        };
    }

    addToCartLayer.common = {
        'product': getProductLayer(product, 'common')
    };

    Object.assign(addToCartLayer, remarketingLayer);

    return addToCartLayer;
}

export function getCheckoutLayer(products) {
    const checkoutContents = {
        'productIds': [],
        'productsEans': [],
        'productsReference': [],
        'amount': 0,
        'totalCart': 0
    };

    let checkoutLayer = {};
    let remarketingLayer = {};

    products.forEach(product => {
        checkoutContents.productIds.push(product.id);
        checkoutContents.amount++;
        checkoutContents.totalCart += product.price;
    });

    if (gtmSettings.gua.trackingId) {
        checkoutLayer.ecommerce = {
            'currencyCode': gtmSettings.common.currency,
            'checkout': {
                'actionField': {
                    'step': 1
                },
                'products': getProductLayered(products, 'gua')
            }
        };
    }

    if (gtmSettings.gua.trackingId || gtmSettings.googleAds.trackingId) {
        if (gtmSettings.gua.remarketingFeature) {
            remarketingLayer = getRemarketingLayer(products, 'cart');
        }
    }

    if (gtmSettings.facebook.trackingId) {
        checkoutLayer.facebook = {
            'contents': getProductLayered(products, 'facebook'),
            'contentType': 'product'
        };
    }

    checkoutLayer.common = {
        'checkoutStep': 1,
        'products': getProductLayered(products, 'common'),
        'productIds': checkoutContents.productIds,
        'productsEans': checkoutContents.productsEans,
        'productReferences': checkoutContents.productsReference,
        'numItems': checkoutContents.amount,
        'totalCart': (Math.round(checkoutContents.totalCart * 100) / 100).toFixed(2)
    };

    Object.assign(checkoutLayer, remarketingLayer);

    return checkoutLayer;
}

export function getOrderLayer(orderComplete) {
    const orderContents = {
        'productIds': [],
        'productsEan': [],
        'productsReference': [],
        'amount': 0
    };

    const orderLayer = {};
    let remarketingLayer = {};

    orderComplete.items = normalizeComponents(orderComplete.items, 'cart');

    orderComplete.items.forEach(product => {
        orderContents.productIds.push(product.id);
        orderContents.amount++;
    });

    if (gtmSettings.gua.trackingId) {
        orderLayer.ecommerce = {
            'currencyCode': gtmSettings.common.currency,
            'purchase': {
                'actionField': {
                    'id': orderComplete.id,
                    'revenue': orderComplete.total,
                    'shipping': orderComplete.shipping
                },
                'products': getProductLayered(orderComplete.items, 'gua')
            }
        };
    }

    if (gtmSettings.gua.trackingId || gtmSettings.googleAds.trackingId) {
        if (gtmSettings.gua.remarketingFeature) {
            remarketingLayer = getRemarketingLayer(orderComplete.items, 'cart');
            Object.assign(orderLayer, remarketingLayer);
        }
    }

    if (gtmSettings.facebook.trackingId) {
        orderLayer.facebook = {
            'contents': getProductLayered(orderComplete.items, 'facebook'),
            'contentType': 'product'
        };
    }

    orderLayer.common = {
        'orderId': orderComplete.id,
        'products': getProductLayered(orderComplete.items, 'common'),
        'productIds': orderContents.productIds,
        'productEans': orderContents.productsEan,
        'productReferences': orderContents.productsReference,
        'numItems': orderContents.amount,
        'orderRevenue': orderComplete.total,
        'coupons': []
    };

    return orderLayer;
}